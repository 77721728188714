import React from 'react';
import PropTypes from 'prop-types';

import { CartItem } from '../../../helpers/proptypes';
import EditionPreviews from '../campaigns/EditionPreviews';
import {forEach as _forEach} from "lodash";
import { PROJECT_NAME } from 'js/constants';

CartWizardStepMediaNavigator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  edition: PropTypes.object,
};

CartWizardStepMediaNavigator.defaultProps = {
  edition: {},
};

function CartWizardStepMediaNavigator({ edition }) {
  const printDataPreviews = [];
  _forEach(edition?.template?.print_data_previews, preview => {
    printDataPreviews.push({
      key:
        'https://s3.eu-central-1.amazonaws.com/' +
        PROJECT_NAME +
        '.templates/' +
        edition?.template?.id +
        '/images/' +
        preview,
    });
  });
  return (
    <div className="wizard-step-media-navigator">
      <div className="details-content">
        <EditionPreviews
          editionPreviews={edition?.template?.direct_ordering ? printDataPreviews : edition.previews}
          type={edition?.template?.export_type?.value}
        />
      </div>
    </div>
  );
}

export default CartWizardStepMediaNavigator;
