/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-unused-vars */
import React from 'react';

export const locale = {
  "locales": ["nl"],
  "messages": {
    "editor": {
      "first_name": "Voornaam",
      "name": "Naam",
      "subject": "Onderwerp",
      "message": "Bericht",
      "custom_dropdown": "Dropdown op maat",
      "custom_label": "Eigen etikettering",
      "set_autoplay": "Automatisch spelen",
      "autoplay_info": "Let op! Sommige browsers staan autoplay in de meeste gevallen niet toe. Gedempt automatisch afspelen is echter altijd toegestaan.",
      "set_muted": "Stomme",
      "data_protection_officer": "Functionaris voor gegevensbescherming",
      "data_protection_authority": "Gegevensbeschermingsautoriteit",
      "button_font_color": "Kleur lettertype",
      "set_label_dropdown": "Wijs een label toe aan de vervolgkeuzelijst",
      "set_label_custom_field": "Wijs een label toe aan het invoerveld",
      "empty_field": "Vrij veld",
      "custom_field": "Door gebruiker gedefinieerd veld",
      "change_label": "Label wijzigen",
      "edit_label": "Label bewerken",
      "salutation": "Begroeting",
      "title": "Titel",
      "link_title": "Link naam",
      "main_text": "Hoofdtekst",
      "more_text": "verdere tekst",
      "create_text": "Tekst maken",
      "edit_text_slider": "Er is nog geen tekstelement gemaakt",
      "edit_rss_elements": "Nog geen malelementen geselecteerd, klik hier om toe te voegen.",
      "rss_settings": "Instellingen RSS-feed",
      "select_rss": "Je kunt een RSS-feed selecteren uit de sjablonen",
      "available_rss": "Beschikbare RSS-feeds",
      "load_rss_part_1": "Je hebt de volgende RSS-feed geselecteerd.",
      "load_rss_part_2": "Klik op de knop 'RSS-feeds ophalen' om de feeds te laden",
      "load_rss_feeds": "RSS-feeds ophalen",
      "color": "Kleur",
      "background_color": "Achtergrondkleur",
      "distance_to_border": "Afstand tot de rand",
      "color_of_qrcode": "QR-code kleur",
      "click_me": "Klik op mij",
      "new_menu_item": "Nieuw menu-item",
      "external": "extern",
      "imprint": "Afdruk",
      "privacy": "Gegevensbescherming",
      "january": "Januari",
      "february": "februari",
      "march": "Maart",
      "april": "April",
      "may": "Mei",
      "june": "Juni",
      "july": "Juli",
      "august": "Augustus",
      "september": "September",
      "october": "oktober",
      "november": "November",
      "december": "December",
      "monday": "Maandag",
      "tuesday": "Dinsdag",
      "wednesday": "Woensdag",
      "thursday": "Donderdag",
      "friday": "Vrijdag",
      "saturday": "Zaterdag",
      "sunday": "Zondag",
      "edit_date": "Afspraak bewerken",
      "oclock": "Klok",
      "filter_selection": "Filterselectie",
      "use_filter": "Toepassen",
      "sample_picture_found": "Voorbeeldafbeelding gevonden",
      "already_edited_in_another_tab": "Deze editie is mogelijk al bewerkt in een ander tabblad.",
      "information_for_contact": "Gegevens voor contactformulier",
      "mail_receiver": "E-mail ontvanger",
      "google_analytics": "Google Analytics",
      "measurement_id": "Meet-ID voor Google Analytics",
      "new_picture_inserted": "Nieuwe afbeelding ingevoegd",
      "text_changed": "Tekst gewijzigd",
      "edit_title": "Klik hier om de titel te bewerken",
      "edit_description": "Klik hier om de afbeeldingsbeschrijving te bewerken",
      "edit_copyright": "Klik hier om het copyright te bewerken",
      "no_changes": "Geen wijzigingen",
      "changes_on_document": "Wijzigingen in het document",
      "changes_on_page": "Wijzigingen op pagina",
      "page": "Pagina",
      "choose_button_label": "Selecteer een label voor de knop",
      "edit_calendly": "Klik hier om de instellingen voor Calendly te bewerken",
      "exchanged_with_page": "uitgewisseld met pagina",
      "upload_failed": "Uploaden mislukt",
      "try_again": "Probeer het opnieuw",
      "saving_failed": "Opslaan mislukt",
      "new_page_inserted": " nieuwe pagina('s) ingevoegd na pagina",
      "delete_pages": "Pagina's verwijderen",
      "all_content_deleted": "Pagina's en alle inhoud erop wordt verwijderd.",
      "yes_delete": "Ja, verwijderen",
      "pages_deleted": " Pagina('s) verwijderd, per pagina",
      "rather_not": "Beter van niet",
      "new_here": "Ben je nieuw hier?",
      "first_start": "Het lijkt erop dat je onze nieuwe editor voor het eerst hebt geopend. Wilt u een introductie?",
      "yes_absolutely": "Ja, absoluut",
      "im_fine": "Ik kan",
      "no_page_definitions_found": "Geen paginadefinities gevonden!",
      "move_up": " Verplaats pagina('s) naar boven",
      "side_position_fixed": "Vaste zijpositie",
      "generated_in_pdf": "Pagina's worden automatisch gegenereerd in de PDF",
      "move_down": " Verplaats pagina('s) naar beneden",
      "by": "Naar",
      "add_page": " Pagina's invoegen",
      "remove_page": " Pagina's verwijderen",
      "unsaved_changes": "Je hebt niet-opgeslagen wijzigingen",
      "i_have_it": "Ok, nu heb ik het!",
      "document_pictures": "Afbeeldingen in het document",
      "used_images": "Gebruikte afbeeldingen",
      "take_a_photo": "Maak een foto",
      "unused_images": "Ongebruikte afbeeldingen",
      "content_is_saved": "Inhoud wordt opgeslagen",
      "page_arrangement": "Zijopstelling",
      "print_sheet_view": "Blad afdrukken",
      "view_safety_distance": "Zicht op veiligheidsafstand",
      "view_structure": "Structuur bekijken",
      "start_tutorial": "Zelfstudie starten",
      "adjust_by_window": "Pagina aanpassen aan venster",
      "adjust_by_width": "Pas de pagina aan de breedte aan",
      "adjust_by_height": "Pas de pagina aan de hoogte aan",
      "delete_content": "Inhoud verwijderen",
      "delete_selected_content_block": "Hiermee wordt het geselecteerde inhoudsblok en de inhoud (afbeeldingen, teksten) verwijderd.",
      "delete_children_of_parents": "Merk op dat dit een parent element is. Als je dit verwijdert, worden alle bijbehorende kindelementen ook verwijderd.",
      "reset_content": "Inhoud resetten",
      "reset_content_block_to_root": "Dit zet het geselecteerde inhoudsblok en de inhoud (afbeeldingen, teksten) terug naar hun oorspronkelijke staat.",
      "yes_reset": "Ja, opnieuw instellen",
      "copy_full_content": "Volledige pagina-inhoud kopiëren",
      "paste_content": "Inhoud van klembord plakken",
      "enlarge_block": "Blok vergroten",
      "shrink_block": "Blok verkleinen",
      "text_overflow": "Tekstoverloop",
      "initial_image_available": " Eerste afbeelding beschikbaar",
      "paste_content_after": "Inhoud van klembord plakken na deze",
      "deselect_element": "Deselecteer element",
      "select_element": "Selecteer element",
      "block_can_not_move": "Blok kan niet worden verplaatst",
      "up": "Naar boven",
      "reset_block": "Blok resetten",
      "change_backgroundcolor": "Achtergrondkleur wijzigen",
      "change_fontcolor": "Letterkleur wijzigen",
      "copy_block": "Blok kopiëren",
      "delete_block": "Blok verwijderen",
      "down": "Naar beneden",
      "product_in_cart": "Product in winkelmandje",
      "added_to_cart": " is toegevoegd aan je winkelmandje.",
      "great": "Geweldig!",
      "to_cart": "Naar het winkelmandje",
      "are_you_sure": "Weet je het zeker?",
      "reset_to_selected_status": "Het document wordt teruggezet naar de geselecteerde status",
      "abort": "Annuleren",
      "reset_to_version": "Terugzetten naar versie",
      "choose_a_campaign": "Selecteer campagne",
      "name_for_edition": "Naam voor deze editie",
      "save_to": "Opslaan als...",
      "save": "Sla",
      "settings": "Instellingen",
      "short_summary": "Korte samenvatting",
      "keywords_separated": "Trefwoorden gescheiden door een komma",
      "serial_letter": "Mail samenvoegen",
      "activate_serial_letter": "E-mail samenvoegen activeren",
      "serial_letter_database": "Database voor samenvoegen van e-mails",
      "serial_letter_database_upload_info": "De upload ondersteunt de volgende bestandsindelingen - .xlsx, .xls en .csv",
      "serial_letter_info": "Voer de plaatshouders in de cellen van de eerste rij in de database in. Voer daaronder de respectieve waarden in. Bijvoorbeeld als volgt:",
      "preview_of_database": "Voorbeeld database",
      "serial_letter_database_upload": "uploaden",
      "no_error": "Zonder fout",
      "error": "Fout",
      "information_for_imprint": "Informatie voor opdruk",
      "responsible_for_content": "Verantwoordelijk voor inhoud",
      "company_association": "Bedrijf / Vereniging",
      "street": "Straat",
      "zip_code": "Postcode",
      "city": "Plaats",
      "email": "E-mailadres",
      "phone_number": "Telefoon",
      "data_protection_information": "Informatie over gegevensbescherming",
      "responsible": "Verantwoordelijke",
      "change": "Verander",
      "export_image": "Afbeelding exporteren",
      "preview": "Voorbeeld",
      "preview_button": "Knop preview",
      "print_pdf": "PDF afdrukken",
      "initialize": "Initialiseren!",
      "order": "Bestel",
      "versions": "Versies",
      "currently_used_image": "Momenteel gebruikte afbeelding",
      "upload_new_image": "Nieuwe afbeelding uploaden",
      "new_cut": "Opnieuw snijden",
      "take_on": "Overnemen",
      "choose_file": "Selecteer bestand",
      "image_description": "Beschrijving afbeelding",
      "image_upload": "Afbeelding uploaden",
      "mediathek": "Mediatheek",
      "pixabay": "Pixabay",
      "add_content": "Inhoud toevoegen",
      "copied_content_block": "Inhoud blok gekopieerd",
      "unsaved_content": "Je hebt niet-opgeslagen wijzigingen! Wilt u de editor echt verlaten?",
      "filter_settings": "Filterinstellingen",
      "filter_grayscale": "Grijswaarden",
      "filter_sepia": "Sepia",
      "filter_blur": "vervagen",
      "filter_brightness": "Helderheid",
      "filter_contrast": "Contrast",
      "filter_hueRotate": "Kleurtoon draaien",
      "filter_invert": "Kleuren omkeren",
      "filter_opacity": "Transparantie",
      "filter_saturate": "Verzadiging",
      "filter_default": "Standaard",
      "filter_set": "Beëindigd",
      "work_surface": "Hier bevindt zich het werkblad.",
      "work_surface_description": "Hier vindt het ontwerpproces plaats. Afbeeldingen, teksten en elementen kunnen hier worden ingevoegd en bewerkt.",
      "page_navigation": "Hier vindt u de paginanavigatie",
      "page_navigation_description": "Hier kun je schakelen tussen de afzonderlijke pagina's en pagina's toevoegen, verwijderen of verwisselen.",
      "content_blocks": "Hier vindt u de inhoudsblokken",
      "content_blocks_description": "Hier kun je verschillende beeld- en tekstelementen invoegen in je advertentiemateriaal door erop te klikken. Als er niet genoeg ruimte is op het advertentiemateriaal, kun je op elk moment overbodige elementen verwijderen.",
      "screen_view": "Je kunt de schermweergave hier wijzigen.",
      "screen_view_description": "Hier kun je de weergave van je webmedia wijzigen. 100% geeft altijd de originele grootte van het advertentiemateriaal weer.",
      "error_display": "Fouten worden hier weergegeven.",
      "error_display_description": "Het systeem waarschuwt je hier als er een tekstoverloop of andere technische fouten worden gevonden.",
      "options": "Hier vindt u opties voor uw reclamemateriaal.",
      "options_description": "Klik op de voorbeeldknop om een voorbeeld-PDF te genereren of op de bestelknop om het advertentiemateriaal in het winkelmandje te plaatsen. Met 'Opslaan als' kun je het advertentiemateriaal kopiëren naar een andere campagne.",
      "tutorial_end": "Dat was onze redacteur in een notendop!",
      "tutorial_end_description": "Nu kun je snel en eenvoudig je reclamemateriaal ontwerpen in overeenstemming met de huisstijl.",
      "content_block_copied": "Inhoud blok gekopieerd",
      "content_blocks_switched": "Inhoudsblokken verwisseld",
      "content_block_reset": "Inhoud blok reset",
      "content_block_resize": "Grootte inhoudsblok gewijzigd",
      "content_block_removed": "Inhoud blok verwijderd",
      "no_place": "Geen ruimte",
      "content_block_inserted": "Inhoudsblok ingevoegd",
      "no_dates_available": "Geen data beschikbaar, klik hier om er een toe te voegen.",
      "new_date": "Nieuwe datum",
      "edit_dates": "Afspraken bewerken",
      "date_description": "Beschrijving van de aanstelling",
      "add": "Voeg  toe",
      "no_dates_availableTwo": "Geen data beschikbaar",
      "date_title": "Titel van de benoeming",
      "date_place": "Locatie van de afspraak",
      "date_from": "Am/Dn",
      "date_to": "Tot",
      "no_forms": "Nog geen malelementen geselecteerd, klik hier om toe te voegen",
      "read_privacy_policy": "Ik heb het privacybeleid gelezen en begrepen.",
      "contact_form_settings": "Instellingen contactformulier",
      "dropdown_settings": "Instellingen Dropdown",
      "add_options": "Opties toevoegen",
      "dropdown_label": "Dropdown label",
      "dropdown_option_fields": "Dropdown keuzerondjes",
      "pls_choose": "Selecteer een formulierelement",
      "pls_chooseTwo": "Selecteer de gewenste contactformulierelementen",
      "send": "Verzenden",
      "google_maps_element_settings": "Locatie-instellingen Google Maps",
      "no_location": "Je hebt nog geen adres toegevoegd, klik hier om te bewerken",
      "found_textoverflow": "Tekstoverloop gevonden",
      "minimum_size": "Minimumgrootte",
      "too_low_resolution": "Afbeelding heeft te lage resolutie!",
      "set_scale_free": "Vrij schalen activeren",
      "unset_scale_free": "Gratis schalen uitschakelen",
      "no_images_selected": "Nog geen foto's geselecteerd, klik hier om toe te voegen",
      "gallery_settings": "Instellingen galerij",
      "add_image": "Afbeelding toevoegen",
      "add_image_front": "Afbeelding toevoegen aan het begin",
      "add_image_back": "Afbeelding aan het einde toevoegen",
      "slider_images_invert": "Afbeeldingsvolgorde omkeren",
      "image_text_settings": "Instellingen afbeeldings tekst",
      "change_slider": "Als u de titel of beschrijving wilt wijzigen, klikt u op de betreffende tekst in de schuifbalk",
      "change_image_title": "Titel van afbeelding wijzigen",
      "change_image_description": "Afbeeldingsbeschrijving wijzigen",
      "image_copyright": "Afbeelding copyright",
      "slider_settings": "Instellingen schuifregelaar",
      "new_titel": "Nieuwe titel",
      "new_link_title": "Nieuwe linktitel",
      "edit_text_title": "Titel bewerken",
      "edit_text_link": "Link bewerken",
      "edit_link_title": "Titel van link bewerken",
      "edit_text_text": "Tekst bewerken",
      "add_element": "Element toevoegen",
      "add_element_before": "Element vooraan toevoegen",
      "add_element_after": "Element aan de achterkant toevoegen",
      "edit_text_of_element": "Als u de teksten wilt bewerken, klikt u op de betreffende tekst in de schuifbalk",
      "invert_elements": "Volgorde omkeren",
      "edit_nav": "Navigatie bewerken",
      "term": "Aanwijzing",
      "link": "Link",
      "actions": "Acties",
      "content_of_QRCode": "Welke inhoud moet de QR code bevatten?",
      "address_of_website": "Voer het adres van de website in:",
      "name_surname": "Voornaam en achternaam",
      "business_card": "Visitekaartje",
      "must_url": "Moet een URL zijn",
      "position": "Positie",
      "phone": "Telefoon",
      "website": "Website",
      "address": "Adres",
      "email_message": "E-mail",
      "text": "Tekst",
      "recipient_email_address": "E-mailadres ontvanger",
      "reference": "Onderwerp",
      "content": "Inhoud",
      "your_text": "Uw tekst",
      "add_QRCode": "QR-code invoegen",
      "no_preview_of_twitter": "Helaas is het niet mogelijk om Twitter hier weer te geven. Gebruik de preview-functie van de website.",
      "no_preview_of_calendly": "Helaas is het niet mogelijk om Calendly hier weer te geven. Gebruik de preview functie van de website.",
      "twitter_link": "Twitter-link geplaatst:",
      "social_media_settings": "Instellingen sociale media",
      "calendly_settings": "Calendly instellingen",
      "button_settings": "Knopinstellingen",
      "action_button_content_text": "Klik hier om de instellingen voor de actieknop te bewerken",
      "action_button_link_element": "Verwijzing naar een element",
      "action_button_external_link": "Verwijzing naar een externe link",
      "action_button_or": "of",
      "button_color": "Kleur knop",
      "add_social_media_account": "Nog geen social media account toegevoegd, klik hier om er een te selecteren.",
      "choice_of_provider": "Selectie van aanbieders van sociale media",
      "embed_code": "Embed-code van kanaal voor sociale media",
      "link_to_your_page": "Link naar je pagina op",
      "link_to_your_calendly_page": "Link naar je Calendly-pagina",
      "link_to_your_page_two": "Link naar je pagina",
      "video_settings": "Video-instellingen",
      "add_video": "Nog geen video geselecteerd, klik hier om er een in te voegen",
      "choice_video_provider": "Selectie van videoproviders",
      "upload_video": "Video uploaden",
      "choice_mp4_file": "Selecteer video (.mp4) bestand",
      "nav_type": "Type",
      "nav_type_url": "URL",
      "nav_type_page": "Pagina",
      "nav_type_page_prefix": "Pagina"
    },
    "medialibrary": {
      "new_folder": "Nieuwe map",
      "sorting": "Sorteren",
      "directories": "Adresboeken",
      "medialibrary": "Mediatheek",
      "media": "Media",
      "upload_media": "Media uploaden",
      "sorting_description": "De sortering is gebaseerd op de bestandsnaam en niet op de gedefinieerde titel.",
      "sorting_info_text": "Selecteer een sorteervolgorde voor de mappen en media. De sorteerset geldt ook voor alle mapweergaven.",
      "paste": "Invoegen",
      "info": "Let op!",
      "info_text": "Een of meer bestanden bestaan al. Deze zijn niet opnieuw geüpload.",
      "back_to_root": "Terug naar de hoofdmap",
      "directory_by": "Directory van",
      "created_by": "Gemaakt door",
      "no_folder_created": "Er zijn geen mappen gemaakt",
      "no_media_found": "Geen media in de map",
      "upload_processing": "Uw uploads worden verwerkt",
      "not_uploaded": "Media kon niet worden geüpload!",
      "close": "Sluit",
      "preview": "Afbeelding/video preview",
      "name": "Naam",
      "upload_info": "Info",
      "options": "Opties",
      "status": "Status",
      "adjust_meta": "Metagegevens aanpassen",
      "title": "Titel",
      "description": "Beschrijving van de",
      "save": "Sla",
      "create_new_directory": "Nieuwe map maken",
      "directory_name": "Naam directory",
      "rename_folder": "Map hernoemen",
      "copyright": "Copyright",
      "upload": "uploaden",
      "wastebasket": "Prullenmand",
      "are_you_sure_erase_file": "Weet je zeker dat je het bestand permanent wilt verwijderen?",
      "are_you_sure_erase_folder": "Weet je zeker dat je de map onherroepelijk wilt verwijderen?",
      "erase": "Verwijder"
    },
    "meta": {
      "campaigns": {
        "title": "Campagnes",
        "description": "Campagnes",
        "name": "Campagnes"
      },
      "productShop": {
        "title": "Winkel op",
        "description": "Ben je op zoek naar merchandise? Dan ben je hier aan het juiste adres.",
        "name": "Artikel"
      },
      "carShop": {
        "title": "Voertuigen winkel",
        "description": "Op zoek naar je droomauto? Bekijk dan nu onze voertuigen.",
        "name": "Voertuigen"
      },
      "car_detail_search": {
        "title": "Weet je niet zeker welke auto bij je past? Gebruik onze zoekfunctie om de perfecte auto te vinden",
        "description": "Ben je op zoek naar een auto met specifieke vereisten, maar weet je niet precies welke auto je nodig hebt? Selecteer het voertuigtype, de aandrijflijn en het uitrustingsniveau.",
        "name": "Gedetailleerd zoeken"
      },
      "car_brands": {
        "title": "Selecteer uw merk",
        "description": "Kies je merk en ontdek de beste aanbiedingen op de markt.",
        "name": "Merkselectie"
      },
      "car_models": {
        "title": "Selecteer het model van je {merknaam}",
        "description": "Kies een model uit de populairste {modelOne} tot {modelTwo}.",
        "name": "Modellen"
      },
      "car_model_drive": {
        "title": "Selecteer het brandstoftype en de transmissie voor uw {merknaam} {modelnaam}",
        "description": "We hebben de {brandName} {modelName} met {fuelTypes} motoren. Beschikbare versnellingsbaktypes zijn {gearboxTypes}.",
        "name": "Brandstof"
      },
      "car_model_equipment": {
        "title": "Selecteer het uitrustingsniveau voor uw {merknaam} {modelnaam}",
        "description": "Kies uit Budget, Familie, Comfort of Business. De populairste, Comfort, biedt parkeersensoren, Bluetooth en meer.",
        "name": "Uitrusting"
      },
      "favorites": {
        "title": "Je favorieten, opgeslagen voor later",
        "description": "Het is geweldig dat je een aantal van onze beste items hebt bewaard voor later. Probeer nu degene te selecteren die je wilt.",
        "name": "Favorieten"
      },
      "cart": {
        "title": "Winkelmandje",
        "description": "Winkelmandje",
        "name": "Winkelmandje"
      },
      "checkout": {
        "title": "Kassa",
        "description": "Kassa",
        "name": "Kassa"
      },
      "clients": {
        "title": "Klanten",
        "description": "Klanten",
        "name": "Klanten"
      },
      "client_details": {
        "title": "Bedrijf",
        "description": "Bedrijf",
        "name": "Bedrijf"
      },
      "leads": {
        "title": "Leads",
        "description": "Leads",
        "name": "Leads"
      },
      "lead_details": {
        "title": "Lood",
        "description": "Lood",
        "name": "Lood"
      },
      "cars": {
        "title": "Voertuigen",
        "description": "Voertuigen",
        "name": "Voertuigen"
      },
      "car_overview": {
        "title": "Overzicht voertuigen",
        "description": "Overzicht voertuigen",
        "name": "Overzicht voertuigen"
      },
      "car_details": {
        "title": "Voertuig",
        "description": "Voertuig",
        "name": "Voertuig"
      },
      "pages": {
        "title": "Pagina",
        "description": "Pagina",
        "name": "Pagina"
      },
      "page_details": {
        "title": "Pagina",
        "description": "Pagina",
        "name": "Pagina"
      },
      "profile": {
        "title": "Profiel",
        "description": "Profiel",
        "name": "Profiel"
      },
      "locations": {
        "title": "Locaties",
        "description": "Locaties",
        "name": "Locaties"
      },
      "company_locations": {
        "title": "Locaties",
        "description": "Locaties",
        "name": "Locaties"
      },
      "data_push": {
        "title": "Gegevens pushen",
        "description": "Gegevens pushen",
        "name": "Gegevens pushen"
      },
      "white_label_projects": {
        "title": "White label projecten",
        "description": "White label projecten",
        "name": "White label projecten"
      },
      "users": {
        "title": "Gebruikers",
        "description": "Gebruikers",
        "name": "Gebruikers"
      },
      "user_details": {
        "title": "Gebruikers",
        "description": "Gebruikers",
        "name": "Gebruikers"
      },
      "user_import": {
        "title": "Gebruikers importeren",
        "description": "Gebruikers importeren",
        "name": "Gebruikers importeren"
      },
      "teams": {
        "title": "Teams",
        "description": "Teams",
        "name": "Teams"
      },
      "questionnaires": {
        "title": "Vragenlijsten",
        "description": "Vragenlijsten",
        "name": "Vragenlijsten"
      },
      "questionnaire_details": {
        "title": "Vragenlijst",
        "description": "Vragenlijst",
        "name": "Vragenlijst"
      },
      "document_types": {
        "title": "Typen documenten",
        "description": "Typen documenten",
        "name": "Typen documenten"
      },
      "document_type_details": {
        "title": "Type document",
        "description": "Type document",
        "name": "Type document"
      },
      "templates": {
        "title": "Sjablonen",
        "description": "Sjablonen",
        "name": "Sjablonen"
      },
      "cms_elements": {
        "title": "CMS elementen",
        "description": "CMS elementen",
        "name": "CMS elementen"
      },
      "cms_element_details": {
        "title": "CMS element",
        "description": "CMS element",
        "name": "CMS element"
      },
      "brands": {
        "title": "Merken",
        "description": "Merken",
        "name": "Merken"
      },
      "models": {
        "title": "Modellen",
        "description": "Modellen",
        "name": "Modellen"
      },
      "categories": {
        "title": "Categorieën",
        "description": "Categorieën",
        "name": "Categorieën"
      },
      "category_details": {
        "title": "Categorie",
        "description": "Categorie",
        "name": "Categorie",
        "authorization_on_mobile_device": "Autorisatie op mobiele apparaten",
        "hide_category": "Categorie verbergen"
      },
      "attributes": {
        "title": "Attributen",
        "description": "Attributen",
        "name": "Attributen"
      },
      "attribute_details": {
        "title": "Attribuut",
        "description": "Attribuut",
        "name": "Attribuut"
      },
      "attribute_groups": {
        "title": "Attribuutgroepen",
        "description": "Attribuutgroepen",
        "name": "Attribuutgroepen"
      },
      "dynamic_data_definitions": {
        "title": "Dynamische gegevensdefinities",
        "description": "Dynamische gegevensdefinities",
        "name": "Dynamische gegevensdefinities"
      },
      "pipelines": {
        "title": "Pijpleidingen",
        "description": "Pijpleidingen",
        "name": "Pijpleidingen"
      },
      "pipeline_details": {
        "title": "Pijpleiding",
        "description": "Pijpleiding",
        "name": "Pijpleiding"
      },
      "items": {
        "title": "Producten",
        "description": "Producten",
        "name": "Producten"
      },
      "item_details": {
        "title": "Product",
        "description": "Product",
        "name": "Product"
      },
      "calendar": {
        "title": "Kalender",
        "description": "Kalender",
        "name": "Kalender"
      },
      "orders": {
        "title": "Bestellingen",
        "description": "Bestellingen",
        "name": "Bestellingen"
      },
      "settings": {
        "title": "Instellingen",
        "description": "Instellingen",
        "name": "Instellingen"
      },
      "four_zero_four": {
        "title": "404",
        "description": "404",
        "name": "404"
      },
      "account": {
        "title": "Account",
        "description": "Account",
        "name": "Account"
      },
      "forgot_password": {
        "linktext": "Wachtwoord vergeten?",
        "title": "Wachtwoord vergeten",
        "description": "Wachtwoord vergeten",
        "name": "Wachtwoord vergeten"
      },
      "renew_password": {
        "title": "Wachtwoord vernieuwen",
        "description": "Wachtwoord vernieuwen",
        "name": "Wachtwoord vernieuwen"
      },
      "new_password_after_reset": {
        "title": "Nieuw wachtwoord na reset",
        "description": "Nieuw wachtwoord na reset",
        "name": "Nieuw wachtwoord na reset"
      },
      "pricing": {
        "title": "Prijzen",
        "description": "Prijzen",
        "name": "Prijzen"
      },
      "signedup": {
        "title": "Geregistreerd",
        "description": "Geregistreerd",
        "name": "Geregistreerd"
      },
      "go_pro": {
        "title": "Ga pro",
        "description": "Ga pro",
        "name": "Ga pro"
      },
      "paypal": {
        "title": "Betalen met Paypal",
        "description": "Betalen met Paypal",
        "name": "Betalen met Paypal"
      },
      "lead_catchers": {
        "title": "Leiders",
        "description": "Leiders",
        "name": "Leiders"
      },
      "bills": {
        "title": "Facturen",
        "description": "Facturen",
        "name": "Facturen"
      },
      "provisionServiceDocuments": {
        "title": "Commissies"
      },
      "commissionServiceDocuments": {
        "title": "Documenten van de Commissie"
      },
      "integrations": {
        "title": "Integraties",
        "description": "Integraties",
        "name": "Integraties"
      },
      "order_complete": {
        "title": "Bestelling afgerond. Hartelijk dank",
        "description": "Bestelling afgerond. Hartelijk dank",
        "name": "Bestelling afgerond. Hartelijk dank"
      },
      "order_details": {
        "title": "Bestelgegevens",
        "description": "Bestelgegevens",
        "name": "Bestelgegevens"
      },
      "my_company": {
        "title": "Mijn bedrijf",
        "description": "Mijn bedrijf",
        "name": "Mijn bedrijf"
      },
      "white_label_plugins": {
        "title": "White label plugins",
        "description": "White label plugins",
        "name": "White label plugins"
      },
      "plugins": {
        "title": "Creacheck-plugins",
        "description": "Creacheck-plugins",
        "name": "Creacheck-plugins"
      },
      "bill_details": {
        "title": "Factuurgegevens",
        "description": "Factuurgegevens",
        "name": "Factuurgegevens"
      },
      "activate_plugin": {
        "title": "Plugin activeren",
        "description": "Plugin activeren",
        "name": "Plugin activeren"
      },
      "model_groups": {
        "title": "Modelgroepen",
        "description": "Modelgroepen",
        "name": "Modelgroepen"
      },
      "model_group_details": {
        "title": "Details modelgroep",
        "description": "Details modelgroep",
        "name": "Details modelgroep"
      },
      "model_assets": {
        "title": "Afbeeldingen van modellen",
        "description": "Afbeeldingen van modellen",
        "name": "Afbeeldingen van modellen"
      },
      "model_asset_details": {
        "title": "Details modelafbeelding",
        "description": "Details modelafbeelding",
        "name": "Details modelafbeelding"
      },
      "lead_per_mail_rules": {
        "title": "Leid door e-mail regels",
        "description": "Leid door e-mail regels",
        "name": "Leid door e-mail regels"
      },
      "car_rental": {
        "title": "Veilig en snel boeken in {stad}. {aantal} auto's om uit te kiezen",
        "description": "Reserveer je auto snel en veilig. Geloof ons, een auto huren was nog nooit zo snel en gemakkelijk.",
        "name": "Verhuren"
      },
      "failed_refunds": {
        "title": "Mislukte terugbetalingen",
        "description": "Mislukte terugbetalingen",
        "name": "Mislukte terugbetalingen"
      },
      "companySignup": {
        "title": "Registratie voor bedrijven",
        "description": "Registreer je bedrijf en profiteer van het nieuwste ecosysteem voor autodealers",
        "name": "Registratie voor bedrijven"
      },
      "price_negotiations": {
        "title": "Prijsonderhandelingen",
        "description": "Prijsonderhandelingen",
        "name": "Prijsonderhandelingen"
      },
      "price_negotiation_details": {
        "title": "Prijsonderhandeling",
        "description": "Prijsonderhandeling",
        "name": "Prijsonderhandeling"
      },
      "communities": {
        "title": "Gemeenschappen lijst",
        "description": "Gemeenschappen lijst",
        "name": "Gemeenschappen lijst"
      },
      "car_service_locations": {
        "title": "Locaties waar serviceafspraken beschikbaar zijn",
        "description": "Locaties",
        "name": "Locaties"
      },
      "car_service_resources": {
        "title": "Kies een serviceconsultant",
        "description": "Service consultant",
        "name": "Service consultant"
      },
      "car_service_free_slots": {
        "title": "Kies een vrij tijdstip om je auto te parkeren",
        "description": "Tijdvenster",
        "name": "Tijdvenster"
      },
      "car_service_client_data": {
        "title": "Log in of registreer om een afspraak te maken",
        "description": "Inloggen",
        "name": "Inloggen"
      },
      "car_service_car_data": {
        "title": "Voer de voertuiggegevens in om de service te boeken",
        "description": "Voertuiggegevens",
        "name": "Voertuiggegevens"
      },
      "car_service_summary": {
        "title": "Laatste stap - Controleer je gegevens en selectie",
        "description": "Controleer",
        "name": "Controleer"
      },
      "car_services": {
        "title": "Werkplaats",
        "description": "diensten",
        "name": "diensten"
      },
      "car_service_stations": {
        "title": "Werkplaatsen",
        "description": "Werkstations",
        "name": "Werkstations"
      },
      "document_templates": {
        "title": "Documentsjablonen",
        "description": "Documentsjablonen",
        "name": "Documentsjablonen"
      },
      "document_template_details": {
        "title": "Documentsjabloon",
        "description": "Documentsjabloon",
        "name": "Documentsjabloon"
      }
    },
    "sort": {
      "relevance": "Relevantie",
      "username": "e-mail",
      "lastName": "Achternaam",
      "cdate": "Nieuwste eerst",
      "cdateDesc": "Nieuwste eerst",
      "dueDate": "Vervaldatum",
      "fav": "Meest populair",
      "priceAsc": "Laagste prijs",
      "rentPriceAsc": "Laagste prijs",
      "priceDesc": "Hoogste prijs",
      "rentPriceDesc": "Hoogste prijs",
      "priority": "Prioriteit",
      "mdate": "Laatst bijgewerkt",
      "countryName": "Land",
      "vat": "BTW",
      "sumGross": "Bedrag"
    },
    "jobTypes": {
      "wedding": "Bruiloft",
      "family_portrait": "Familieportret",
      "maternity": "Zwangerschap",
      "newborn": "Pasgeboren baby",
      "couple": "Paar",
      "pet": "Huisdier",
      "commercial_advertising": "Commercieel / Reclame",
      "fashion": "Mode",
      "model_portfolio": "Modelportefeuille",
      "event": "Evenement",
      "headshots": "Headshots",
      "real_estate_and_architecture": "Vastgoed en architectuur",
      "other": "Andere"
    },
    "pipelineEvent": {
      "afterLeadCreated": "gemaakt volgens lood",
      "afterJobAccepted": "geaccepteerd na de baan",
      "beforeJobScheduled": "voor de geplande opdracht",
      "afterJobScheduled": "na de geplande opdracht",
      "afterGalleryCreated": "gemaakt volgens galerie",
      "afterPreviousStage": "na vorige stap"
    },
    "paymentEvents": {
      "beforeJobDate": "voor opdrachtdatum",
      "onJobDate": "op jobdatum",
      "afterJobDate": "op jobdatum",
      "afterInvoiceDate": "na factuurdatum"
    },
    "autocomplete": {
      "placeholder": "Selecteer...",
      "noResultsText": "Geen resultaten gevonden",
      "loadingPlaceholder": "Laden"
    },
    "salesDoc": {
      "debtor_number": "Klantnummer",
      "order_number": "Bestelnummer",
      "order_totals": "Bestellingstotalen",
      "quote_from": "Van",
      "quote": "Aanbod",
      "quote_for": "Bieden voor",
      "quotes": "Aanbiedingen",
      "new_quote": "Nieuw aanbod",
      "invoice_from": "Van",
      "invoice": "Factuur",
      "invoice_date": "Factuurdatum",
      "tax_invoice": "Factuur",
      "order": "bestel",
      "credit_note": "Kredietnota",
      "credit_notes": "Kredietnota's",
      "invoice_for": "Factuur voor",
      "invoices": "Facturen",
      "new_invoice": "Nieuwe factuur",
      "cancellation_invoice_to": "Annuleringsfactuur voor",
      "cancellation_invoice": "Annulering factuur",
      "to_refunded": "Moet nog worden terugbetaald:",
      "positions": "Posities",
      "issued": "Uitgegeven",
      "cancelled": "STORNO",
      "billing_period": "Factureringsperiode",
      "billing_period_not_valid": "Ongeldige factureringsperiode",
      "pro_rate": "pro rata",
      "name": "Naam",
      "description": "Beschrijving van de",
      "quantity": "Hoeveelheid",
      "unit_price": "Prijs per eenheid",
      "inc_tax": "Belastingen inbegrepen",
      "exc_tax": "Zonder belastingen",
      "subtotal": "Subtotaal",
      "total": "Totaal bedrag",
      "amount_due": "Totaal netto",
      "total_due": "Factuurbedrag",
      "target": "Klant",
      "due_date": "Verschuldigd op",
      "to_pay": "Nog te betalen:",
      "payed": "Betaald",
      "payment": "Betaling",
      "download_csv_ready": "CSV-export voltooid",
      "no_vat_eu_country": "Belastingvrije intracommunautaire leveringen volgens § 4 Nr. 1b UstG.",
      "no_vat_non_eu_country": "Belastingvrije exportlevering volgens § 6 UstG",
      "thanks_for_order": "Hartelijk dank voor uw bestelling. Tenzij anders vermeld, worden de bovenstaande diensten uitgevoerd op de datum van deze factuur.",
      "bill_by_date": "Maak het bedrag, onder vermelding van het factuurnummer, uiterlijk %datum% over op de volgende rekening:",
      "intended_use": "Beoogd gebruik",
      "paid_with_cash_cc": "De factuur is contant betaald / met een EC-kaart",
      "amount_from_credit_card": "Het bedrag van %bedrag% wordt van je creditcard afgeschreven.",
      "amount_from_paypal": "Het bedrag van %bedrag% wordt van je PayPal-rekening afgeschreven.",
      "debit_from_account": "Het bedrag van %bedrag% wordt van je rekening afgeschreven.",
      "register_and_taxes": "Handelsregister & belastingen",
      "managing_director": "Algemeen directeur"
    },
    "provisionDoc": {
      "provision_calculation": "Commissieberekening",
      "client": "Klant",
      "invoice_no_date": "Factuurnr. / datum",
      "payedDate": "betaald op",
      "serviceType": "Type service",
      "amount": "Bedrag",
      "provisionRate": "Commissietarief",
      "provisionValue": "Commissie",
      "totalAmount": "Totaal bedrag",
      "provision": "Commissie",
      "provisions": "Commissies",
      "provision_for": "Commissie voor",
      "download_pdf": "Downloaden PDF"
    },
    "paymentStatus": {
      "late": "Laat",
      "paid": "Betaald",
      "unpaid": "Onbetaald",
      "inProgress": "In uitvoering"
    },
    "shopItem": {
      "plus_shipping": "plus verzendkosten",
      "variants_possible": "Er zijn verschillende selectiemogelijkheden beschikbaar"
    },
    "sepaAgreement": {
      "sepa_direct_debit_mandate": "SEPA-incassomachtiging (incassomachtiging)",
      "mandate_reference": "Referentie mandaat",
      "will_be_communicated_separately": "zal apart worden gecommuniceerd",
      "payee": "Begunstigde",
      "creditor": "Schuldeiser",
      "payer": "Betaler",
      "i_allow_payee_direct_debit": "Hierbij machtig ik de begunstigde om tot nader order betalingen van mijn rekening te innen door middel van automatische incasso. Tegelijkertijd geef ik mijn bank opdracht om de automatische incasso's te honoreren die door de begunstigde van mijn rekening worden getrokken.",
      "note_i_can_claim_reimbursement_within_eight_weeks": "Let op: ik kan binnen acht weken na de datum van afschrijving een verzoek tot terugbetaling van het afgeschreven bedrag indienen. De met mijn bank overeengekomen voorwaarden zijn van toepassing.",
      "document_issued_electronically_and_valid_without_signature": "Deze brief is automatisch aangemaakt en is ook geldig zonder handtekening",
      "shortening_notice_period": "De opzegtermijn verkorten",
      "deadline_for_advance_information_of_direct_debit_is_shortened_to_one_day": "De termijn voor voorafgaande kennisgeving van de SEPA-incasso wordt teruggebracht tot één dag.",
      "i_issue_sepa_direct_debit_mandate": "Hierbij geef ik het SEPA-mandaat voor automatische incasso af"
    },
    "sad": {
      "offer": "Aanbod",
      "modification": "Amendement",
      "order": "bestel",
      "offers": "Aanbiedingen",
      "orders": "Bestellingen",
      "offer_short": "Ang.",
      "order_short": "Het beste.",
      "conversion_rate_short": "CR",
      "conversion_rate": "Omrekeningskoers",
      "last_offer_date": "Laatste aanbod",
      "last_offer_date_short": "Laatste Ang.",
      "car_data": "Voertuiggegevens",
      "class": "Klasse",
      "model_name": "Naam model",
      "model_name_short": "Modelnaam (kort)",
      "model_range": "Serie",
      "body_design": "Lichaamsvorm",
      "car_color": "Kleur",
      "base_price": "Basisprijs",
      "list_price": "catalogusprijs",
      "optional_equipment": "Speciale uitrusting",
      "incl": "incl.",
      "factory_discount": "Fabriek",
      "cash_discount": "Contante korting",
      "volume_discount": "Kwantumkorting",
      "transportation_costs": "Overdrachtskosten",
      "price_reduction": "Prijsverlaging",
      "car_image": "Voertuigafbeelding van configurator",
      "discount": "Landgoed",
      "sales_price": "Verkoopprijs",
      "max_speed": "Maximale snelheid",
      "car_acceleration": "Versnelling (0 - 100)",
      "online_code": "Online code",
      "sales_activity": "Verkoop",
      "last_modified": "Laatste wijziging",
      "offer_date": "Datum aanbieding",
      "order_date": "Datum bestelling",
      "order_location": "Bestellocatie",
      "pre_contract_nr": "V-Bestelling nr.",
      "pre_contract_date": "V order dat.",
      "contract_nr": "Bestelnr.",
      "shipment_place": "Plaats van levering",
      "shipment_key": "Leversleutel",
      "special_agreement": "Speciale overeenkomst",
      "contact_person": "Contactpersoon",
      "customer_account_number": "Debiteurenrekeningnr.",
      "financing": "Financiering",
      "leasing": "Leasing",
      "financing_product": "Product",
      "paid_deposit": "Aanbetaling",
      "paid_deposit_leasing": "Speciale leasebetaling",
      "credit_amount": "Totaal leenbedrag",
      "monthly_rate": "Maandelijkse afbetaling",
      "contract_duration": "Runtime",
      "total_mileage": "Kilometerstand",
      "purchase_price": "Aankoopprijs",
      "annual_percentage_rate": "Effectieve jaarlijkse rentevoet",
      "nominal_interest_percentage_rate": "Debetrente vast p.a.",
      "debit_interest_percentage_rate": "Nominale rente per jaar",
      "total_amount": "Totaal bedrag",
      "final_instalment": "Laatste termijn",
      "application_date": "Aanvraagdatum",
      "application_approval_date": "Machtigingsdatum",
      "application_rejection_date": "Afkeuringsdatum",
      "client_private": "Privé",
      "client_business": "Commercieel",
      "monthly_leasing_rate": "Maandelijkse leasetermijn",
      "monthly_financing_rate": "Maandelijkse financiering",
      "monthly_insurance_rate": "Maandelijkse premie autoverzekering",
      "no_winleas_data_available": "Geen WinLEAS-gegevens beschikbaar",
      "winleas_data_available": "Financierings- (F) en/of leasinggegevens (L) beschikbaar via WinLEAS",
      "discount_cash": "Contante ambulance",
      "discount_volume": "Hoeveelheid/recycler korting",
      "discount_environmental": "Milieubonus",
      "discount_additional": "Aanvullende voorwaarden",
      "deduction": "Korting",
      "deduction_mbl": "MBL korting",
      "deduction_mbl_interest": "Rente korting MBL",
      "deduction_consultant": "Korting consultant",
      "deduction_consultant_interest": "Rente disconteringsadviseur",
      "commission": "Commissie",
      "commission_standard": "Standaard",
      "commission_addition_deduction": "Opwaarderen / afwaarderen",
      "commission_subsequent_scope_of_supply": "Volgende. Omvang van de levering",
      "commission_total": "Totaal",
      "subsidy": "Subsidie",
      "subsidy_contract_partner": "Subsidie VP",
      "subsidy_factory": "Subsidiefabriek",
      "subsidy_mbvd": "Subsidie MBVD",
      "shortcut_lp_kp": "LP/KP",
      "campaign": "Actie",
      "service_rate": "Servicetarief",
      "residual_value": "Restwaarde",
      "table_residual_value": "Restwaarde tabel",
      "residual_value_change": "Verandering in restwaarde",
      "ref": "met betrekking tot",
      "net": "net",
      "gross": "bruto",
      "list_price_short": "LP",
      "sales_price_short": "KP",
      "note_save": "Notitie opslaan",
      "notes": "Opmerkingen",
      "salesman": "Verkoper",
      "salesman_blacklist": "Verkoper (Zwarte lijst)",
      "salesman_number": "Verkoper nr.",
      "salesman_number_short": "Nee.",
      "enterprise_number": "Bedrijf nr.",
      "Mercedes": "Mercdes",
      "Smart": "Slim",
      "equipment_line": "Lijn",
      "packages": "Pakketten",
      "upholstery": "Bekleding",
      "single_equipments": "Andere",
      "included_in": "vervat in",
      "store": "Tak",
      "save_filterset": "Filterset opslaan",
      "internal_activity": "Intern bedrijf",
      "offer_made": "Aanbod gemaakt",
      "in_progress": "In uitvoering",
      "predecessor_offer": "Vorige aanbieding / duplicaat",
      "lost": "Zakelijk verloren",
      "ordered": "Bestelling voltooid",
      "canceled": "Bestelling geannuleerd"
    },
    "rad": {
      "earnings": "Opbrengst",
      "occupancy_rate": "Gebruik",
      "amount": "Bedrag"
    },
    "external_account_status": {
      "login_fail": "Controleer de toegangsgegevens! Anders kunnen er geen gegevens meer worden uitgelezen.",
      "login_warning": "Controleer de toegangsgegevens!",
      "login_valid": "Toegangsgegevens correct",
      "last_successful_login": "Laatste succesvolle login"
    },
    "categoryitems_stock": "Stuk",
    "initialize": "Init",
    "customer": "Bedrijf",
    "customer_": "Klant",
    "select_mandant": "Selecteer klant",
    "initial_title": "Oorspronkelijke titel van het advertentiemedium",
    "will_be_shown_to_the_user_in_frontend": "Wordt weergegeven aan gebruikers in de frontend",
    "producttemplate_data_saved": "Sjabloongegevens opgeslagen",
    "product_size": "Productformaat / -grootte",
    "template_upload_files": "Selecteer bestand(en) om te uploaden",
    "example_of_product_size": "bijv: DIN A4, DIN lang...",
    "output_format": "Uitvoerformaat",
    "product_size_width": "Breedte in pixels",
    "product_size_height": "Hoogte in pixels",
    "template_folder": "Sjabloonpad",
    "template_dpi": "DPI voor uitvoer",
    "template_upload": "uploaden",
    "template_pageSpace": "Rasterruimtes",
    "output_exportType": "Type uitvoer",
    "template_fonts": "Lettertypen",
    "template_fontSizeMin": "Lettergrootte min",
    "template_styles": "CSS- & LESS-gegevens",
    "template_fontSizeMax": "Lettergrootte max",
    "template_init_amount_of_pages": "Initieel aantal pagina's",
    "template_page_add_increase": "Ontrouw",
    "template_pageMaxAmount": "Aantal pagina's max.",
    "template_pageMinAmount": "Aantal pagina's min",
    "template_pageTop": "Vulling bovenkant",
    "template_pageBottom": "Vulling onderkant",
    "template_pageFirst": "Paginanummer Titelpagina",
    "template_pageFirstFixed": "Voorpagina gerepareerd",
    "template_pageLast": "Paginanummer Laatste pagina",
    "template_pageLastFixed": "Laatste pagina gerepareerd",
    "template_endless": "Eindeloze pagina",
    "template_fixed": "Pagina gerepareerd",
    "template_sample_page": "Aantal pagina's Standaardinhoud",
    "font_colors": "Tekstkleuren",
    "back_colors": "Achtergrondkleuren",
    "client_logo": "Bedrijfslogo",
    "template_pageNumber": "Aantal pagina's",
    "page_names": "Pagina-instellingen",
    "add_contentBlock": "Inhoudsblok toevoegen",
    "css": "Algemeen CSS",
    "cssPDF": "CSS alleen voor PDF-uitvoer",
    "cssPrintPDF": "CSS alleen voor afdrukbare PDF-uitvoer",
    "cssWebsite": "CSS voor uitvoer als webpagina",
    "chosenItem": "Huidige productselectie",
    "pdf_price": "PDF-prijs",
    "auflagen": "Voorwaarden",
    "print_prices": "Basisprijzen",
    "proof_base_price": "Controle basisprijsgegevens",
    "new_proof_base_price": "Controle nieuwe basisprijsgegevens",
    "get_vendor_price": "Prijzen opvragen",
    "add_variant_line": "Regel toevoegen",
    "remove_all_item_variant": "Alle edities verwijderen",
    "get_vendor_prices": "Prijs afroep leverancier",
    "get_vendor_id": "Product-ID van leverancier opvragen",
    "no_vendor_id": "Geen product-ID leverancier ingesteld!",
    "cc_item_id": "Product ID CreaCheck",
    "vendor_item_id": "Product ID Leverancier",
    "vendor_item_name": "Naam product Leverancier",
    "vendor_item_missing_attributes": "Er kan geen prijs worden gevonden voor deze combinatie van kenmerken.",
    "vendor_item_save_attributes": "Selecteer de attributen!",
    "vendor_item_must_define_attributes": "U moet attributen definiëren om varianten te kunnen maken.",
    "vendor_item_has_no_attributes": "Deze productgroep heeft geen kenmerken",
    "vendor_item_all_n_quantities": "Alle hoeveelheden {quantityCount} weergeven",
    "vendor": "Leverancier",
    "internal_item_id": "Interne ID",
    "select_vendor_product": "Selecteer productgroep",
    "item_type": "Soort product",
    "print_item": "Product afdrukken",
    "website_item": "Website",
    "normal_item": "Ander product",
    "not_more_pages": "Je kunt niet meer paginanamen maken dan er mogelijke pagina's zijn!",
    "info_title_page": "Titelpagina kan niet worden verplaatst of verwijderd",
    "info_last_page": "Laatste pagina kan niet worden verplaatst of verwijderd",
    "page_definition_page_count_info": "Paginanummer 0 = standaardwaarde voor alle pagina's zonder definitie, laatste voor laatste pagina",
    "padding_on_top": "Afstand boven",
    "padding_on_bottom": "Afstanden onder",
    "padding_on_left": "Afstand links",
    "padding_on_right": "Afstand rechts",
    "delimitation_of_grid_blocks": "Rasterblokken kunnen onbeperkt worden toegevoegd. De pagina wordt dan steeds langer.",
    "fixed_page": "De pagina staat vast en kan niet worden verplaatst.",
    "page_definition": "Pagina definitie",
    "add_definition": "Definitie toevoegen",
    "page_default_value": "Paginanummer 0 = standaardwaarde voor alle pagina's zonder definitie",
    "page_count": "Aantal pagina's",
    "page_widths": "Zijdelingse breedtes",
    "page_heights": "Zijhoogten",
    "no_special_formatting": "Geen speciale opmaak",
    "min_max_font_size": "Min-/Max-FontSize",
    "activate_min_max_font_size": "Min-/Max-FontSize activeren of deactiveren",
    "filename": "Bestandsnaam",
    "for_user": "Voor gebruikers",
    "user_can_use_font": "De gebruiker kan dit lettertype gebruiken in de editor",
    "import_successful": "Importeren voltooid, kijk in de andere tabbladen",
    "import_resets_template": "Importeren reset de sjabloon, deze moet opnieuw geïnitialiseerd worden, alle bestaande klantedities met deze sjabloon worden onbruikbaar!!!!",
    "problem": "Er heeft zich een probleem voorgedaan",
    "reading_pages": "Pagina's lezen",
    "done": "Afgewerkt",
    "versions": "Versies",
    "template_not_edited": "Deze sjabloon is nog niet bewerkt.",
    "dataProof_infoText": "Bij een handmatige gegevenscontrole controleren onze experts voor jou alle technische criteria van de prepressfase. Deze omvatten de bedrukbaarheid, de naleving van alle technische specificaties en het subjectieve kleureffect. Meer gedetailleerde informatie over gegevenscontrole vindt u onder FAQ.",
    "media_library": "Mediatheek",
    "count": "Hoeveelheid",
    "welcome_message": "Hallo, {naam}",
    "email": "e-mail",
    "website": "Website",
    "phone": "Telefoon",
    "fax": "fax",
    "password": "wachtwoord",
    "repeat_password": "Herhaal wachtwoord",
    "company": "Bedrijf",
    "company_name": "Bedrijfsnaam",
    "company_data_timeline_tab_title": "Tijdlijn van bedrijfsgegevens",
    "company_data_success": "Bedrijfsgegevens zijn succesvol gewijzigd!",
    "company_data_error": "Fout bij het wijzigen van de bedrijfsgegevens!",
    "updated_by": "Bijgewerkt door",
    "companies": "Bedrijven",
    "first_name": "Voornaam",
    "last_name": "Achternaam",
    "street": "Straat",
    "house_number": "Huisnummer",
    "apartment_number": "Plat nummer",
    "address_line2": "Adresregel 2",
    "zip_code": "Postcode",
    "city": "Stad",
    "country": "Land",
    "currency": "Valuta",
    "previous_year": "Vorig jaar",
    "vat_name": "BTW Naam",
    "vat_id_name": "BTW ID Naam",
    "vat_id": "BTW - IdNr",
    "vat_rates": "BTW-tarieven",
    "coc_number": "COC-nummer",
    "registered_at": "Geregistreerd bij",
    "page": "Pagina",
    "page_of": "van",
    "save": "Sla",
    "save_and_email": "Opslaan en e-mailen",
    "add": "Voeg  toe",
    "add_client": "Bedrijf toevoegen",
    "update_company_data": "Bedrijfsnaam wijzigen",
    "add_user": "Gebruiker toevoegen",
    "add_product": "Product toevoegen",
    "add_producttemplate": "Sjabloon toevoegen",
    "add_question": "Vraag toevoegen",
    "add_option": "Optie toevoegen",
    "add_questionnaire": "Vragenlijst toevoegen",
    "add_template": "Sjabloon toevoegen",
    "add_position": "Positie toevoegen",
    "add_pipeline": "Pijpleiding toevoegen",
    "add_quote": "Aanbod toevoegen",
    "add_invoice": "Factuur toevoegen",
    "add_stage": "Niveau toevoegen",
    "add_gallery": "Galerij toevoegen",
    "add_job": "Baan toevoegen",
    "add_payment_schedule": "Betalingsplan toevoegen",
    "add_payment": "Betaling toevoegen",
    "add_location": "Locatie toevoegen",
    "add_data_definition": "Gegevensdefinitie toevoegen",
    "add_car": "Voertuig toevoegen",
    "add_document": "Document toevoegen",
    "add_document_type": "Documenttype toevoegen",
    "add_team": "Team toevoegen",
    "add_brand": "Merk toevoegen",
    "add_model": "Model toevoegen",
    "add_model_group": "Modelgroep toevoegen",
    "add_model_asset": "Modelafbeelding toevoegen",
    "add_category": "Categorie toevoegen",
    "add_attribute": "Eigenschap toevoegen",
    "add_attribute_group": "Attribuutgroep toevoegen",
    "add_value": "Waarde toevoegen",
    "add_variant": "Variant toevoegen",
    "add_lead": "Lood toevoegen",
    "add_task": "Taak toevoegen",
    "add_page": "Pagina toevoegen",
    "add_data_push": "Gegevens pushen toevoegen",
    "add_white_label": "Wit label project toevoegen",
    "add_menu_element": "Menu-item toevoegen",
    "add_secondary_menu_element": "Secundair menu-item toevoegen",
    "add_cms_element": "CMS element toevoegen",
    "add_lead_catcher": "Loodvanger toevoegen",
    "add_white_label_plugin": "White label plugin toevoegen",
    "add_lead_per_mail_rule": "Regel lead per e-mail toevoegen",
    "add_message": "Een bericht toevoegen",
    "add_car_service": "Service toevoegen",
    "add_car_service_station": "Werkstation toevoegen",
    "add_team_member": "Teamlid toevoegen",
    "add_document_template": "Documentsjabloon toevoegen",
    "add_lead_autoresponse": "Lead autorespons toevoegen",
    "add_main_menu_version": "Versie hoofdmenu toevoegen",
    "add_lead_tagger": "Lead tagger toevoegen",
    "add_lead_assignment_strategy": "Toewijzingsstrategie toevoegen",
    "add_escalation_rule": "Escalatieregel toevoegen",
    "add_coupon": "Voucher toevoegen",
    "add_company": "Bedrijf toevoegen",
    "add_item": "Product toevoegen",
    "add_manual_entry": "Handmatige invoer toevoegen",
    "add_allowed_domain": "Geautoriseerd domein toevoegen",
    "update_allowed_domain": "Bevoegd domein bijwerken",
    "send_message": "Verstuur bericht",
    "send": "Stuur",
    "details": "details",
    "delete": "Verwijder",
    "undelete": "Herstel",
    "undelete_user_confirm": "De gebruiker herstellen?",
    "or": "of",
    "actions": "Acties",
    "client": "Bedrijf",
    "job": "Job",
    "active_jobs": "Actieve banen",
    "questionnaire": "Vragenlijst",
    "review_and_send": "Controleren en verzenden",
    "download": "Downloaden",
    "duplicate": "Dubbele",
    "client_name": "Naam",
    "address": "Adres",
    "title": "Titel",
    "uploading": "Uploaden",
    "status": "Status",
    "next_stage": "Volgende niveau",
    "type": "Type",
    "schedule": "Dienstregeling",
    "location": "Locatie",
    "sent_on": "Verzonden op",
    "name": "Naam",
    "description": "Beschrijving van de",
    "bill_number": "Factuurnummer",
    "quote_number": "Aanbiedingsnummer",
    "do_sort": "Sorteer",
    "date": "datum",
    "gallery": "Galerij",
    "questionnaires": "Vragenlijsten",
    "job_takes_whole_day": "Deze taak neemt de hele dag in beslag",
    "from": "Van",
    "to": "Naar",
    "question": "Vraag",
    "subject": "Onderwerp",
    "content": "Inhoud",
    "template": "Sjabloon",
    "stage_has_due_date": "Deze stap heeft een vervaldatum",
    "stage_sends_automated_email": "Deze stap stuurt een automatische e-mail",
    "day": "Dag",
    "days": "Dagen",
    "hours": "Uren",
    "review": "Controleer",
    "timezone": "Tijdzone",
    "client_since": "Bedrijf toegevoegd op",
    "user_since": "Gebruiker sinds",
    "payment_instructions": "Betalingsinstructies",
    "bank_name": "Naam bank",
    "debtor_number": "Debiteur-ID",
    "account_holder": "Rekeninghouder",
    "account_number": "Rekeningnummer",
    "bank_location": "Locatie bank",
    "bank_code_number": "Sorteercode bank",
    "iban": "IBAN",
    "swift_bic": "SWIFT/BIC",
    "company_logo": "Bedrijfslogo",
    "percent_of_order": "Percentage van de bestelling",
    "percent_of_remaining_balance": "Percentage van het resterende bedrag",
    "amount": "Bedrag",
    "due": "Due",
    "payment_schedule": "Betalingsplan",
    "record_payment": "Log betaling",
    "mark_as_unpaid": "Markeren als onbetaald",
    "close": "Sluit",
    "yes": "Ja",
    "want_to_mark_the_payment_as_paid": "Wil je deze betaling echt als betaald markeren?",
    "want_to_mark_the_payment_as_unpaid": "Wil je deze betaling echt als onbetaald markeren?",
    "payment_marked_as_paid": "Gelogde betaling",
    "payment_marked_as_unpaid": "Betaling gemarkeerd als onbetaald",
    "overdue_payments": "Achterstallige betalingen",
    "next_payment": "Volgende betaling",
    "quote_accepted": "Aanbod aanvaard",
    "quote_rejected": "Aanbod afgewezen",
    "todays_tasks": "Taken voor vandaag",
    "overdue_tasks": "Achterstallige taken",
    "overdue_and_upcoming_payments": "Achterstallige en aankomende betalingen",
    "jobs_in_progress": "Vacatures in uitvoering",
    "appointments": "Data",
    "appointment": "Datum",
    "last_update": "Laatste update",
    "mark_as_completed": "Markeren als voltooid",
    "want_to_mark_stage_complete": "Wil je de stap markeren als voltooid?",
    "stage_marked_complete": "Stadium gemarkeerd als voltooid",
    "gallery_download": "Afbeelding downloaden",
    "gallery_sharing": "Galerij delen",
    "gallery_social_media": "Afbeeldingen op sociale media",
    "preview": "Voorbeeld",
    "invoice_preview": "Factuurvoorbeeld",
    "preview_pdf": "Voorbeeld PDF",
    "message": "Bericht",
    "signup": "Registreer",
    "continue": "Ga verder",
    "password_assistance": "Hulp bij wachtwoord",
    "password_renewing": "Nieuw wachtwoord",
    "not_any_user_information": "Geen gebruikersinformatie.",
    "failed_redirection": "Doorsturen mislukt.",
    "renew_password": "Voordat je kunt inloggen, moet je een nieuw wachtwoord instellen. We hebben je zojuist een e-mail gestuurd met instructies.",
    "get_new_password": "Nieuw wachtwoord aanvragen",
    "set_new_password": "Uw nieuwe wachtwoord instellen",
    "forgot_password": {
      "infotext": "Als u uw wachtwoord bent vergeten, kunt u het hier opnieuw instellen. Voer in het onderstaande formulier uw geregistreerde e-mailadres in en klik op \"Doorgaan\". We sturen je dan een e-mail met meer informatie.",
      "infotext_robethood": "Als u uw wachtwoord bent vergeten, kunt u het hier opnieuw instellen. Voer in het onderstaande formulier het geregistreerde e-mailadres in en klik op \"Doorgaan\". Wij sturen u dan een e-mail met meer informatie.",
      "info_email_not_used": "Als je het e-mailadres dat is gekoppeld aan je Creacheck-account niet meer gebruikt, neem dan contact met ons op via ons contactformulier: {link}.",
      "info_email_not_used_robethood": "Als je het e-mailadres dat aan je account is gekoppeld niet meer gebruikt, neem dan contact met ons op via ons contactformulier: {link}.",
      "enter_new_password": "Je hoeft alleen maar deze stap uit te voeren om je aan te melden bij Creacheck. Voer gewoon twee keer je nieuwe wachtwoord in. Zo eenvoudig is het."
    },
    "thanks_for_signing_up": "Bedankt voor uw registratie",
    "confirm_your_email": "Bevestig je e-mailadres",
    "signed_up_gratulations": "Welkom bij CreaCheck. We hebben je een e-mail gestuurd om het registratieproces te voltooien. Gelieve uw registratie te bevestigen.",
    "signed_up_gratulations_robethood": "Welkom op onze website. We hebben je een e-mail gestuurd om het registratieproces te voltooien. Gelieve uw registratie te bevestigen.",
    "check_email_for_message_from_us": "Controleer je e-mail inbox.",
    "check_email_for_message_from_us_robethood": "Controleer uw e-mail inbox",
    "new_clients": "Nieuwe klanten",
    "users": "Gebruikers",
    "user": "Gebruikers",
    "won_from_count_leads": "Gewonnen uit {aantal} leads",
    "deals_won_out_of_count": "Deals gewonnen door {telling}",
    "new_badge": "Nieuw",
    "answer_badge": "Antwoord",
    "accept": "Accepteer",
    "reject": "Weiger",
    "quote_rejected_on_date": "Aanbod afgewezen op {datum}",
    "quote_accepted_on_date": "Aanbod aanvaard op {datum}",
    "want_to_accept_the_quote": "Wil je het aanbod accepteren? De aannemer wordt direct op de hoogte gebracht van je beslissing.",
    "want_to_reject_the_quote": "Wil je het aanbod afwijzen? De aannemer wordt direct op de hoogte gebracht van je beslissing.",
    "want_to_convert_quote_to_invoice": "Wil je deze aanbieding omzetten in een factuur?",
    "convert_quote_to_invoice": "Zet de aanbieding om in een factuur",
    "convert_to_invoice": "Omzetten naar factuur",
    "convert_quote_from_template": "Aanbieding converteren naar een andere sjabloon",
    "completed_on_date": "Voltooid op {datum}",
    "due_date_on_date": "Vervaldatum {datum}",
    "due_date": "Vervaldatum",
    "current_stages_due_date": "Vervaldatum van de huidige fase",
    "go_pro": "Ga Pro",
    "recent_activity": "Laatste activiteit",
    "load_more": "Meer laden",
    "your_galleries": "Uw galerijen",
    "unread_by_you": "Ongelezen door jou",
    "count_images": "{telling} afbeeldingen",
    "view": "Bekijk",
    "filled_out_on": "Ingevuld op",
    "filled_out_by": "Ingevuld door",
    "you_answered_the_questionnaire_on_date": "U beantwoordde de vragenlijst op {datum}",
    "company_sent_a_questionnaire": "{bedrijf} heeft een vragenlijst gestuurd en vraagt om antwoorden",
    "allow_to_download_full_res": "De klant toestaan om afbeeldingen in volledige resolutie te downloaden",
    "allow_to_share_with_friends": "Laat de klant de galerij delen met vrienden",
    "allow_to_share_on_social_media": "Laat de klant afbeeldingen delen via sociale media",
    "got_it": "OK",
    "learn_more": "Meer informatie",
    "this_website_uses_cookies": "Onze website maakt gebruik van cookies om ervoor te zorgen dat u de beste ervaring krijgt op onze website.",
    "are_you_sure": "Weet je het zeker?",
    "no": "Geen",
    "closed_please_add_timeframe": "<span><span className='text-danger semi-bold'>Gesloten</span> - voeg een tijdsbestek toe</span>",
    "not_active_please_add_timeframe": "<span><span className='text-danger semi-bold'>Niet actief</span> - voeg een tijdsbestek toe</span>",
    "note": "Opmerking",
    "change_password": "Wachtwoord wijzigen",
    "roles": "Rollen en rechten",
    "active_set": "Huidig abonnement",
    "infos": "Informatie over",
    "documents": "Documenten",
    "document": "Document",
    "financial": "Financieel",
    "bookingAccountNumber": "Account plaatsen",
    "costAccountNumber": "Kostenplaats",
    "locations": "Locaties",
    "white_label": "Wit label",
    "show_password_text": "Wachtwoordtekst weergeven",
    "parent": "Ouder",
    "change_parent": "Wijzig ouder",
    "cancel": "Annuleren",
    "timeline_undo": "Ongedaan maken",
    "confirm_action_ramifications": "Bevestig dat u de gevolgen van uw actie begrijpt",
    "client_will_not_be_visible_in_search_results": "De geselecteerde klant is niet langer zichtbaar in uw klantenzoekfunctie",
    "changing_parent_takes_a_while": "Het kan even duren om de precursor te veranderen",
    "load_message_from_template": "Bericht laden van sjabloon",
    "questions": "Vragen",
    "search": "Zoek op",
    "search_for": "Zoeken naar",
    "dynamic_data_definitions": "Dynamische gegevensdefinities",
    "dynamic_data_definition": "Dynamische gegevensdefinitie",
    "data_identifier": "Gegevensidentificatie",
    "cars": "Voertuigen",
    "rentals": "Huren",
    "no_car_available": "Geen trolley beschikbaar",
    "file": "Bestand",
    "files": "Bestanden",
    "teams": "Teams",
    "team": "Team",
    "document_types": "Typen documenten",
    "document_type": "Type document",
    "system_type": "Type systeem",
    "brands": "Merken",
    "models": "Modellen",
    "model": "Model",
    "model_groups": "Modelgroepen",
    "model_group": "Modelgroep",
    "model_assets": "Afbeeldingen van modellen",
    "model_asset": "Modelbeeld",
    "categories": "Categorieën",
    "attributes": "Attributen",
    "attribute": "Attribuut",
    "values": "Waarden",
    "value": "Waarde",
    "select_category": "Selecteer categorie",
    "position": "Positie",
    "color": "Kleur",
    "car": "Product",
    "category": "Categorie",
    "brand": "Merk",
    "required": "Verplicht veld",
    "multilanguage": "Meerdere talen",
    "seo": "SEO",
    "page_title": "Pagina titel",
    "keywords": "Trefwoorden",
    "item": "Artikel",
    "sku": "SKU",
    "variants": "Varianten",
    "variant": "Variant",
    "price": "Prijs",
    "prices": "Prijzen",
    "variant_attributes": "Varianteigenschappen",
    "no_value_set": "Geen waarde ingesteld",
    "identification": "Identificatie",
    "templates": "Sjablonen",
    "vin": "FIN",
    "leads": "Leads",
    "lead": "Lood",
    "order_it": "Bestel",
    "order": "bestel",
    "orders": "Bestellingen",
    "images": "Foto's",
    "image": "Afbeelding",
    "upc": "UPC",
    "visibility": "Zichtbaarheid",
    "show_item_online": "Product online zichtbaar",
    "use_as_resource": "Gebruik als bron",
    "stock_and_prices": "Inventaris en prijzen",
    "sales_price": "Verkoopprijs",
    "sales_point": "Distributie",
    "warehouse": "Magazijn",
    "stock_level": "Voorraad",
    "rent_price": "Huurprijs",
    "pseudo_price": "Pseudoprijs",
    "express_price": "Spoedprijs",
    "overnight_price": "Overnachtingsprijs",
    "sameday_price": "Dagprijs",
    "basic_price": "Basisprijs",
    "weekday_price": "Weekdag Prijs",
    "weekend_price": "Weekendprijs",
    "week_price": "Prijs per week",
    "month_price": "Maandelijkse prijs",
    "unit_of_measure": "Meeteenheid",
    "basic_unit": "Basiseenheid",
    "packaging_unit": "Verpakkingseenheid",
    "subscribe": "Aanmelden",
    "all": "Alle",
    "pipeline": "Pijpleiding",
    "pipelines": "Pijpleidingen",
    "default_team": "Standaard team",
    "select_pipeline": "Selecteer een pijplijn",
    "please_select_pipeline_first": "Selecteer eerst een pijplijn",
    "view_type": "Bekijk",
    "stage": "Niveau",
    "stages": "Stappen",
    "properties": "Eigenschappen",
    "unassigned": "Zonder toewijzing",
    "assignee": "Processor",
    "order_type": "Type bestelling",
    "internal_comment": "Intern commentaar",
    "comments": "Reacties",
    "comment": "Opmerking",
    "no_comments_yet": "Er zijn nog geen reacties",
    "show_all_comments": "Alle opmerkingen weergeven",
    "people": "Personen",
    "watchers": "Waarnemer",
    "dates": "Data",
    "date_created": "Gemaakt",
    "date_updated": "Bijgewerkt",
    "closed": "Gesloten",
    "start_watching_lead": "Begin met kijken naar deze aanwijzing",
    "stop_watching_lead": "Stop met kijken naar deze voorsprong",
    "no_longer_watching_lead": "Je neemt deze aanwijzing niet langer waar",
    "watching_lead": "Je observeert deze leiding",
    "wrote_comment": "Heeft een commentaar geschreven",
    "replied_to_comment": "Beantwoord een reactie",
    "language": "Taal",
    "languages": "Talen",
    "primary_language": "Hoofdtaal",
    "secondary_languages": "Secundaire talen",
    "won": "Won",
    "lost": "Verloren",
    "change_pipeline": "Verander pijplijn",
    "start_pipeline": "Pijplijn starten",
    "pipeline_started": "Pijplijn gelanceerd",
    "change_stage": "Niveau wijzigen",
    "switch_team": "Wijzigingsteam",
    "open": "Open",
    "budget": "Budget",
    "always_available": "Altijd beschikbaar",
    "default_location": "Standaard locatie",
    "selected_category_has_no_attributes": "Geselecteerde categorie heeft geen kenmerken",
    "car_overview": "Overzicht voertuigen",
    "make_reservation": "Een reservering maken",
    "reservation": "Reservering",
    "cms_pages": "Pagina's",
    "grid_fluid": "Vloeistof",
    "grid_normal": "Normaal",
    "cms_add_container": "Container toevoegen",
    "cms_add_row": "Regel toevoegen",
    "cms_add_element": "Element toevoegen",
    "media_files": "Mediabestanden",
    "upload": "uploaden",
    "page_design": "Pagina ontwerp",
    "translations": "Vertalingen",
    "translate": "Vertaal",
    "category_filters": "Categorie filter",
    "attribute_groups": "Attribuutgroepen",
    "select_attribute": "Selecteer kenmerk",
    "display": "Weergave",
    "select_multiple": "Meervoudige selectie",
    "use_null_as_min_val": "Stel 0 in als minimum",
    "filter": "Filters",
    "do_filter": "Filter",
    "mobile_phone": "Mobiele telefoon",
    "mark_lead_won": "Markeer voorsprong als gewonnen",
    "mark_lead_lost": "Markeer lood als verloren",
    "mark_lead_deleted": "Markeer lood als verwijderd",
    "reopen_lead": "Lood heropenen",
    "won_reason": "Reden waarom gewonnen",
    "lost_reason": "Reden waarom verloren",
    "delete_reason": "Reden waarom verwijderd",
    "reopen_reason": "Reden voor heropening",
    "no_reason_specified": "Geen reden opgegeven",
    "lead_marked_won": "Lead gemarkeerd gewonnen. Gefeliciteerd",
    "lead_marked_lost": "Lood gemarkeerd verloren",
    "lead_marked_deleted": "Leiding gemarkeerd verwijderd",
    "lead_reopened": "Lood heropend",
    "stage_changed": "Loodniveau veranderd",
    "pipeline_changed": "Leads pijplijn veranderd",
    "team_changed": "Lead toegewezen aan een ander team",
    "current_pipeline": "Huidige pijplijn",
    "current_stage": "Huidig niveau",
    "file_extension": "Uitbreiding",
    "file_size": "Maat",
    "file_uploaded": "Geüpload",
    "no_client_assigned": "Geen klant toegewezen",
    "no_products_assigned": "Geen producten toegewezen",
    "no_order_assigned": "Geen volgorde toegewezen",
    "planned_duration_in_days": "Geplande duur (dagen)",
    "lead_budget_must_be_set": "Er moet een leidend budget worden vastgesteld",
    "client_must_be_assigned": "Klant moet worden toegewezen",
    "order_must_be_assigned": "De volgorde moet worden toegewezen",
    "product_must_be_assigned": "Product moet worden toegewezen",
    "lead_has_to_have_following_documents": "De leiding moet de volgende documenten hebben (documenten moeten geldig zijn)",
    "client_has_to_have_following_documents": "De klant moet de volgende documenten hebben (documenten moeten geldig zijn)",
    "count_days": "{telling, meervoud, een {# dag} andere {# dagen}}",
    "longer": "Langer",
    "date_from": "Datum van",
    "time_from": "Tijd van",
    "date_to": "Datum tot",
    "time_to": "Tijd tot",
    "rate": "Prijs",
    "car_rental_weekday": "Verhuur - Weekdagtarief",
    "car_rental_weekend": "Verhuur - Weekendtarief",
    "car_rental_week": "Verhuur - wekelijkse betaling",
    "car_rental_month": "Huur - Maandelijkse termijn",
    "document_overdue_please_review": "Document te laat. Controleer",
    "count_cars": "{telling, meervoud, een {# voertuig} andere {# voertuigen}}",
    "count_products": "{telling, meervoud, een {# product} andere {# producten}}",
    "we_found_count_cars": "We hebben {telling, meervoud, een {# voertuig} andere {# voertuigen}} gevonden.",
    "we_found_count_products": "We hebben {telling, meervoud, een {# product} andere {# producten}} gevonden.",
    "delete_all_filters": "Alle filters verwijderen",
    "image_recognition_tags": "Tags herkend uit de afbeelding",
    "username": "Gebruikersnaam",
    "handover_must_be_performed": "Het protocol voor voertuigoverdracht moet worden ingevuld",
    "document_pages": "Document pagina's",
    "pages": "Pagina's",
    "handover": "Overdracht",
    "mileage": "Kilometerstand",
    "client_documents": "Documenten van klanten",
    "lead_documents": "Documenten leiden",
    "fuel_level": "Brandstofniveau",
    "start_editing": "Bewerken starten",
    "design": "Ontwerp",
    "responsive": "Responsief",
    "back": "Terug",
    "use_text_description": "Tekstbeschrijving gebruiken",
    "use_visual_builder": "Visuele bouwer gebruiken",
    "set_image": "Beeld instellen",
    "select_image": "Afbeelding selecteren",
    "uploaded_by_me": "Geüpload door mij",
    "current_item": "Huidig artikel",
    "current_page": "Huidige pagina",
    "difficulties": "Moeilijkheden",
    "damages": "Schade",
    "damage_note": "Kennisgeving van schade",
    "no_damages_in_protocol": "Geen schade in het logboek",
    "add_damage": "Schade toevoegen",
    "my_company": "Mijn bedrijf",
    "user_administration": "Gebruikersadministratie",
    "client_administration": "Contactbeheer",
    "crm_administraion": "CRM-beheer",
    "content_administration": "Beheer van inhoud",
    "product_administration": "Productbeheer",
    "producttemplate_administration": "Beheer van sjablonen",
    "car_service_administration": "Beheer van de werkplaats",
    "site_administration": "Websitebeheer",
    "data_push": "Gegevens pushen",
    "client_has_no_ancestors": "Klant heeft geen voorouders",
    "domain": "Domein",
    "entity": "Entiteit",
    "event": "Evenement",
    "endpoint_url": "URL eindpunt",
    "authentication": "Authenticatie",
    "headers": "Koppen",
    "none": "Geen",
    "social_media": "Sociale media",
    "style": "Stijl",
    "html_header": "HTML koptekst",
    "custom_html_header": "Aangepaste HTML-header",
    "css_file_name": "CSS-bestandsnaam door gebruiker gedefinieerd",
    "custom_css": "Aangepaste CSS",
    "custom_less": "Door gebruiker gedefinieerd LESS",
    "custom_font_folder": "Lettertypenmap selecteren",
    "custom_font": "Aangepast lettertype uploaden",
    "custom_css_mail": "Aangepaste CSS voor e-mails",
    "main_menu": "Hoofdmenu",
    "menu_css_class": "CSS-klasse hoofdmenu",
    "menu_css_class_scroll": "CSS-klasse hoofdmenu bij scrollen",
    "white_label_projects": "White label projecten",
    "imprint": "Afdruk",
    "terms_of_service": "GTC",
    "rental_terms_of_service": "Algemene huurvoorwaarden",
    "car_sales_terms_of_service": "Algemene verkoopvoorwaarden voor gebruikte voertuigen",
    "item_sales_terms_of_service": "Algemene verkoopvoorwaarden Producten",
    "privacy_policy": "Gegevensbescherming",
    "cancellation_policy": "Annuleringsvoorwaarden",
    "footer": "Voettekst",
    "hide_footer": "Voettekst verbergen",
    "unknown_property": "Onbekende parameter",
    "unknown": "onbekend",
    "price_from": "van",
    "price_to": "tot",
    "slider_from": "van",
    "slider_to": "tot",
    "within": "binnen",
    "of": "van",
    "within_count_km_of_place": "binnen {telling}km van {plaats}",
    "click_to_start_geolocation": "Klik hier om geolokalisatie te starten",
    "geolocation_error": "Er is een probleem opgetreden bij het herkennen van uw positie of uw positie is niet bekend. Voer uw positie handmatig in.",
    "count_km_from": "km van",
    "car_power": "Prestaties",
    "car_power_unit": "PS",
    "car_gear": "Versnellingsbak",
    "car_capacity": "Verplaatsing",
    "car_capacity_unit": "cm³",
    "car_fueltype": "Brandstof",
    "car_type": "Type voertuig",
    "car_num_previous_owners": "Aantal voertuigeigenaren",
    "car_num_doors": "Aantal deuren",
    "car_num_seats": "Aantal zitplaatsen",
    "car_emission_class": "Klasse van verontreiniging",
    "car_condition": "Staat van het voertuig",
    "car_construction_year": "Jaar van bouw",
    "car_color_manufacturer": "Kleur (fabrikant)",
    "car_parking_assistance": "Parkeerhulp",
    "car_interior_material": "Materiaal van de binneninrichting",
    "car_airbags": "Airbags",
    "car_air_conditioning": "Airconditioning",
    "car_environment_badge": "Milieu badge",
    "car_co2_emissions": "CO2-emissies",
    "car_co2_emissions_unit": "g/km",
    "car_fuel_consumption": "Verbruik",
    "car_fuel_consumption_unit": "l/100 km",
    "combined": "gecombineerd",
    "combined_short": "kam.",
    "urban": "stedelijk",
    "extra_urban": "buiten de stad",
    "energy_efficiency_class": "Energie-efficiëntieklasse",
    "technical_data": "Technische gegevens",
    "first_registration": "Eerste registratie",
    "proceed_to_checkout": "Kassa",
    "home": "Startpagina",
    "checkout": "Kassa",
    "your_cart": "Je winkelwagen",
    "your_car": "Uw auto",
    "your_data": "Uw gegevens",
    "services": "Service",
    "shipping_information": "Overdracht",
    "payment_details": "Betaling",
    "summary": "Samenvatting",
    "find_out_more": "Meer informatie",
    "qty": "Hoeveelheid",
    "checkout_next": "Verder",
    "checkout_previous": "Terug",
    "please_select": "Selecteer",
    "enum_many_glue": ",",
    "enum_last_glue": " en",
    "enum_end": " selecteer",
    "show_details": "Details tonen",
    "add_to_cart": "Toevoegen aan winkelmandje",
    "show_cart": "Winkelmandje tonen",
    "cart": "Winkelmandje",
    "missingQuantity": {
      "cart": "winkelmand"
    },
    "cart_is_empty": "Winkelmandje is leeg",
    "item_already_in_cart": "Dit artikel zit al in je winkelmandje",
    "remove": "Verwijder",
    "removed_from_cart": "is verwijderd uit het winkelmandje",
    "continue_with_login": "Doorgaan met inloggen",
    "dont_have_an_account": "Nog geen account",
    "continue_without_login": "Doorgaan zonder inloggen",
    "already_have_an_account_enter_username_and_password": "Heb je al een account? Vul je gebruikersnaam en wachtwoord in op het onderstaande formulier.",
    "continue_without_login_and_register": "Je kunt het aankoopproces voortzetten en hier je account aanmaken.",
    "continue_booking_without_login_and_register": "U kunt het boekingsproces voortzetten en hier uw account aanmaken",
    "online_discount": "Online korting",
    "your_personal_data": "Uw persoonlijke gegevens",
    "login_data": "Toegangsgegevens",
    "shipping": "Verzending",
    "payment_cash": "Contant",
    "payment_creditCard": "Creditcard",
    "payment_payPal": "PayPal",
    "payment_wire_transfer": "Overschrijving",
    "payment_bill": "Factuur",
    "choose_different_payment_method": "Selecteer een andere betaalmethode",
    "i_will_pick_up_my_car": "Ik haal mijn auto op",
    "i_will_pick_up_my_order": "Ik haal mijn bestelling op",
    "i_want_my_car_delivered": "Ik wil graag dat mijn voertuig wordt afgeleverd.",
    "i_want_my_order_delivered": "Ik wil dat mijn bestelling wordt geleverd",
    "we_could_not_calculate_route_to_address": "We konden geen route berekenen naar het opgegeven adres. Controleer en probeer het opnieuw.",
    "credit_card_holder": "Kredietkaarthouder",
    "credit_card_number": "Creditcardnummer",
    "car_registration": "Voertuigregistratie",
    "car_insurance": "Autoverzekering",
    "service_provided_in_country_by_company": "Dienst in {land} geleverd door {bedrijf}",
    "i_wish_to_have_car_registered": "Ja, ik wil de autorisatiedienst gebruiken",
    "i_wish_to_get_car_insurance": "Ik wil graag een autoverzekering afsluiten",
    "today": "Vandaag",
    "in_10_days": "Over 10 dagen",
    "your_money_is_safe_with_us": "Uw geld is veilig bij ons",
    "car_transport": "Auto transport",
    "delivery": "levering",
    "decide_car_delivery_or_pickup": "Het is aan jou om te beslissen of je de auto zelf wilt ophalen of wilt laten bezorgen. Hoe dan ook, de auto zal in perfecte staat zijn, schoon en met een volle tank benzine.",
    "secured_payment_until_car_is_delivered": "Veilig betalingsproces tot levering van het voertuig",
    "e_payment_to_reserve_car_and_secure_services": "E-betaling om de auto te reserveren en de geselecteerde services veilig te stellen",
    "e_payment_credit_card_paypal": "Elektronische betaling (creditcard of PayPal)",
    "multiple_secure_options_to_pay_for_the_car": "Veilige betalingsopties voor de auto (bankoverschrijving, contant bij afhalen)",
    "buy_car_by_clicking_button": "Je kunt deze auto nu kopen door op onderstaande knop te klikken",
    "payment_with_credit_card_ending_last4_expiring_exp_month_exp_year_issued_to_card_holder": "Creditcardbetaling eindigend op {last4} en aflopend in {expMonth}/{expYear}, uitgegeven aan {cardHolder}",
    "click_on_paypal_checkout_to_continue": "Klik op de knop Paypal Checkout om verder te gaan.",
    "paypal_is_approved_continue_to_summary": "Uw PayPal-betaling is geautoriseerd. Ga verder met het overzicht om de bestelling te voltooien en de betaling te autoriseren.",
    "paypal_buttons_loading": "PayPal-knoppen laden...",
    "please_wait_saving_order": "Sla de bestelling op. Even geduld alstublieft...",
    "error_loading_paypal_scripts": "PayPal-scripts konden niet worden geladen. Sluit deze popup en open hem opnieuw.",
    "link": "Link",
    "hide_on_devices": "Verbergen op apparaten",
    "mobile_phones": "Mobiele telefoons",
    "tablets": "Tabletten",
    "desktop_pcs": "Desktop pc's",
    "show_content_on_mouse_over": "Inhoud tonen wanneer de muis erover gaat",
    "menu_content": "Inhoud menu",
    "link_to_page": "Link naar de pagina",
    "page_has_no_translations": "Deze pagina heeft geen vertalingen",
    "search_results": "Zoekresultaten",
    "press_enter_to_search": "Druk op de enter-toets om te zoeken",
    "no_results_found": "Geen resultaten gevonden",
    "create_new_entry_now": "U kunt nu een nieuwe invoer aanmaken:",
    "no_results_for_your_search": "We hebben geen resultaten gevonden voor je zoekopdracht",
    "change_image": "Afbeelding wijzigen",
    "employee_data": "Personeelsgegevens",
    "newsletter": "Toestemming om nieuwsbrieven te ontvangen",
    "editorAutosave": "Automatisch opslaan in de editor",
    "is_profile_public": "Is het profiel openbaar?",
    "parent_page": "Ouderpagina",
    "cms_elements": "CMS elementen",
    "cms_element": "CMS element",
    "new_lead_from_website": "Nieuwe lead van de website",
    "website_settings": "Website-instellingen",
    "profile": "Profiel",
    "my_bills": "Mijn facturen",
    "integrations": "Integraties",
    "email_templates": "E-mail sjablonen",
    "cms_templates": "CMS sjablonen",
    "cms_template": "CMS sjabloon",
    "select_brand": "Selecteer merk",
    "all_brands_visible": "Alle merken zijn zichtbaar",
    "contact_us": "Neem contact met ons op",
    "thank_you": "Hartelijk dank",
    "thank_you_for_contacting_us": "Bedankt dat u contact met ons opneemt. We hebben uw vraag ontvangen en nemen zo spoedig mogelijk contact met u op.",
    "cms_standard": "Standaard",
    "select_element": "Selecteer CMS element",
    "divider": "Verdeler",
    "testimonial": "Aanbeveling",
    "hero_unit": "Heldenelement",
    "image_carousel": "Schuifregelaar",
    "call_to_action": "Oproep tot actie",
    "bar_counter": "Balie",
    "products": "Producten",
    "producttemplates": "Sjablonen",
    "single_product": "Enkel product",
    "single_producttemplate": "Enkel sjabloon",
    "lead_form": "Loodvorm",
    "image_carousel_element": "Afbeelding schuifbalk",
    "cms_grid": "Container",
    "cms_row": "Lijn",
    "cms_col": "Kolom",
    "text_color": "Kleur tekst",
    "bar_counter_now": "Nu",
    "shadow": "Schaduw",
    "no_shadow": "Geen schaduw",
    "small": "Klein",
    "big": "Groot",
    "rounded_corners": "Afgeronde hoeken",
    "no_rounded_corners": "Geen afgeronde hoeken",
    "image_position": "Positie afbeelding",
    "icon": "Pictogram",
    "icon_class": "Pictogramklasse (CSS)",
    "position_top": "Boven",
    "position_left": "Links",
    "bar_color": "Kleur bar",
    "bar_background_color": "Achtergrondkleur balk",
    "button_icon": "Knopsymbool",
    "button_text": "Knoptekst",
    "full_height": "Volledige hoogte",
    "padding_top": "Afstand boven",
    "padding_bottom": "Afstand onder",
    "lead_source": "Bron",
    "change_item": "Artikel wijzigen",
    "show_infos_on_top": "Toon info hierboven",
    "show_infos_on_bottom": "Toon info hieronder",
    "condensed": "Gecondenseerd",
    "assign_item": "Product toewijzen",
    "image_animation": "Beeldanimatie",
    "no_animation": "Geen animatie",
    "fade_in": "Fade in",
    "from_left": "Vanaf links",
    "from_right": "Van rechts",
    "from_top": "Van bovenaf",
    "from_bottom": "Van onderen",
    "image_gallery": "Afbeeldingen galerij",
    "elements": "Elementen",
    "accordion": "Accordeon",
    "map": "Kaart",
    "height": "Hoogte",
    "video": "Video",
    "link_to_video": "Link naar de video",
    "video_possible_sources": "Video's van de volgende portals worden ondersteund:: YouTube, Vimeo, Dailymotion",
    "autoplay": "Automatisch afspelen",
    "aspect_ratio": "Beeldverhouding",
    "image_compare": "Vergelijk afbeelding",
    "second_image": "Tweede foto",
    "login_form": "Inlogformulier",
    "button_signin": "Inloggen",
    "alternative_login": "...of log in met:",
    "alternative_login_long": "Gebruik onze sociale aanmeldingsfunctie",
    "login_with": "Log in met",
    "show_facebook_login": "Facebook-login weergeven",
    "show_google_login": "Google-login weergeven",
    "signup_form": "Registratieformulier",
    "signup_legal_explanation": "Registratie - wettelijke verklaring",
    "signup_agreement_text": "Door te registreren ga ik akkoord met de {voorwaarden}.",
    "contact_agreement_text": "Ik heb de {data_use} gelezen en begrepen.",
    "signup_agreement_text_terms": "Algemene voorwaarden",
    "signup_agreement_text_data_use": "Privacybeleid",
    "signup_agreement_text_cancellation_policy_and_form": "Annuleringsvoorwaarden en het annuleringsformulier",
    "will_become_link_with_title_title": "een link met de titel: {title}",
    "show_only_when_user_logged_id": "Wordt alleen weergegeven als de gebruiker is ingelogd",
    "car_rental_form": "Autoverhuur formulier",
    "car_search_form": "Auto zoekformulier",
    "car_search_funnel": "Auto zoeken configurator",
    "background_image": "Achtergrondafbeelding",
    "background_color": "Achtergrondkleur",
    "same_col_height": "Dezelfde kolomhoogte",
    "pick_up_and_drop_off": "Ophalen & retourneren",
    "pick_up_time": "Pick-up tijd",
    "drop_off_time": "Terugkeerperiode",
    "get_a_quote": "Prijzen bepalen",
    "select_location": "Selecteer een locatie",
    "type_address_to_start_search": "Voer een adres in om te beginnen met zoeken",
    "menu_version": "Menu-versie",
    "menu_transparent": "Transparant",
    "menu_dark": "Donker",
    "menu_light": "Licht",
    "logo_version": "Versie logo",
    "logo_default": "Standaardlogo gebruiken",
    "logo_scroll": "Gebruik scroll-logo",
    "select_a_pick_up_location": "Selecteer eerst een ophaallocatie",
    "could_not_find_location_please_enter_more_details": "We konden geen locatie vinden. Voer uw afhaallocatie met meer informatie in",
    "client_not_found": "Klant niet gevonden",
    "order_will_directly_be_marked_paid": "De bestelling wordt onmiddellijk gemarkeerd als betaald",
    "invoice_will_directly_be_marked_paid": "De factuur wordt onmiddellijk gemarkeerd als betaald",
    "rental_period": "Huurperiode",
    "change_booking_dates": "Boekingsgegevens wijzigen",
    "per_day": "per dag",
    "buy_now": "Nu kopen",
    "book_now": "Boek nu",
    "rent_car_now": "Nu huren",
    "commit_to_buy": "Opdracht met betalingsverplichting",
    "back_to_cart": "Terug naar het winkelmandje",
    "equipment": "Uitrusting",
    "technical_specifications": "Technische gegevens",
    "cancel_checkout_question": "Afrekenen annuleren?",
    "how_it_works": "Hoe het werkt",
    "secure_payment": "Beveiligde betaling",
    "top_services": "Top diensten",
    "if_you_buy_this_car_you_will_get_lots_of_services": "Als je deze auto koopt, wees dan niet bang voor papierwerk. We kunnen de registratie, verzekering en autolening regelen als je dat wilt. Selecteer gewoon de service die je nodig hebt bij het afrekenen.",
    "need_more_info_question": "Heb je meer informatie nodig?",
    "contact_dealer": "Contact dealer",
    "move_around_to_see_changes": "Verplaats om de veranderingen te zien",
    "lead_catchers": "Leiders",
    "lead_catcher": "Hoofd vanger",
    "advanced_routing": "Geavanceerde routing",
    "add_route": "Route toevoegen",
    "source": "Bron",
    "can_be_ordered_online": "Kan online worden besteld",
    "count_years_warranty": "{telling} {telling, meervoud, een {jaar} andere {jaren}} Garantie",
    "amount_online_advantage": "{bedrag} Online voordeel",
    "your_order": "Uw bestelling",
    "file_name": "Bestandsnaam",
    "tags": "Tags",
    "save_and_set_image": "Opslaan en instellen",
    "choose_or_drop_file_to_upload": "Selecteer een bestand of sleep het naar de uploadpagina.",
    "media_formats_supported": "JPG en PNG worden ondersteund.",
    "max_file_upload_size": "De maximale bestandsgrootte is 15 MB",
    "no_files_found": "Geen bestanden gevonden.",
    "upload_file_by_dragging_over": "Upload een bestand door het gewoon over de pagina te slepen",
    "your_car_is_ready_to_check_out": "Je droomauto staat voor je klaar",
    "choosing_one_of_our_cars_is_a_good_decision": "Nog maar een paar stappen naar uw droomauto. Profiteer van onze online voordelen.",
    "your_car_will_be_delivered_to_you": "Je voertuig wordt afgeleverd op de door jou gewenste locatie",
    "your_will_get_a_amount_discount_for_ordering_online": "Voor online bestellingen ontvang je een korting van {bedrag}",
    "you_will_receive_extended_warranty": "Je krijgt een uitgebreide garantie",
    "you_will_get_exclusive_offers_when_using_our_app": "Je ontvangt exclusieve aanbiedingen als je onze mobiele app gebruikt",
    "you_dont_risk_when_ordering_online": "Je loopt niet veel risico als je online bestelt.",
    "your_car_rent_is_nearly_done": "Je huurauto is bijna klaar!",
    "please_continue_with_booking_and_payment": "Ga verder met boeken en betalen. Wij zorgen ervoor dat je auto klaarstaat op de geselecteerde ophaaldatum. Online boeken bespaart je tijd!",
    "please_provide_address_delivery_method_payment": "Voer je adres in, selecteer de verzendmethode en de betalingsgegevens.",
    "your_information_is_safe_with_us": "Uw informatie is veilig bij ons.",
    "we_offer_parcel_shipment_for_just_amount": "Voor je gemak bieden we één pakketlevering voor al onze bestellingen voor slechts {bedrag}.",
    "free_delivery_on_all_our_orders": "We bieden gratis bezorging op al onze bestellingen.",
    "get_your_car_delivered": "levering",
    "cant_pick_your_car_yourself_we_got_you_covered": "Wat gebeurt er als je je droomauto vindt en hem niet zelf kunt ophalen? Geen zorgen. Wij hebben een oplossing. Zodra je auto geregistreerd en verzekerd is, zal een chauffeur een afleverafspraak met je maken en je auto op je oprit parkeren.",
    "we_are_more_than_happy_to_show_you_around_your_new_car": "Natuurlijk kijken we ernaar uit om je persoonlijk te verwelkomen en je nieuwe auto te laten zien.",
    "we_can_complete_your_order_in_shop_for_pickup": "Maar als je wilt, kunnen we je bestelling in onze winkel afronden en kun je hem ophalen wanneer je tijd hebt.",
    "we_will_inform_you_when_we_complete_order_for_pickup": "We laten je weten wanneer je bestelling klaar is. Daarna wachten we geduldig tot je het komt ophalen.",
    "we_will_ship_your_order_to_provided_address": "We verzenden je bestelling met DHL naar het door jou opgegeven adres. We laten je weten wanneer de bestelling is verzonden. Je ontvangt ook een volgnummer.",
    "we_calculated_route_from_car_to_you": "We hebben een route berekend van waar de auto zich bevindt naar het adres dat je hebt opgegeven. Het lijkt erop dat onze chauffeur {afstand}km moet rijden om de auto tot aan je voordeur te brengen.",
    "service_includes": "De service omvat:",
    "driver_will_call_you_and_schedule_delivery": "Onze chauffeur zal je bellen en de auto afleveren wanneer het jou het beste uitkomt",
    "your_car_will_be_washed": "Je auto wordt gewassen",
    "your_car_will_be_fully_tanked": "Je auto is volgetankt met benzine",
    "you_will_get_quick_tour_around_car": "Je krijgt een korte rondleiding door de auto",
    "you_can_pick_up_car_yourself": "Heb je vragen? Ons team helpt je graag verder.",
    "only_one_step_left": "Nog één stap!",
    "check_if_everything_is_correct_and_provide_payment_data": "Controleer of alles correct is, geef ons je betalingsgegevens en verzend de bestelling.",
    "terms_of_contract_for_additional_services": "Contractuele voorwaarden voor aanvullende diensten",
    "terms_of_use_marketplace": "Gebruiksvoorwaarden van de marktplaats",
    "for_the_sales_of_used_vehicles": "Verkoop van gebruikte voertuigen",
    "for_the_rental_of_vehicles": "Voertuig huren",
    "your_payment_schedule": "Betaling",
    "shipping_pickup": "Collectie",
    "shipping_parcel": "Pakket",
    "shipping_transport": "Transport",
    "car_interior": "Binneninrichting",
    "car_exterior": "Buitenfittingen",
    "we_are_processing_your_order_please_wait": "We zijn uw bestelling aan het verwerken. Even geduld alstublieft...",
    "we_are_processing_your_booking_please_wait": "We zijn uw reservering aan het verwerken. Even geduld alstublieft...",
    "your_order_was_completed_successfully": "Je bestelling is succesvol afgerond.",
    "we_created_a_user_profile": "We hebben een gebruikersprofiel voor je aangemaakt",
    "check_your_email": "Controleer je inbox",
    "your_orders": "{telling, meervoud, een {uw ordernummer} andere {uw ordernummers}}:",
    "first_experience_with_our_shop_saved_profile": "Het lijkt erop dat dit je eerste ervaring is met onze online shop. We hebben je profielgegevens met succes opgeslagen in onze database. Om door te gaan met het verwerken van je bestelling, controleer je je mailbox en bevestig je je e-mailadres.",
    "order_details_sent_to_email_check_history_for_details": "Alle bestelgegevens zijn naar uw e-mailadres verzonden. U kunt uw bestelgeschiedenis en bestelstatus op elk gewenst moment bekijken in uw gebruikersaccount.",
    "timeline": "Tijdlijn",
    "promotions": "Acties",
    "payment_method": "Betaalmethode",
    "first_payment": "1. betaling",
    "second_payment": "2. betaling",
    "shipping_method": "Verzendmethode",
    "payment_1_e_payment_to_secure_your_order": "Aanbetaling via elektronische betaling om je voertuig te reserveren.",
    "payment_2_choose_payment_method_for_the_car": "Selecteer een betaalmethode voor je voertuig.",
    "payment_escrow": "Borgrekening",
    "payment_car_credit": "Financiering",
    "full_refund": "Volledige terugbetaling",
    "partial_refund": "Gedeeltelijke terugbetaling",
    "cancel_order": "Bestelling annuleren",
    "create_shipping": "Verzending maken",
    "schedule_car_registration": "Autorisatie",
    "schedule_transport": "Plan transport",
    "is_published": "Wordt gepubliceerd",
    "no_salesman_available": "Er is momenteel geen verkoper beschikbaar",
    "any_questions": "Vragen?",
    "feel_free_to_ask_our_online_sales_team": "Neem gewoon contact op met ons online verkoopteam",
    "please_complete_your_details": "Vul uw gegevens in",
    "registration_is_stressfull_get_your_car_registered": "Voor sommige mensen is het registreren van een auto stressvol en tijdrovend. Laat je auto registreren op jouw naam zonder gedoe.",
    "review_your_order": "Controleer je bestelling",
    "in_order_to_confirm_identity_please_complete_your_details": "Om je identiteit te bevestigen, hebben we aanvullende informatie nodig:",
    "payment_instructions_will_be_provided_after_seller_accepts_order": "De verkoper geeft je gedetailleerde betalingsinstructies nadat je bestelling is geaccepteerd.",
    "nationality": "Nationaliteit",
    "date_of_birth": "Geboortedatum",
    "place_of_birth": "Geboorteplaats",
    "discover_more": "Meer ontdekken",
    "more_of_product": "Meer {product}",
    "more_of_product_in_city": "Meer {product} in {stad}",
    "order_placed": "Bestelling geplaatst",
    "show_order_details": "Bestelgegevens weergeven",
    "white_label_plugins": "White label plugins",
    "white_label_plugin": "White label plugin",
    "enhance_chedri_project_with_plugins": "Creacheck-ervaring verbeteren met plugins",
    "plugin": "Plugin",
    "plugins": "Plugins",
    "enhance_your_project_with_plugins": "Je kunt zelf kiezen hoeveel Creacheck-functionaliteit je nodig hebt. Bekijk de beschikbare plugins, hun functies en prijzen.",
    "week": "week",
    "month": "maand",
    "quarter": "Kwart",
    "year": "Jaar",
    "more_infos": "Meer informatie",
    "less_infos": "Minder informatie",
    "activate_plugin": "Plugin activeren",
    "partially_activate_plugin": "Plugin activeren",
    "terminate_plugin": "Plugin afsluiten",
    "detailed_description": "Gedetailleerde beschrijving",
    "installation_instructions": "Installatie-instructies",
    "you_are_not_an_adminitrator": "Je bent geen beheerder",
    "creditor_id": "Crediteur ID",
    "i_understand_the_cost_and_want_activate_plugin": "Ik begrijp de kosten en wil graag de plugin activeren",
    "i_understand_the_running_cost_when_terminating_plugin": "Ik begrijp de kosten die in rekening worden gebracht aan het einde van de factureringsperiode",
    "are_you_sure_you_want_to_terminate_plugin": "Wil je de plugin echt stoppen?",
    "security_settings": "Beveiligingsinstellingen",
    "limit_write_rights": "Schrijfrechten beperken",
    "limit_read_rights": "Leesrechten beperken",
    "edit_your_company_data": "Hier kunt u uw bedrijfsgegevens bewerken. De contactgegevens en financiële gegevens voor facturering wijzigen",
    "latest_order": "Laatste bestelling",
    "latest_orders": "Laatste bestellingen",
    "latest_invoices": "Laatste facturen",
    "latest_editions": "Laatst bewerkt",
    "overdue_invoices": "Verschuldigde facturen",
    "view_all": "Alles weergeven",
    "i_accept": "Ik accepteer",
    "accept_terms_before_continuing": "Voordat u doorgaat, moet u onze algemene voorwaarden en privacybeleid accepteren.",
    "approve_car_sale": "Autorisatie verkoop auto",
    "enter_vin_number": "Voer het chassisnummer van het voertuig in",
    "mark_as_paid": "Markeer als betaald",
    "enter_ein_number": "Elektronische verzekeringsbevestiging invoeren",
    "send_payment_instructions": "Stuur betalingsinstructies",
    "submit_vin_number": "Stuur het chassisnummer van het voertuig",
    "approve_and_inform_client": "De klant autoriseren en informeren",
    "submit_ein_number": "Elektronische verzekeringsbevestiging verzenden",
    "ein": "Elektronische verzekeringsbevestiging",
    "payment_reference": "Betalingskenmerk",
    "upload_sales_cotract": "Verkoopcontract uploaden",
    "cancellation_reason": "Reden voor annulering",
    "enter_bank_data": "Bankgegevens invoeren",
    "pickup_invite": "Uitnodiging om op te halen",
    "mark_picked_up": "Markeren als verzameld",
    "refunds": "Terugbetalingen",
    "sent_on_date": "Verzonden op {datum}",
    "not_sent_yet": "Nog niet verzonden",
    "pick_up_location": "Ophaallocatie",
    "emails": "E-mails",
    "use_following_list_to_personalize_your_emails": "Gebruik de volgende lijst om e-mails te personaliseren:",
    "recipients_first_name": "Voornaam van de ontvanger",
    "recipients_last_name": "Achternaam van de ontvanger",
    "senders_company_name": "Bedrijfsnaam van de afzender",
    "system_mail_registration_description": "Verzonden wanneer een nieuwe gebruiker zich registreert op de website",
    "system_mail_invitation_description": "Verzonden wanneer een werknemer een nieuwe gebruiker aanmaakt met een e-mailadres",
    "system_mail_car_rental_pickup_reminder_description": "Verzonden één dag voordat de klant zijn huurauto ophaalt",
    "system_mail_car_rental_dropoff_reminder_description": "Verzonden één dag voordat de klant zijn huurauto inlevert",
    "terms_of_use": "Gebruiksvoorwaarden",
    "contract": "Contract",
    "order_data_processing": "Contract voor orderverwerking",
    "i_read_and_accept_plugins_terms_of_use": "Ik heb de gebruiksvoorwaarden gelezen en begrepen en accepteer deze uitdrukkelijk.",
    "i_read_and_accept_plugins_contract": "Ik heb het contract gelezen en begrepen en accepteer het uitdrukkelijk.",
    "i_read_and_accept_plugins_order_data_processing": "Ik heb de gegevensverwerking van de bestelling gelezen en begrepen en accepteer deze uitdrukkelijk.",
    "send_activation_request": "Activeringsverzoek verzenden",
    "missing_companys_bank_data": "Het lijkt erop dat we de bankgegevens van uw bedrijf missen.",
    "you_can_fill_missing_data_in_settings": "Je kunt ontbrekende gegevens invoeren in {settings}.",
    "not_sole_representative_click_below_to_request_plugin_activation": "Je bent niet gemachtigd om zelf plugins te activeren. Klik op de knop om de plugin te activeren. De geautoriseerde gebruiker wordt gevraagd om het activeringsproces te voltooien.",
    "not_authorized_click_below_to_request_plugin_activation": "Je bent niet gemachtigd om plugins te activeren. Klik op de knop om een activeringsverzoek naar bevoegde gebruikers te sturen.",
    "select": "Selecteer",
    "enter_app_secret": "Voer app-geheim in",
    "enter_page_access_token": "Toegangstoken voor pagina invoeren",
    "legal": "Wettelijk",
    "activation_pending": "In afwachting van activering",
    "termination_pending": "Annulering in behandeling",
    "form_contains_errors": "Er zijn enkele problemen in de vorm",
    "internal_car_reference": "Interne referentie auto",
    "system_debtor_number": "Debiteurnummer systeem",
    "internal_company_reference": "Interne bedrijfsreferentie",
    "internal_location_reference": "Interne locatieverwijzing",
    "show_notes": "Opmerkingen",
    "show_my_notes": "Mijn notities tonen",
    "show_by_me": "Laat van mij zien",
    "items": "Producten",
    "send_text_message": "SMS verzenden",
    "wrote_message": "Bericht geschreven",
    "sent_text_message": "Eén verstuurde sms",
    "wrote_note": "Opmerking geschreven",
    "please_check_document_check_expiration_date": "Controleer het document. Controleer of u het document hebt gezien. Controleer de vervaldatum",
    "i_reviewed_the_document": "Ik heb het document gecontroleerd",
    "receive_updates_in_messenger": "Wil je updates ontvangen in Messenger?",
    "weve_received_your_request_and_will_contact_you": "We hebben uw aanvraag ontvangen en nemen zo snel mogelijk contact met u op.",
    "check_your_email_to_confirm": "Controleer uw e-mail om de verzending van dit contactformulier te bevestigen.",
    "response_text": "Antwoord tekst",
    "entries": "Inzendingen",
    "timeline_block": "Tijdlijn blok",
    "external_accounts": "Externe rekeningen",
    "send_copy_to_me": "Stuur een kopie naar mij",
    "user_tracking": "Volgen van gebruikers",
    "select_model": "Model kiezen",
    "select_model_group": "Selecteer modelgroep",
    "equipment_level_budget": "Budget",
    "equipment_level_family": "Familie",
    "equipment_level_comfort": "Comfort",
    "equipment_level_business": "Bedrijf",
    "salutation": "Begroeting",
    "getting_started": "Eerste stappen",
    "set_your_locations": "Definieer uw locaties",
    "set_up_locations_for_your_car_business_set_services_opening_hours_upload_photos": "Elk voertuig heeft een locatie nodig waaruit klanten kunnen kiezen. Ga naar de instellingen waar je alle gewenste locaties kunt definiëren. Daar kun je bepalen welke diensten je op elke locatie aanbiedt, openingstijden instellen en foto's uploaden zodat klanten je bedrijf gemakkelijker kunnen vinden.",
    "now_that_locations_are_set_create_users_so_they_can_work_together": "Nu uw klanten weten waar u zaken doet, laat ons weten wie er voor u werkt. Maak gebruikers aan zodat ze kunnen samenwerken met Creacheck-technologieën",
    "cms_content": "CMS inhoud",
    "this_is_blank_page_use_editor_to_create_great_one": "Dit is een lege pagina. In onze visuele editor kun je een prachtige pagina maken met afbeeldingen, mooie typografie en compatibel met alle apparaten.",
    "cars_to_sell": "Voertuigen te koop",
    "your_business_is_car_sales_but_there_are_no_cars_create_yourself_or_use_import": "Het is uw taak om voertuigen te verkopen. Wij willen het u gemakkelijker maken. Je hebt nog geen voertuigen ingevoerd in ons systeem. Gebruik gewoon de handige voertuigimportfunctie of voer je voertuigen handmatig in.",
    "create_manually": "Handmatig aanmaken",
    "import": "Importeren",
    "car_import": "Voertuigen importeren",
    "most_car_dealers_use_mobilede_enter_your_interface_username_and_password_to_import": "Zoals we hebben ontdekt, gebruiken de meeste autodealers mobile.de om hun auto's te presenteren. Je hebt dus hoogstwaarschijnlijk toegang tot de webservice. Voer je gebruikersnaam en wachtwoord in. We zullen al je auto's automatisch importeren en je advertentie gesynchroniseerd houden. Als je meerdere inloggegevens hebt, kun je er meer invoeren in je profiel.",
    "we_will_import_count_cars_for_this_username": "We importeren {count} auto's voor deze gebruikersnaam",
    "mobile_de_username": "Mobile.de Verkoper API-gebruikersnaam",
    "mobile_de_password": "Mobile.de Verkoper API Wachtwoord",
    "missing_bank_data": "Ontbrekende bankgegevens",
    "we_dont_have_your_bank_data": "Blijkbaar hebben we je bankgegevens niet",
    "monthly_billing_relies_on_sepa_direct_debit_fill_in_bank_data_in_settings": "Onze maandelijkse facturering is gebaseerd op SEPA-incasso. Omdat we geen bankgegevens hebben, kun je veel van onze plugins niet gebruiken. Ga naar de instellingen en voer je bankgegevens in.",
    "fill_in_bank_data": "Voer de bankgegevens in",
    "sepa_mandate": "SEPA-mandaat",
    "sepa_mandates": "SEPA-mandaten",
    "missing_sepa_mandate": "We missen een SEPA-mandaat",
    "changed_bank_data_please_generate_new_sepa_mandate": "Het lijkt erop dat u onlangs uw bankrekeningnummer hebt gewijzigd. Om ons maandelijkse factureringsproces te automatiseren en u minder stress te bezorgen, vragen we u een nieuw SEPA-mandaat aan te maken.",
    "generate_sepa_mandate_now": "Een nieuw SEPA-mandaat genereren",
    "columns": "Kolommen",
    "send_doc_as_message": "Document naar de klant sturen (e-mail)",
    "alternative_recepient": "Alternatieve ontvanger",
    "enter_email_address": "Voer een geldig e-mailadres in",
    "job_title": "Functie",
    "service_point": "Service",
    "rental_point": "Huurbasis",
    "seller_data": "Gegevens verkoper",
    "car_location": "Locatie voertuig",
    "save_data_before_nav_to_pathname": "Je gegevens worden niet opgeslagen. Wil je overschakelen naar {padnaam} zonder op te slaan?",
    "share": "Deel",
    "assignee_changed_from_assignee_to_assignee": "De begunstigde is gewijzigd van %oudGebruiker% in %nieuwGebruiker%.",
    "stage_changed_from_name_to_name": "Het niveau is gewijzigd van %oldName% in %newName%.",
    "handover_filled_out": "Het protocol voor voertuigoverdracht is voltooid",
    "data_changed": "Bijgewerkte gegevens",
    "key": "toets",
    "old_value": "Oude waarde",
    "new_value": "Nieuwe waarde",
    "no_details_available": "Gedetailleerde informatie is niet beschikbaar",
    "services_we_offer_in_city": "Diensten die we voor je aanbieden in {stad}",
    "damages_present": "Schade aanwezig",
    "user_is_online": "Gebruiker is online",
    "user_is_offline": "Gebruiker is offline",
    "click_to_start_chat": "Klik hier om de chat te starten",
    "click_to_view_chat_history": "Klik hier om de chatgeschiedenis te bekijken",
    "theme_colors": "Themakleuren",
    "logos": "Logo's",
    "send_invitation_with_username_and_password": "Uitnodiging versturen met gebruikersnaam en wachtwoord (alleen voor nieuwe gebruikers)",
    "cookie_settings": "Cookiegegevens",
    "accept_only_necessary_cookies": "Accepteer alleen noodzakelijke cookies",
    "accept_all_cookies": "Alle cookies accepteren",
    "privacy_preference_center": "Voorkeurscentrum gegevensbescherming",
    "your_privacy": "Uw privacy",
    "basic_information_on_cookies": "Wanneer u een website bezoekt, kan er informatie worden opgeslagen of opgehaald in uw browser, meestal in de vorm van cookies. Deze informatie kan betrekking hebben op u, uw voorkeuren of uw apparaat. Deze informatie wordt voornamelijk gebruikt om de website naar verwachting te laten werken. De informatie identificeert u meestal niet direct, maar kan zorgen voor een meer gepersonaliseerde webervaring. Omdat we uw recht op privacy respecteren, kunt u ervoor kiezen bepaalde soorten cookies niet toe te staan. Selecteer een categorie voor meer informatie en om onze standaardinstellingen te wijzigen. Het blokkeren van sommige soorten cookies kan echter invloed hebben op uw ervaring van de website en de diensten die wij u kunnen bieden.",
    "necessary_cookies": "Noodzakelijke cookies",
    "performance_cookies": "Prestatiecookies",
    "targeting_cookies": "Gerichte cookies",
    "always_active": "Altijd actief",
    "cookies_used": "Gebruikte cookies",
    "necessary_cookies_information": "Deze cookies zijn noodzakelijk voor het functioneren van de website en kunnen niet worden uitgeschakeld in onze systemen. Ze worden meestal alleen ingesteld als reactie op acties die u onderneemt in verband met een serviceverzoek, zoals het instellen van uw privacyvoorkeuren, inloggen of het invullen van formulieren. U kunt uw browser zo instellen dat deze cookies worden geblokkeerd of dat u wordt gewaarschuwd voor deze cookies, maar sommige delen van de website zullen dan niet werken. Deze cookies slaan geen persoonlijk identificeerbare informatie op.",
    "save_settings": "Instellingen opslaan",
    "save_and_continue": "Opslaan en doorgaan",
    "priority": "Prioriteit",
    "closed_leads": "Gesloten kabels",
    "show": "Weergave",
    "hide": "Verstoppertje",
    "first_registration_from": "Eerste registratie van",
    "first_registration_to": "Eerste registratie tot",
    "power_from": "Motorvermogen van",
    "power_to": "Motorvermogen tot",
    "mileage_from": "Kilometers van",
    "mileage_to": "Kilometerstand tot",
    "included_mileage": "{kilometer",
    "really_cancel": "Echt annuleren?",
    "unsaved_information_will_be_lost": "Niet-opgeslagen gegevens gaan verloren",
    "client_signature": "Handtekening van de klant",
    "another_photo": "Nog een foto",
    "try_again": "Probeer het opnieuw",
    "back_to_vehicle": "Terug naar het voertuig",
    "image_upload_finished": "Het uploaden van de afbeeldingen is voltooid",
    "you_have_no_internet_connection": "Je hebt geen internetverbinding",
    "waiting_to_reconnect": "Wachten op herverbinding",
    "plus_count_more": "+ {telling} meer",
    "document_is_missing": "Eén document ontbreekt",
    "upload_document_name_now": "Upload document '{naam}' nu",
    "lead_per_mail": "Leid via e-mail",
    "create_rules_to_forward_emails": "Maak regels om e-mails als kopie door te sturen naar andere gebruikers. Als de 'Data e-mail plugin' is geactiveerd, kun je ook e-mails met belangrijke informatie in XML-formaat rechtstreeks doorsturen naar Customer One.",
    "lead_per_mail_rules": "Leid door e-mail regels",
    "include_xml_data_in_email": "Gegevens in XML-formaat invoegen in de e-mail",
    "sad_activity": "SAD-activiteit",
    "e_payments": "E-betalingen",
    "stripe_connect": "Stripe aansluiten",
    "we_will_handle_epayments_for_you": "Met Stripe Connect kunnen we creditcardbetalingen voor je verwerken. Nadat je op \"Connect to Stripe\" hebt geklikt, kun je je bestaande Stripe account gebruiken om verbinding te maken met het Creacheck platform of een nieuwe Stripe account aanmaken als je die nog niet hebt. Met je eigen Stripe account kun je je hele betalingsgeschiedenis inzien en op elk moment geld opnemen.",
    "connect_with_stripe": "Maak verbinding met Stripe",
    "stripe_accounts": "Stripe-accounts",
    "stripe_account_connected": "Je Stripe-account is nu verbonden",
    "stripe_accounts_are_connected": "Uw Stripe-account is nu verbonden met {projectnaam}. {projectnaam} kan nu creditcardbetalingen namens u accepteren.",
    "money_won": "Gewonnen geld",
    "leads_won": "Verkregen leads",
    "offered_by": "Je autodealer",
    "pay_with_saved_credit_card": "Betaal met je opgeslagen creditcard",
    "pay_with_new_credit_card": "Betaal met een nieuwe creditcard",
    "settings": "Instellingen",
    "account": "Functies",
    "clients": "Bedrijven",
    "payments": "Betalingen",
    "calendar": "Kalender",
    "contact": "Neem contact met ons op",
    "login": "Inloggen",
    "logout": "Afmelden",
    "previous_odometer_reading": "Vorige kilometerstand",
    "current_odometer_reading": "Huidige kilometerstand",
    "odometer_reading": "Kilometerstand",
    "tank_volume": "Tankinhoud",
    "vehicle_fuel_costs": "Kosten voor tanken",
    "mileage_surcharge": "Extra kilometertoeslag",
    "insurance_self_participation": "Eigen risico verzekering",
    "additional_costs_due": "Extra kosten als gevolg van",
    "no_additional_costs": "Geen extra kosten",
    "charge_customer_additional_costs": "De klant extra kosten in rekening brengen",
    "warning_you_chose_not_to_charge_customer_for_additional_costs": "Opgelet! Je hebt besloten de klant geen extra kosten in rekening te brengen",
    "cancel_booking": "Boeking annuleren",
    "no_invoices_found": "Geen facturen gevonden",
    "upload_drivers_license": "Upload rijbewijs",
    "order_details": "Bestelgegevens",
    "shipping_address": "Verzendadres",
    "order_overview": "Overzicht bestellen",
    "booked_by_mistake": "Per ongeluk geboekt",
    "booked_wrong_dates": "Verkeerde data geboekt",
    "found_better_price": "Betere prijs gevonden",
    "change_of_plans": "Wijziging van plan",
    "client_called_to_cancel": "De klant belde en vroeg om te annuleren",
    "client_wrote_email_to_cancel": "De klant heeft een e-mail geschreven en gevraagd om te annuleren",
    "car_damaged_before_transport": "Het voertuig was vóór transport beschadigd",
    "car_damaged_in_transport": "Het voertuig is beschadigd tijdens transport",
    "item_not_available": "Artikel niet langer beschikbaar",
    "other_reason": "Andere",
    "license_plate_number": "Nummerplaat",
    "open_in_google_maps": "Openen in Google Maps",
    "access_identifier": "Toegangscode",
    "branch": "Tak",
    "new": "Nieuw",
    "in_progress": "In uitvoering",
    "failed": "Mislukt",
    "not_started": "Niet gestart",
    "finished": "Afgewerkt",
    "failed_refunds": "Mislukte terugbetalingen",
    "list_all_failed_refund_payments": "Vermeld alle mislukte terugbetalingen met details",
    "rent_sync_vamoso_reservation_success": "Succesvol gereserveerd in het Vamoso-systeem. Het reserveringsnummer is %reservationId%",
    "rent_sync_vamoso_reservation_failure": "Het was niet mogelijk om de auto te reserveren in het Vamoso-systeem. Het systeem gaf de volgende foutmelding: \"%errorText%\".",
    "rent_sync_vamoso_cancellation_success": "De reservering in het Vamoso-systeem is succesvol geannuleerd",
    "rent_sync_vamoso_cancellation_failure": "Het was niet mogelijk om de reservering te annuleren in het Vamoso-systeem. Het systeem gaf de volgende foutmelding: \"%errorText%\".",
    "rent_sync_vamoso_booking_success": "Succesvol geboekt in het Vamoso systeem",
    "rent_sync_vamoso_booking_failure": "Het boeken van de reservering in het Vamoso-systeem was niet mogelijk. Het systeem gaf de volgende foutmelding: \"%errorText%\".",
    "rent_sync_vamoso_return_success": "Het voertuig werd met succes gemarkeerd als teruggebracht in het Vamoso-systeem",
    "rent_sync_vamoso_return_failure": "Het was niet mogelijk om de auto terug te brengen naar het Vamoso-systeem. Het systeem gaf de volgende foutmelding: \"%errorText%\".",
    "view_lead_details": "Toon afleidingsdetails",
    "change_category": "Categorie wijzigen",
    "json_url_parameters": "JSON URL-parameters",
    "layout_version": "Lay-out versie",
    "layout_wide": "Breed",
    "layout_narrow": "Smal",
    "layout_detailed": "Gedetailleerd",
    "layout_slider": "Schuifregelaar",
    "layout_list": "Lijst",
    "search_version": "Versie zoekmodule",
    "not_sure_go_to_advisory_mode": "Ik weet het niet zeker: hier is de {link}",
    "link_to_advisory_mode": "Counselling modus",
    "desired_pickup_location": "De gewenste ophaallocatie",
    "incl_short": "incl.",
    "included": "Inbegrepen",
    "included_services": "Inbegrepen services",
    "insurance": "Verzekering",
    "liability_and_comprehensive_insurance_with_excess_amount_of_amount_per_claim": "Aansprakelijkheids- en omniumverzekering met een eigen risico van {bedrag} per schadegeval",
    "tyres_suitable_for_winter": "Winterbanden",
    "additional_driver": "Extra bestuurder",
    "on_request": "op aanvraag",
    "navigation_system": "Navigatiesysteem",
    "child_seat": "Kinderzitje",
    "order_comments_and_special_wishes": "Verdere wensen",
    "ordering_as_person": "Ik bestel als particulier",
    "ordering_as_company": "Ik bestel als bedrijf",
    "filter_by_date": "Filter op datum",
    "car_rental_max_days_count_exceeded": "Helaas overschrijdt de geselecteerde periode onze maximale huurperiode van {telling, meervoud, een {# dag} andere {# dagen}}.",
    "require_document_number": "Documentnummer aanvragen",
    "require_document_issuing_city": "De plaats van afgifte van het document opvragen",
    "require_document_issuing_authority": "Verzoek uitgevende instantie van het document",
    "require_document_issue_date": "Datum van afgifte van het document aanvragen",
    "require_document_expiration_date": "Vervaldatum van het document opvragen",
    "doc_number": "Documentnummer",
    "doc_issuing_city": "Plaats van afgifte van het document",
    "doc_issuing_authority": "Instantie van afgifte van het document",
    "doc_issue_date": "Datum van uitgifte van het document",
    "doc_expiration_date": "Vervaldatum van het document",
    "chedri_plugins": "Creacheck-plugins",
    "in_order_to_use_our_software_in_its_full_potential_you_need_to_activate_plugins_in_order_to_do_that_please_confirm_that_you_may_sign_contracts_on_behalf_of_your_company": "<p>Om onze software te kunnen gebruiken, moet u de gewenste functies activeren. Elke functie wordt geleverd in de vorm van een plugin, die meestal gepaard gaat met een betalingsverplichting (vergoeding).<br/>Wanneer u een plugin activeert, wordt u gevraagd de voorwaarden van de plugin te accepteren. Voordat u verder kunt gaan, moet u echter bevestigen dat u bevoegd bent om namens uw bedrijf te handelen.</p",
    "i_declare_that_i_am_entitled_to_legally_sign_contracts_on_behalf_of_my_company": "Ik verklaar dat ik gemachtigd ben om wettelijk bindende contracten te ondertekenen namens mijn bedrijf, {bedrijf}",
    "your_company_was_successfully_registered": "We hebben u en uw bedrijf met succes geregistreerd in ons systeem",
    "check_email_for_more_instructions_about_your_company_registration": "We hebben informatie over hoe verder te gaan naar het door u opgegeven e-mailadres gestuurd. Controleer uw Postvak IN. Als je vragen hebt of niet weet hoe je verder moet, neem dan contact met ons op.",
    "dependent_on": "Afhankelijk van",
    "we_have_more_plugins_that_further_enhance_the_functionality": "We hebben andere plugins die de functionaliteit van {plugin} verder verbeteren.",
    "finish_plugin_configuration": "Plugin-configuratie afsluiten",
    "it_seems_that_you_havent_finished_configuring_plugins": "Het lijkt erop dat je de configuratie van de geactiveerde plugins nog niet hebt voltooid. Voor sommige plugins moeten externe aanmeldgegevens worden gedefinieerd (bijv. voor de interface mobile.de of Vamoso aanmeldgegevens). Ga naar de instellingen en voeg de ontbrekende accounts toe.",
    "lead_created_from_an_email": "Deze lead is gemaakt op basis van een e-mail",
    "show_original_message": "Oorspronkelijk bericht weergeven",
    "we_found_count_similar_leads": "We hebben {telling, meervoud, een {# vergelijkbare lead} andere {# vergelijkbare leads}} gevonden.",
    "show_similiar_leads": "Soortgelijke leads tonen",
    "new_lead_from_failed_refund": "Nieuwe lead door mislukte terugbetaling",
    "you_can_apply_company_data_from_company_profile": "U kunt bedrijfsgegevens overdragen vanuit het bedrijfsprofiel",
    "load_company_data": "Bedrijfsgegevens laden",
    "company_lookup": "Bedrijf zoeken",
    "match_lead_tags": "Leadtags synchroniseren",
    "is_active": "Is actief",
    "is_renew_password_at_next_login": "Vernieuw wachtwoord bij volgende aanmelding",
    "no_overdue_tasks": "Er zijn geen achterstallige taken",
    "great_job": "Goed gedaan!",
    "no_leads_for_today": "Geen leads voor jou vandaag",
    "address_not_set": "Adres niet ingesteld",
    "statistics": "Statistieken",
    "select_assignee": "Selecteer een editor",
    "kroschke_order_in_progress": "Kroschke order met voertuig ID %kroschkeVehicleId% is succesvol geïnitialiseerd",
    "in_original": "In de oorspronkelijke",
    "a_copy": "Eén exemplaar",
    "mark_documents_sent": "Documenten markeren als verzonden",
    "i_sent_the_documents": "Ik heb de documenten opgestuurd",
    "documents_were_sent": "Documenten zijn verzonden",
    "documents_sent": "Documenten verzonden",
    "define_custom_license_plate_number": "Definieer voorkeursnummerplaat",
    "about_car_registration": "De autorisatieservice omvat",
    "i_would_like_individual_license_plate_number": "Ja, ik wil mijn gewenste personage selecteren",
    "we_gather_necessary_documents": "Registratie bij uw vergunningverlenende instantie",
    "we_produce_license_plates": "Aanschaf en montage van kentekenplaten",
    "license_plate_reservation_number": "Reserveringsnummer van de kentekenplaat",
    "with_custom_license_plate_number": "Met gepersonaliseerde nummerplaat",
    "after_the_order_reserve_desired_license_plate_at_local_registration_office": "Na het bestellen moet je de gewenste kentekenplaat online reserveren bij je plaatselijke registratiekantoor.",
    "inform_us_about_your_license_plate_reservation_so_we_can_start_the_process": "Als je dit hebt gedaan, laat ons dan weten wat je gewenste kenteken is en wat je reserveringsnummer is (dat je van het registratiekantoor hebt gekregen).",
    "clarify": "Verduidelijk",
    "notification": "Communicatie",
    "license_plate_reservation_data_missing": "De reserveringsgegevens voor het kenteken ontbreken",
    "license_plate_reservation_number_filled_out": "Reserveringsgegevens voor ingevoerde nummerplaten. Kentekenplaatnummer: %licensePlateNumber%. Reserveringsnummer: %reservationNumber%",
    "distance_planned": "Geplande route",
    "distance_driven": "Afgelegde route",
    "planned_pickup_date": "Geplande ophaaldatum",
    "pickup_date": "Verzameldatum",
    "planned_delivery_date": "Geplande levering",
    "your_initial_offer": "Je eerste aanbod",
    "your_next_offer": "Je volgende aanbieding",
    "car_number": "Voertuignummer",
    "send_price_proposal": "Stuur prijsvoorstel",
    "our_price": "Onze prijs",
    "purchase_price": "Aankoopprijs",
    "our_prices_are_already_optimized_but_if_you_think_price_is_too_high_negotiate_it": "We proberen onze prijzen voor jou te optimaliseren, maar als je denkt dat de prijs nog steeds te hoog is, laat ons dan weten wat we voor je kunnen doen.",
    "select_the_car_you_love": "Selecteer de gewenste auto",
    "sign_up_or_log_in_if_you_already_have_an_account": "Registreren of inloggen als u al een account hebt",
    "enter_desired_price": "Voer de gewenste prijs in",
    "send_the_form_our_employee_will_contact_you": "Formulier verzenden. Onze medewerker neemt zo snel mogelijk contact met u op",
    "if_we_settle_on_a_price_you_can_buy_the_car_online": "Als we een prijs overeenkomen, kun je de auto direct op onze website kopen.",
    "you_have_to_log_in_to_proceed": "U moet inloggen om verder te gaan",
    "rent_a_car": "Een voertuig huren",
    "pending_price_negotiations_count": "In afwachting van {telling, meervoud, een {# prijsvoorstel} andere {# prijsvoorstellen}}",
    "my_price_negotiation": "Mijn prijssuggestie",
    "price_negotiation": "Prijsvoorstel",
    "price_negotiations": "Prijsvoorstellen",
    "new_price_negotiation_request": "Nieuwe prijsonderhandelingsaanvraag",
    "expired": "Verlopen",
    "first_offer": "Eerste aanbod",
    "current_offer": "Huidig aanbod",
    "expires": "Verloopt op",
    "accept_the_offer": "Aanbod accepteren",
    "send_a_new_offer": "Nieuw aanbod verzenden",
    "your_new_offer": "Je nieuwe aanbod",
    "your_project_dashboard": "Je {project} dashboard",
    "add_my_vehicle": "Mijn voertuig toevoegen",
    "no_reply_yet": "Nog geen antwoord",
    "employee_replied": "Werknemer heeft geantwoord",
    "price_negotiation_details": "Details prijsvoorstel",
    "current_offer_is_amount_you_can_now_proceed_to_checkout": "Je huidige bod is {bedrag}. Je kunt naar de kassa gaan met je onderhandelde prijs.",
    "show_timeline": "Geschiedenis weergeven",
    "communities": "Gemeenschappen",
    "gain_coverage_with_community": "Vergroot je bereik met nieuwe platforms. Werf nieuwe klanten met slechts één klik",
    "community": "Gemeenschap",
    "is_community": "Gemeenschap actief",
    "join_community": "Word lid van de gemeenschap",
    "leave_community": "Gemeenschap verlaten",
    "join_jahreswagen_community_and_profit_from_additional_sales_channel": "<div><p>Eén ding dat helemaal nieuw is in Creacheck zijn communities. Dankzij de Creacheck-software is het mogelijk om platforms te maken waar meerdere bedrijven zich bij kunnen aansluiten.</p><p>Eén zo'n platform is Jahreswagen.de. Je kunt het aanbod van Jahreswagen bekijken. Als je lid wilt worden, ga je naar de community-instellingen en word je lid van de Jahreswagen-community.</p><p>Het lidmaatschap is gratis. Er worden wel transactiekosten in rekening gebracht.</p><p className='m-t-20 alert-info'>Als u zelf een idee hebt voor een community, neem dan contact met ons op. Wij zoeken dan een oplossing voor je.</p></div>",
    "car_description": "Beschrijving voertuig",
    "marketplace_terms": "Gebruiksvoorwaarden van de marktplaats",
    "online_appointment_booking": "Online afspraken maken",
    "my_online_appointments": "Mijn online afspraken",
    "employee": "Werknemers",
    "choice_of_date": "Keuze van datum",
    "works": "Werken",
    "make_selection": "Selecteer",
    "remove_selection": "Verwijder",
    "tasks": "Taken",
    "task": "Taak",
    "car_service": "Service",
    "car_services": "diensten",
    "car_service_station": "Werkstation",
    "car_service_stations": "Werkstations",
    "services_offered_at_your_locations": "Definieer diensten op uw locaties.",
    "service_stations_where_your_employees_work": "Definieer werkstations zodat je het gebruik kunt bijhouden en bezette en vrije bronnen kunt zien.",
    "team_members": "Teamleden",
    "service_details": "Service Details",
    "service_location": "De door jou gekozen servicelocatie",
    "service_drop_off_time": "Uw geselecteerde servicetijd",
    "car_service_appointments": "Workshop data",
    "car_service_booked_at": "Afspraak geboekt",
    "show_booking_details": "Toon boekingsgegevens",
    "saving_draft": "Concept is opgeslagen",
    "draft_saved": "Concept opgeslagen",
    "define_custom_page_templates_to_meet_your_cd": "We leveren het Creacheck-systeem met geïntegreerde templates. Hier kunt u uw eigen templates maken die beter passen bij uw huisstijl.",
    "edit_description": "Beschrijving bewerken",
    "edit_language": "Taal bewerken",
    "create_blank_page": "Maak een lege pagina",
    "create_from_template": "Maken vanuit sjabloon",
    "use_template": "Gebruik de sjabloon",
    "time_left": "Resterende tijd",
    "animated_header": "Geanimeerde koptekst",
    "header_size": "Kopgrootte",
    "options": "Opties",
    "effects": "Effecten",
    "animation": "Animatie",
    "parallax": "Parallax",
    "animation_repeat": "Herhaal animatie",
    "animation_intensity": "Animatie-intensiteit",
    "animation_duration": "Animatie duur",
    "animation_delay": "Animatie vertraging",
    "animation_initial_opacity": "Beginwaarde van de ondoorzichtigheid van de animatie",
    "animation_speed_curve": "Animatie snelheidscurve",
    "parallax_intensity": "Parallaxintensiteit",
    "animation_direction": "Parallax richting",
    "parallax_initial_opacity": "Initieel dekkend vermogen van de parallax",
    "append_html": "HTML bijvoegen",
    "css_classes": "CSS-klassen",
    "forward_url": "Omleiden naar deze URL",
    "effect_fade": "Fade in",
    "effect_slide": "Dia's",
    "effect_zoom": "Zoom",
    "effect_bounce": "Stuiteren",
    "effect_flip": "Flip",
    "effect_fold": "Vouwen",
    "effect_roll": "Rollen",
    "direction_center": "Centrum",
    "direction_left": "Links",
    "direction_right": "Rechts",
    "direction_top": "Boven",
    "direction_bottom": "Onder",
    "repeat_once": "Eenmaal",
    "repeat_loop": "Lus",
    "selected_template": "Geselecteerde sjabloon",
    "premade_layouts": "Geprefabriceerde indelingen",
    "your_existing_pages": "Je bestaande pagina's",
    "count_templates": "{Aantal sjablonen",
    "background_attachment": "Achtergrond bijlage",
    "background_position": "Achtergrond",
    "background_repeat": "Achtergrond Herhaling",
    "background_size": "Achtergrondgrootte",
    "edit_mode": "Bewerkingsmodus",
    "effects_active": "Effecten actief",
    "document_templates": "Documentsjablonen",
    "code_document_templates_for_print_use_the_templates_for_individual_quotes_and_invoices": "Codeer je eigen documentsjablonen om ze af te drukken voor je klanten. Je kunt ook je eigen versies van offertes en facturen ontwerpen.",
    "currencies": "Valuta",
    "document_template": "Documentsjabloon",
    "document_content": "Inhoud van het document",
    "number_prefix": "Nummer voorvoegsel",
    "sales_documents": "Verkoopdocumenten",
    "theme": "Thema",
    "choose_template": "Documentsjabloon selecteren",
    "default": "Standaard",
    "favorites": "Favorieten",
    "favorites_list": "Favorietenlijst",
    "require_client_address": "Het adres van de klant opvragen",
    "require_client_email": "E-mailadres van de klant opvragen",
    "require_client_phone": "Het telefoonnummer van de klant opvragen",
    "require_message": "Bericht aanvragen",
    "subscription": "Abonnement",
    "subscription_plan": "Abonnement",
    "main_plugin": "Hoofdplugin",
    "target_organisation": "Doelorganisatie",
    "reference_number": "Referentienummer",
    "show_invoice": "Factuur tonen",
    "delivery_date": "Leveringsdatum",
    "test_drive": "Testrit",
    "find_your_perfect_car": "Vind het perfecte voertuig",
    "select_car_design": "Selecteer ontwerp",
    "multiple_selection_possible": "Meerdere selecties mogelijk",
    "fuel_type": "Brandstof",
    "select_equipment_level": "Uitrustingsniveau kiezen",
    "engine": "Motorisatie",
    "like_budget": "Zoals budget",
    "like_family": "Zoals familie",
    "like_comfort": "Zoals comfort",
    "we_did_not_find_any_results_for_your_search": "Sorry, je zoekopdracht kwam niet overeen met",
    "we_did_not_find_any_cars_at_this_location_for_the_selected_period": "We hebben geen auto's gevonden op deze locatie voor de geselecteerde periode",
    "we_did_not_find_any_cars_for_your_seaech": "Helaas hebben we geen auto's gevonden voor uw zoekopdracht",
    "all_cars": "Toon alle auto's",
    "search_for_cars": "Auto's zoeken",
    "used_car_search": "Tweedehands auto zoeken",
    "dropdown": "Keuzelijst",
    "checkboxes": "Selectievakje",
    "multiple_choice": "Meervoudige selectie",
    "text_input": "Tekstinvoer",
    "number_input": "Aantal invoer",
    "multiline_input": "Meerregelige tekstinvoer",
    "paragraph": "Sectie",
    "change_questionnaire": "Vragenlijst wijzigen",
    "answers_to_a_questionnaire": "Antwoorden op een vragenlijst",
    "request_filling_out_questionnaire": "Stuur je klanten een vragenlijst",
    "send_questionnaire_as_message": "Stuur de vragenlijst als een bericht",
    "ingoing": "Inkomend",
    "outgoing": "Uitgaand",
    "vat_short": "BTW.",
    "vat": "Belasting toegevoegde waarde",
    "price_net": "{prijs} netto",
    "environment": "Milieu",
    "inquire": "Vragen",
    "continue_to_product_name": "Ga verder naar {productnaam}",
    "you_can_find_this_and_other_vehicles_at_company": "Je kunt deze en andere voertuigen vinden bij {bedrijf}",
    "more_vehicles_based_on_your_search": "Meer voertuigen op basis van je zoekopdracht",
    "add_to_favorites": "Opslaan als favoriet",
    "remove_from_favorites": "Verwijderen uit favorieten",
    "share_the_offer": "Aanbod delen",
    "any_question_the_company_team_is_at_your_disposal": "Heb je vragen? Het {bedrijf} team staat tot je beschikking.",
    "you_want_this_vehicle_but_the_price_isnt_right_submit_price_proposal": "Wil je dit voertuig maar is de prijs niet helemaal juist? Dien je prijsvoorstel in.",
    "you_want_to_rent_this_vehicle_at_project_name_you_can": "Je zou graag voor een langere periode in dit voertuig rijden, bij {projectnaam} heb je de mogelijkheid om je droomvoertuig te huren.",
    "uploaded_video": "Video uploaden",
    "lead_autoresponse": "Lead autorespons",
    "automatically_respond_to_incoming_leads": "Reageert automatisch op binnenkomende leads. Definieer dagen van de week en uren waarop de autoresponder actief is.",
    "client_gave_count_answers_to_the_name_questionnaire": "De klant gaf {aantal} antwoorden op de vragenlijst \"{naam}\".",
    "new_lead_from_comms_center": "Nieuwe lead na gesloten communicatie",
    "customer_chat_ended": "Chat met een klant beëindigd",
    "chatter_welcome_message": "U bent van harte welkom. Bedankt voor het uitproberen van onze chat. Hoe kan ik u vandaag helpen?",
    "please_rate_us": "Beoordeel ons",
    "reservation_fee": "Reserveringskosten",
    "item_name": "Naam artikel",
    "deposit": "Aanbetaling",
    "balance_due": "Resterend verschuldigd bedrag",
    "authorize": "Autoriseer",
    "authorized_by_name_on_date": "Goedgekeurd door {naam} op {datum}",
    "auto_generated": "Automatisch gegenereerd",
    "not_authorized": "Niet toegestaan",
    "settings_instructions": "Instructies voor instellingen",
    "payment_config": "Betalingsconfiguratie",
    "projects": "Projecten",
    "invoice_address": "Factuuradres",
    "deliver_adress": "Afleveradres",
    "your_contract_partner": "Uw contractpartner",
    "remaining_balance": "Resterend bedrag",
    "item_filter_is_active": "Online zichtbaar",
    "item_filter_is_resource": "Is bron",
    "item_filter_is_subscription": "Financiering",
    "cancelled_by_name_on_date": "Geannuleerd door {naam} op {datum}",
    "add_credit_card_data_in_next_step": "In de volgende stap kun je creditcardgegevens toevoegen",
    "upon_collection": "Bij afhalen",
    "leads_manually_set_to_high_priority": "Leads handmatig ingesteld op hoge prioriteit",
    "leads_with_no_reaction_for_2_hours_since_creation": "Leads zonder reactie gedurende 2 uur sinds aanmaak",
    "leads_that_were_not_updated_for_2_days": "Leads die al 2 dagen niet zijn bijgewerkt",
    "use_this_element_as_reference": "Gebruik dit element als referentie",
    "you_can_reuse_this_element_on_multiple_pages": "Inhoud die op meerdere websites wordt hergebruikt, kan worden gerefereerd. Elementen voor de Express Page Builder kunnen niet als referentie worden gedefinieerd. Zulke elementen worden altijd gekopieerd",
    "company_data": "Bedrijfsgegevens",
    "company_singup_company_data_hint": "We hebben de volgende informatie van je nodig om je bedrijf aan te maken en je een domein toe te wijzen.",
    "company_singup_personal_data_hint": "<p>Om uw toegang met uw eigen gebruikersgegevens te ontvangen, hebben we de volgende informatie nodig.<br/>U ontvangt dan een e-mail met uw persoonlijke toegangsgegevens.</p>",
    "your_test_domaine": "Je testdomeinnaam",
    "your_test_domaine_hint": "<p>Maak een domeinnaam aan waarin uw project zal worden gehost. <br/>Houd er rekening mee dat we ook projecten kunnen hosten onder een door u opgegeven domeinnaam. Neem dan later contact met ons op.</p>",
    "features": "Kenmerken",
    "forms": "Formulieren",
    "pricings": "Prijzen",
    "testimonials": "Referenties",
    "show_your_page_structure_and_responsive_visibility": "Toont je paginastructuur met labels en responsieve zichtbaarheid",
    "toggle_edit_mode_for_page_preview": "De bewerkingsmodus voor een paginavoorvertoning wijzigen",
    "toggle_animation_and_parallax_effects": "Animatie- en parallaxeffecten in- en uitschakelen",
    "use_quick_page_builder_to_create_content_faster": "Gebruik de Quick Page Builder om veel sneller inhoud te maken",
    "cancel_invoice": "Factuur annuleren",
    "refunded": "Terugbetaald",
    "invoice_mark_refunded": "Maak de factuur terugbetaalbaar",
    "denied_to_pay": "Weigering te betalen",
    "invoice_mark_denied_to_pay": "Betaalt niet",
    "registration_success": "Succesvol geregistreerd",
    "no_images_yet": "Nog geen foto's.",
    "sortingPrice": "Sorteerprijs",
    "main_menu_version": "Versie hoofdmenu",
    "main_menu_versions": "Uitvoeringen hoofdmenu",
    "administration_of_main_menu_versions": "Beheer van de versies van het hoofdmenu",
    "featured": "Uitgelicht",
    "list_element_class": "Lijstelement (links) CSS-klasse",
    "link_class": "CSS-klasse koppelen",
    "width": "Breedte",
    "primary_menu_elements": "Elementen van het hoofdmenu",
    "secondary_menu_elements": "Elementen van het secundaire menu",
    "has_secondary_elements": "Heeft secundaire elementen",
    "active": "actief",
    "company_singup_wizard_step_subscription_plan": "Pakketten kiezen",
    "company_singup_wizard_step_subscription_plan_headline": "Selecteer het pakket dat je wilt testen.",
    "company_singup_wizard_step_additional_plugins": "Extra pakketten",
    "company_singup_wizard_step_subscription_plan_main_plugin_headline": "Ik wil graag de volgende functies testen:",
    "company_singup_wizard_step_company_data": "Contactgegevens van uw bedrijf",
    "company_singup_wizard_step_company_data_headline": "Naam en contactgegevens van uw bedrijf",
    "company_singup_wizard_step_personal_data": "Je werknemersprofiel",
    "company_singup_wizard_step_personal_data_headline": "Je werknemersprofiel",
    "company_singup_wizard_step_personal_data_hint": "Om een werknemersprofiel voor je aan te kunnen maken, hebben we je persoonlijke gegevens nodig. Dit account geeft je beheerrechten voor je %platformnaam% project.",
    "company_singup_wizard_step_personal_data_domain_hint": "<p>Maak een domeinnaam aan waarin uw project gehost zal worden. <br/>Houd er rekening mee dat we ook projecten kunnen hosten onder een domeinnaam die u zelf opgeeft. Neem dan later contact met ons op.</p>",
    "company_singup_wizard_free_trial_hint": "Test onze aanbiedingen een maand lang gratis en vrijblijvend.",
    "company_signup_wizard_free_trial_plugin_hint": "Test dan een maand gratis:",
    "included_in_subscription_plan": "Inbegrepen in het abonnement, anders",
    "mostly_asked": "Meest gestelde vragen",
    "test_package": "Testpakket",
    "active_since": "actief sinds",
    "test_period_until": "Testfase tot",
    "navigate_to_plugins_and_configure": "Naar het overzicht en de instellingen van de plugins",
    "termination_date": "Datum van beëindiging",
    "lead_taggers": "Lead Taggers",
    "lead_tagger": "Hoofd Tagger",
    "share_with": "Deel met",
    "password_weak": "Zwak",
    "password_medium": "Medium",
    "password_strong": "Sterk",
    "plugin_already_added_in_whitelabel_project": "De plugin is al toegewezen aan het white label project.",
    "lead_channel": "Kanaal",
    "lead_channel_web": "Web",
    "lead_channel_mobile": "App",
    "lead_channel_phone": "Telefoon",
    "lead_channel_email": "E-mail",
    "lead_channel_personally": "Persoonlijk",
    "allow_others_to_share": "Sta anderen toe om met meer mensen te delen",
    "additional_imprint_information": "Extra informatie over opdruk",
    "share_with_the_company_i_work_for": "Met het bedrijf waarvoor ik werk, deel ik",
    "share_with_all_companies_in_my_holding_structure": "Met alle bedrijven in mijn holdingstructuur, aandelen",
    "latest_lead": "Laatste lood",
    "latest_edit": "Laatste bewerking",
    "reaction_time": "Reactietijd",
    "no_reaction_time": "Geen reactie",
    "gt_2_days": "> 2 dagen",
    "lt_5_minutes": "< 5 minuten",
    "lt_1_hour": "< 1 uur",
    "lt_2_hours": "< 2 uur",
    "lt_6_hours": "< 6 uur",
    "lt_1_day": "< 1 dag",
    "lt_2_days": "< 2 dagen",
    "by_employee": "Door werknemer",
    "by_company": "Per bedrijf",
    "by_location": "Per locatie",
    "by_source": "Volgens bron",
    "client_data": "Klantgegevens",
    "show_in_lead_description": "Tonen in de leadbeschrijving",
    "this_answer_will_be_shown_in_lead_description": "Dit antwoord wordt weergegeven in de leadbeschrijving",
    "this_answer_will_not_be_shown_in_lead_description": "Dit antwoord wordt niet weergegeven in de leadbeschrijving",
    "lead_assignment_strategy": "Strategie voor toewijzing van opdrachten",
    "lead_assignment_strategies": "Strategieën voor toewijzing van leads",
    "change_the_lead_assignment_strategies_for_companies_and_pipelines": "Verander de manier waarop je medewerkers leads toegewezen krijgen op basis van hun organisaties en pijplijnen.",
    "strategy": "Strategie",
    "deleted": "Verwijderd",
    "assign_to_me": "Aan mij toewijzen",
    "responsibilities": "Verantwoordelijkheden",
    "escalation_rules": "Escalatieregels",
    "escalation_rule": "Escalatieregel",
    "define_rules_when_you_would_like_to_inform_users_about_overdue_leads": "Definieert regels die je teams kunnen gebruiken om leads te identificeren die zijn achtergelaten of waar teamleden niet op reageren.",
    "category_images": "Categorie afbeeldingen",
    "use_document_template": "Documentsjabloon gebruiken",
    "escalation_level_one": "Fase één - werknemers informeren",
    "escalation_level_two": "Stap twee - teamleider informeren",
    "automatically_terminate_the_lead": "Afleiding automatisch beëindigen (afleiding markeren als verloren)",
    "no_reaction": "Geen reactie",
    "no_reaction_for": "Geen reactie voor",
    "no_update": "Geen update",
    "no_update_for": "Geen update voor",
    "escalation_level": "Escalatieniveau",
    "time_settings": "Tijdinstellingen",
    "pending": "Valt op",
    "skipped": "Overgeslagen",
    "holding_structure": "Holdingstructuur",
    "show_sibling_companies": "Broeder- en zusterbedrijven tonen",
    "show_descendant_companies": "Toon ondergeschikte bedrijven",
    "subscription_type": "Financieringsmodel",
    "no_subscription_type_selected": "Er moet minstens één financieringsmodel worden geselecteerd.",
    "financing": "Financiering",
    "monthly_payment": "Maandelijkse afbetaling",
    "final_payment": "Laatste termijn",
    "interest_rate": "Rentevoet",
    "availability": "Beschikbaarheid",
    "delivery_time_month": "Levertijd (in maanden)",
    "delivery_time": "Levertijd",
    "cancelation_period_month": "Annuleringsperiode (in maanden)",
    "enter_financing_data": "Financieringsgegevens invoeren",
    "marketing_agency": "Marketingbureau",
    "edit_company_read_and_write_rights": "De lees- en schrijfrechten van het bedrijf bewerken",
    "grant_data_access": "Gegevenstoegang verlenen",
    "deny_data_access": "Toegang tot gegevens weigeren",
    "allow_data_access_for_a_single_company": "Gegevenstoegang mogelijk maken voor één bedrijf",
    "allow_data_access_for_a_whole_level": "Gegevenstoegang voor een heel niveau mogelijk maken",
    "deny_data_access_for_a_single_company": "De toegang tot gegevens voor één bedrijf weigeren",
    "deny_data_access_for_a_whole_level": "Gegevenstoegang voor een heel niveau weigeren",
    "closing_costs": "Sluitingskosten",
    "nominal_interest_rate": "Streefrente",
    "has_rate_insurance": "Betalingsbescherming",
    "is_user_based": "Is gebaseerd op gebruikers",
    "min_user_count": "Minimaal aantal gebruikers",
    "max_user_count": "Maximaal aantal gebruikers",
    "price_per_user": "Prijs per gebruiker",
    "monthly": "Maandelijks",
    "instantly": "Onmiddellijk",
    "quarterly": "Driemaandelijks",
    "annual": "Jaarlijks",
    "monthly_fee": "Maandelijkse vergoeding",
    "starting_fee": "Inschrijfgeld",
    "included_templates_count": "Aantal meegeleverde sjablonen",
    "simple_templates_count": "Aantal meegeleverde sjablonen (enkel)",
    "middle_templates_count": "Aantal meegeleverde sjablonen (gemiddeld)",
    "complex_templates_count": "Aantal meegeleverde sjablonen (Complex)",
    "surcharge": "Toeslag",
    "base_surcharge": "Basistoeslag",
    "max_surcharge": "Maximale toeslag",
    "dataproof_surcharge": "Dataproof toeslag",
    "aws_domain_hosting": "Domeinhosting via AWS",
    "domain_hosting": "Domeinhosting",
    "desired_domain": "Gewenst domein",
    "redirect_uri": "Doorsturen",
    "check_availability": "Beschikbaarheid controleren",
    "cart_edition_title": "Benoeming:",
    "cart_recent_edition": "Laatst bewerkt:",
    "cart_campaign_title": "Campagne:",
    "cart_last_saved": "Laatst opgeslagen:",
    "remove_item": "Product verwijderen",
    "config": "Configureer",
    "submit_config": "Configuratie bevestigen",
    "complete_order": "Bestelling afronden",
    "payment_method_description": "Selecteer de gewenste betaalmethode",
    "cart_hint": "Opmerking:",
    "cart_hint_description_part_1": "Voer hier alleen algemene opmerkingen in. Opmerkingen over producten kunnen alleen in aanmerking worden genomen als u de optie",
    "cart_hint_description_part_2": "Handmatige gegevenscontrole",
    "cart_hint_description_part_3": "en de opmerking voor het respectieve product hebben ingevoerd.",
    "your_message": "Uw bericht aan ons",
    "hint_dataproof_1": "Je hebt afgezien van de handmatige gegevenscontrole door onze expert voor een of meer items.",
    "hint_dataproof_2": "We willen u erop wijzen dat u in dit geval de volledige verantwoordelijkheid draagt voor uw eigen ontwerp. In het geval van gegevensfouten, onjuiste kleuren in foto's, enz. is een klacht uitgesloten. Onze algemene voorwaarden zijn van toepassing.",
    "hint_dataproof_3": "Als je kiest voor de handmatige gegevenscontrole, wordt je bestand door een expert gecontroleerd op eventuele fouten (technische controle, geen spelling). Als we een fout over het hoofd zien, nemen we de verantwoordelijkheid op ons. Geen risico voor jou!",
    "hint_missing_order_quantity": "Orderaantal ontbreekt!",
    "missing_order_quantity": "U hebt geen bestelhoeveelheid geselecteerd voor een of meer artikelen.",
    "missing_invoice_shipping_address": "Het factuuradres of afleveradres ontbreekt. Selecteer een adres.",
    "billing_address_form_is_not_submitted": "Het factuuradres is niet ingevuld. Vul het formulier in.",
    "shipping_address_form_is_not_submitted": "Het afleveradres is niet ingevuld. Vul het formulier in.",
    "no_invoice_address": "Geen factuuradres aangemaakt",
    "no_delivery_address": "Geen afleveradres aangemaakt",
    "use_invoice_contact": "Contact voor facturering gebruiken",
    "order_item": "Artikel",
    "order_price": "Prijs",
    "order_copies": "Editie",
    "order_totals": "Bestellingstotalen",
    "subtotal": "Subtotaal",
    "invoice_amount": "Factuurbedrag",
    "remainder_available": "Niet-uitgekeerde resterende beschikbare hoeveelheid",
    "reduce_edition": "Om een nieuw adres toe te voegen, moet je eerst het aantal kopieën van het bestaande adres verminderen.",
    "split_delivery_item_hint": "De verdeling van de totale hoeveelheid is nog niet afgerond. De hoeveelheidsspecificatie moet een waarde zijn tussen 1 en de resterende nog te verdelen hoeveelheid of de oplage; momenteel is een maximumwaarde van",
    "net_amount": "Nettobedrag",
    "gross_amount": "Brutobedrag",
    "is_debtor": "Schuldeiser",
    "is_debtor_number_provider": "Debiteurennummer Aanbieder",
    "is_never_remind": "Stuur geen herinneringen",
    "is_demo": "Demomodus",
    "is_vendor": "Leverancier",
    "should_user_pay": "De gebruiker moet betalen",
    "receive_payment_from_users": "Betaling ontvangen van gebruikers",
    "use_payment_receiver": "Gebruik payee optie",
    "payment_receiver": "Begunstigde",
    "use_summary_invoice": "Gebruik verzamelfactuur",
    "credit_limit": "Kredietlimiet",
    "commission_receiver": "Ontvanger van de Commissie",
    "budget_value": "Budgetwaarde",
    "billing_value": "Afwikkelingswaarde",
    "coupon_billing": "Factureringswaarde voor geselecteerde factureringsperiode",
    "coupon_billing_period": "Factureringsperiode",
    "coupon_recharge_period": "Waardebon aanvullen",
    "coupon_print_type": "Beperk voucher",
    "coupon_never": "Nooit",
    "coupon_unset": "Geen beperking",
    "coupon_print_pdf": "Alleen PDF afdrukken",
    "recurring": "Terugkerend",
    "period": "Periode",
    "without_end_date": "Zonder einddatum",
    "budget_settings": "Budgetinstellingen",
    "no_budget_set": "Geen budget vastgesteld",
    "current_period_budget_usage": "Begrotingsgebruik in de huidige periode",
    "user_entered_no_message": "Gebruiker heeft geen bericht ingevoerd",
    "coupons": "Bonnen",
    "coupon": "Voucher",
    "create_discounts_and_subsidy_products": "Maak kortingscodes en subsidieer producten voor je partners",
    "authorization_status": "Machtigingsstatus",
    "user_budget": "Gebruikersbudget",
    "manual_vat_check": "Verlaagd BTW-tarief check",
    "manual_data_check": "Handmatige gegevenscontrole",
    "completed": "Voltooid",
    "changes_requested": "Gevraagde wijzigingen",
    "rejected": "Afgewezen",
    "request_changes": "Wijzigingen aanvragen",
    "reply": "Antwoorden",
    "coupon_monetary": "Vaste korting",
    "coupon_percentage": "Procent korting",
    "calculations": "Berekeningen",
    "percent_discount_value": "Procent korting",
    "monetary_discount_value": "Contante korting",
    "company_budget": "Bedrijfsbegroting",
    "enter_0_to_ignore_this_setting": "Voer 0 in om deze instelling te negeren",
    "min_order_value": "Minimum bestelwaarde",
    "max_order_discount": "Maximale korting per bestelling",
    "coupon_budget_exceeded": "Voucher budget overschreden",
    "billing_target": "Facturering doel",
    "billing_settings": "Factureringsinstellingen",
    "dataproof_textarea": "Moeten we ergens rekening mee houden?",
    "edit": "Bewerk",
    "edit_client": "Klantgegevens bewerken",
    "edit_gallery": "Galerijgegevens bewerken",
    "edit_job": "Taakgegevens bewerken",
    "edit_position": "Positie bewerken",
    "edit_product": "Product bewerken",
    "edit_producttemplate": "Sjabloon bewerken",
    "edit_question": "Vraag bewerken",
    "edit_template": "Sjabloon bewerken",
    "edit_step": "Pijplijnfase bewerken",
    "edit_payment": "Betaling bewerken",
    "edit_team": "Team bewerken",
    "edit_document_type": "Documenttype bewerken",
    "edit_brand": "Merk bewerken",
    "edit_model": "Model bewerken",
    "edit_category": "Categorie bewerken",
    "edit_attribute": "Bewerk kenmerk",
    "edit_data_push": "Gegevens bewerken push",
    "edit_lead_catcher": "Bewerk lead catcher",
    "edit_external_account": "Externe account bewerken",
    "edit_comment": "Opmerking bewerken",
    "edit_delivery_date": "Leveringsdatum bewerken",
    "edit_lead_tagger": "Bewerk lead tagger",
    "edit_commission_receiver": "Commissieontvanger bewerken",
    "edit_payment_receiver": "Begunstigde bewerken",
    "edit_billing_target": "Factureringsdoel bewerken",
    "create_client": "Nieuw bedrijf maken",
    "create_gallery": "Nieuwe galerij maken",
    "create_job": "Nieuwe taak aanmaken",
    "create_product": "Nieuw product maken",
    "create_producttemplate": "Nieuwe sjabloon maken",
    "create_questionnaire": "Een nieuwe vragenlijst maken",
    "create_question": "Nieuwe vraag maken",
    "create_template": "Nieuwe e-mailsjabloon maken",
    "create_cms_template": "Maak een nieuw CMS sjabloon",
    "create_pipeline": "Nieuwe pijplijn maken",
    "create_step": "Nieuwe pijplijnfase maken",
    "create_payment_schedule": "Een nieuw betalingsplan maken",
    "create_payment": "Nieuwe betaling aanmaken",
    "create_message": "Schrijf een bericht",
    "create_text_message": "Een SMS-bericht schrijven",
    "create_note": "Schrijf een notitie",
    "create_user": "Nieuwe gebruiker aanmaken",
    "create_users": "Gebruiker aanmaken",
    "create_doc_type": "Nieuw documenttype maken",
    "create_team": "Maak team",
    "create_brand": "Merk creëren",
    "create_model": "Model maken",
    "create_category": "Categorie maken",
    "create_attribute": "Kenmerk aanmaken",
    "create_attribute_group": "Attribuutgroep maken",
    "create_car": "Voertuig maken",
    "create_item": "Product maken",
    "create_lead": "Creëer voorsprong",
    "assign_lead": "Leiding toewijzen",
    "create_page": "Pagina maken",
    "create_data_push": "Nieuwe gegevenspush maken",
    "create_white_label_project": "Een nieuw white label project maken",
    "create_cms_element": "Nieuw CMS element maken",
    "create_lead_catcher": "Maak een nieuwe lead catcher",
    "create_white_label_plugin": "Maak een nieuwe white label plugin",
    "create_dynamic_data_definition": "Een nieuwe dynamische gegevensdefinitie maken",
    "add_account": "Account toevoegen",
    "create_external_account": "Nieuwe externe account toevoegen",
    "create_model_group": "Nieuwe modelgroep maken",
    "create_model_asset": "Nieuw modelbeeld maken",
    "create_lead_per_mail_rule": "Nieuwe lead aanmaken via e-mailregel",
    "duplicate_page": "Dubbele pagina",
    "duplicate_cms_element": "Duplicaat CMS element",
    "duplicate_cms_template": "Duplicaat CMS sjabloon",
    "create_task": "Taak maken",
    "create_car_service": "Service maken",
    "create_car_service_station": "Werkstation maken",
    "create_doc_template": "Nieuwe documentsjabloon maken",
    "create_lead_autoresponse": "Nieuwe autorespons voor leads maken",
    "create_main_menu_version": "Versie hoofdmenu maken",
    "create_lead_tagger": "Leadtagger maken",
    "create_lead_assignment_strategy": "Strategie voor leadtoewijzing creëren",
    "create_escalation_rule": "Regel voor escalatie aanmaken",
    "create_coupon": "Tegoedbon maken",
    "client_data_saved": "Opgeslagen klantgegevens",
    "user_data_saved": "Gebruikersgegevens opgeslagen",
    "job_data_saved": "Taakgegevens opgeslagen",
    "product_data_saved": "Productgegevens opgeslagen",
    "template_data_saved": "Sjabloongegevens opgeslagen",
    "company_logo_saved": "Logo is opgeslagen",
    "payment_schedule_data_saved": "Betalingsplan is opgeslagen",
    "questionnaire_filled_out": "Ingevulde vragenlijst",
    "your_message_has_been_sent": "Uw bericht is verzonden",
    "email_with_password_reset_instructions_sent": "E-mail met instructies voor het resetten van het wachtwoord is verzonden",
    "password_changed_after_reset": "Je wachtwoord is gewijzigd. Welkom terug",
    "quote_accepted_saved": "Aanbod aanvaard",
    "quote_rejected_saved": "Aanbod afgewezen",
    "quote_saved": "Opgeslagen aanbieding",
    "message_sent": "Bericht is verzonden",
    "note_saved": "Opmerking is opgeslagen",
    "holding_info_saved": "Bewaarde informatie",
    "new_password_set": "Er is een nieuw wachtwoord ingesteld",
    "questionnaire_data_saved": "Vragenlijst opgeslagen",
    "doc_type_saved": "Documenttype opgeslagen",
    "team_saved": "Team gered",
    "document_saved": "Document opgeslagen",
    "brand_saved": "Merk gered",
    "model_saved": "Model opgeslagen",
    "category_saved": "Categorie opgeslagen",
    "attribute_saved": "Attribuut opgeslagen",
    "item_data_saved": "Productgegevens opgeslagen",
    "car_data_saved": "Opgeslagen voertuiggegevens",
    "variant_saved": "Variant opgeslagen",
    "pipeline_saved": "Pijplijn opgeslagen",
    "comment_saved": "Commentaar opgeslagen",
    "lead_saved": "Lood opgeslagen",
    "order_saved": "Bestelling opgeslagen",
    "document_reviewed": "Document beoordeeld",
    "locations_saved": "Locaties opgeslagen",
    "email_confirmed": "E-mail bevestigd",
    "page_saved": "Pagina opgeslagen",
    "template_saved": "Sjabloon opgeslagen",
    "data_push_saved": "Gegevens push opgeslagen",
    "white_label_saved": "White label project opgeslagen",
    "attribute_group_saved": "Attribuutgroep opgeslagen",
    "cms_element_saved": "CMS element opgeslagen",
    "lead_catcher_saved": "Lead catcher gered",
    "image_data_saved": "Beeldgegevens opgeslagen",
    "white_label_plugin_saved": "White label plugin opgeslagen",
    "dynamic_data_definition_saved": "Dynamische gegevensdefinitie opgeslagen",
    "company_data_saved": "Bedrijfsgegevens opgeslagen",
    "contact_data_saved": "Contactgegevens opgeslagen",
    "no_changes_made": "Geen wijzigingen aangebracht",
    "car_order_approved": "Auto verkoop toegestaan",
    "vin_number_updated": "Chassisnummer van het bijgewerkte voertuig",
    "ein_number_updated": "Elektronische verzekeringsbevestiging bijgewerkt",
    "order_marked_as_paid": "Bestelling gemarkeerd als betaald",
    "payment_instructions_sent": "Betalingsinstructies verzonden",
    "order_cancelled": "Bestelling geannuleerd",
    "sales_contract_uploaded": "Verkoopcontract geüpload",
    "bank_data_updated": "Bankgegevens bijgewerkt",
    "pickup_invite_sent": "Afhaaluitnodiging verstuurd",
    "order_picked_up": "Bestelling opgehaald",
    "order_assignee_kroschke_documents_sent": "De autodealer heeft de documenten opgestuurd die nodig zijn om het voertuig te registreren",
    "order_client_kroschke_documents_sent": "De klant heeft de documenten opgestuurd die vereist zijn voor de registratie van zijn voertuig",
    "plugin_partial_activated": "Plugin gedeeltelijk geactiveerd",
    "plugin_request_activate_sent": "Plugin activeringsverzoek verzonden",
    "plugin_activated": "Plugin geactiveerd",
    "plugin_terminated": "Plugin beëindigd",
    "changes_saved": "Wijzigingen opgeslagen",
    "app_secret_saved": "App-geheim opgeslagen",
    "page_access_token_saved": "Pagina toegangstoken opgeslagen",
    "text_message_sent": "SMS verzonden",
    "account_saved": "Account opgeslagen",
    "model_group_saved": "Modelgroep opgeslagen",
    "model_asset_saved": "Modelafbeelding opgeslagen",
    "sepa_agreement_saved": "SEPA-mandaat gered",
    "document_uploaded": "Er is een document geüpload",
    "customer_sent_message": "Klant heeft een bericht verzonden",
    "lead_created": "Lood gecreëerd",
    "lead_watching": "Begonnen met de observatie van de afleidingen",
    "lead_unwatching": "Beëindigt de bewaking van afleidingen",
    "lead_per_mail_rule_saved": "Lead opgeslagen door e-mailregel",
    "drivers_license_uploaded": "Rijbewijs geüpload",
    "plugin_saved": "Plugin opgeslagen",
    "settings_saved": "Instellingen opgeslagen",
    "page_duplicated": "Pagina gedupliceerd",
    "cms_template_duplicated": "CMS sjabloon gedupliceerd",
    "cms_element_duplicated": "CMS element gedupliceerd",
    "price_negotiation_started": "Prijsonderhandeling gestart",
    "new_price_negotiation_message_offer_sent": "Nieuwe prijsonderhandelingsaanbieding verzonden",
    "you_joined_community": "Je bent lid geworden van de community",
    "you_left_community": "Je hebt de gemeenschap verlaten",
    "car_service_booked": "Online afspraak geboekt",
    "car_service_saved": "Service opgeslagen",
    "car_service_station_saved": "Werkstation opgeslagen",
    "team_member_added": "Teamlid toegevoegd",
    "team_member_removed": "Teamlid verwijderd",
    "doc_template_saved": "Documentsjabloon opgeslagen",
    "invoice_issued": "Factuur uitgegeven",
    "request_to_fill_out_questionnaire_saved": "Verzoek om een opgeslagen vragenlijst in te vullen",
    "request_to_fill_out_questionnaire_name_saved": "Verzoek om een vragenlijst in te vullen \"{naam}\" opgeslagen",
    "lead_autoresponse_saved": "Lead autoresponse opgeslagen",
    "main_menu_version_saved": "Versie hoofdmenu opgeslagen",
    "lead_tagger_saved": "Lead Tagger gered",
    "share_settings_updated": "Release-instellingen bijgewerkt",
    "lead_assignment_strategy_saved": "Leadtoewijzingsstrategie opgeslagen",
    "escalation_rule_saved": "Escalatieregel opgeslagen",
    "value_type": "Type",
    "value_precision": "Nauwkeurigheid",
    "category_already_in_attribute": "De categorie is al toegewezen aan het kenmerk.",
    "no_attributes_for_variants": "U moet attributen definiëren om varianten te kunnen maken.",
    "budget_saved": "Bespaard budget",
    "budget_removed": "Budget verwijderd",
    "coupon_saved": "Voucher opgeslagen",
    "authorization_data_saved": "Opgeslagen autorisatiegegevens",
    "template_data": "Algemene informatie",
    "template_pages": "Pagina-instellingen",
    "template_editor": "Editor & PDF-instellingen",
    "template_timeline": "Tijdlijn",
    "template_blocks": "Inhoud blokken",
    "template_import": "Importeren",
    "template_export": "Exporteer",
    "template_generate": "Generator (TEST)",
    "template_print_data": "Gegevens afdrukken",
    "template_import_select_template": "Selecteer sjabloonmap",
    "template_import_select_print_data_document": "Selecteer document met afdrukgegevens",
    "template_upload_print_data_title": "Afdrukgegevens uploaden naar de sjabloonmap",
    "template_upload_print_data_actual_file_title": "Geüploade afdrukgegevens",
    "template_upload_print_data_info": "Voegt een bestand toe aan de S3-sjabloondirectory. Dit bestand wordt gebruikt voor producten wanneer ze worden gemaakt en er geen afdrukgegevens worden gegenereerd.",
    "template_blocks_template": "HTML-blok",
    "template_category_thumb": "Pictogramklasse thumbnail (bijv.: fad fa-cirkel)",
    "add_block_category": "Nieuwe categorie",
    "edit_block_category": "Categorie bewerken",
    "add_block_variant": "Nieuwe variant",
    "edit_block_variant": "Bewerk variant",
    "add_block": "Nieuw blok",
    "copy_block": "Blok kopiëren",
    "edit_block": "Bewerk blok",
    "edit_takeit": "Overnemen",
    "edit_import": "Importeren",
    "edit_import_warning": "Weet u het zeker? Hiermee worden alle niet-opgeslagen vermeldingen overschreven!",
    "template_has_content_blocks": "Variabele inhoud",
    "template_in_test": "Testmodus voor bestellingen",
    "placeholder": "Plaatshouder voor samenvoegen",
    "select_customer": "Selecteer klanten",
    "company_added": "Bedrijf toegevoegd",
    "company_removed": "Bedrijf verwijderd",
    "item_added": "Toegevoegd product",
    "item_removed": "Verwijderd product",
    "category_added": "Categorie toegevoegd",
    "category_removed": "Categorie verwijderd",
    "select_user_set": "Gebruikersmachtiging selecteren",
    "sent_reminders": "Verzonden herinneringen",
    "number_of_sent_reminders": "Aantal verzonden herinneringen",
    "set_number": "Ingesteld aantal",
    "email_to_the_customer_on_save": "Naar de klant sturen per e-mail bij het opslaan",
    "email_on_save": "E-mail bij opslaan",
    "shown_name": "Naam weergeven",
    "filename_thumbnail": "Bestandsnaam voor thumbnail",
    "name_of_imagefile": "Naam van het afbeeldingsbestand, zonder directory. Voorbeeld: thumb1.jpg",
    "block_position": "Positie van het blok in rasterlijnen, 0 voor boven, maakt het blok absoluut",
    "defined_raster_space": "Vooraf gedefinieerde rasterlocatie",
    "needed_raster_space": "Vereiste rasterruimte",
    "size_group_info_text": "Alle blokken met dezelfde SizeGroup kunnen in grootte worden omgeschakeld in de editor",
    "size_group": "Grootte Groep",
    "group_index_info_text": "Alle blokken met dezelfde SizeGroup en dezelfde blokgrootte kunnen worden doorlopen met de index. Vanaf index 1.",
    "group_index": "Groep Index",
    "bottom_info_text": "LET OP: onderaan positioneert het blok absoluut onderaan de pagina, met de afstand ingesteld in PX",
    "bottom_label": "Plaats blok absoluut onderaan",
    "fixed_block_label": "Vast blok",
    "fixed_block_info_text": " Blok kan niet worden verplaatst",
    "once_per_document_label": "Eenmaal per document",
    "once_per_document_info_text": "slechts één keer toegestaan in het hele document",
    "no_copies_label": "Blok kan niet worden gekopieerd",
    "no_copies_info_text": "Blok kan niet worden gekopieerd",
    "no_options_label": "Geen opties weergeven",
    "no_options_info_text": "Het blok heeft geen opties in de editor wanneer het wordt ingevoegd.",
    "once_per_page_label": "Eenmaal per pagina",
    "once_per_page_info_text": "slechts eenmaal toegestaan aan één kant",
    "once_per_variant_label": "Eenmaal per variant",
    "once_per_variant_info_text": "Slechts één keer toegestaan per variant",
    "once_per_category_label": "Eenmaal per categorie",
    "once_per_category_info_text": "slechts één per categorie toegestaan",
    "only_whitelabel_admin_can_modify_label": "Alleen whitelabel beheerder",
    "only_whitelabel_admin_can_modify_info_text": "Dit veld kan alleen gewijzigd worden door de whitelabel beheerder. Het moet gebruikt worden wanneer de whitelabel beheerder een sjabloon aanmaakt.",
    "restricted_to_page_label": "Toegestaan op pagina",
    "restricted_to_page_info_text": "Alleen beschikbaar op deze pagina, -1 = overal toegestaan",
    "exclude_pages_label": "Pagina's uitsluiten (bijv.: 1,2,3...)",
    "exclude_pages_info_text": "Niet toegestaan op deze pagina's, scheiden met komma",
    "only_last_label": "Alleen laatste pagina",
    "only_last_info_text": "Een blok kan alleen op de laatste pagina worden ingevoegd.",
    "container_block_label": "Containerblok",
    "container_block_info_text": "Dit blok bevat een container voor verdere blokken",
    "inline_block_label": "Inline-blok",
    "inline_block_info_text": "Blok wordt behandeld als display:inline-block",
    "inner_label": "Kan in blokcontainer worden geplaatst",
    "inner_info_text": "Blok kan in blokcontainer worden geplaatst",
    "container_class_label": "Beperken tot containerblok",
    "container_class_info_text": "Klassenaam van de container waarin het blok kan worden ingevoegd.",
    "only_inside_block_label": "Kan alleen in blokcontainers worden geplaatst",
    "only_inside_block_info_text": "Blok kan alleen worden ingevoegd in blokcontainers",
    "container_space_label": "Rasterruimtes in het containerblok",
    "container_space_info_text": "Beschikbare rasterlijnen binnen het blok (hetzelfde raster als Pagina!)",
    "container_cols_label": "Kolommen in het containerblok",
    "container_cols_info_text": "beschikbare rasterlijnen worden vermenigvuldigd met deze waarde",
    "website_only": "Alleen voor websites",
    "navName": "Naam voor navigatie",
    "nav_link_label": "Link voor navigatie",
    "nav_link_info_text": "Ankerlink naar de ID van het blok",
    "nav_options": "Opties voor navigatie",
    "combi_parent_label": "Combinatie-element (parent)",
    "combi_parent_info_text": "Ouder element, kan op elk moment worden ingevoegd",
    "combi_child_label": "Combinatie-element (Kind)",
    "combi_child_info_text": "Kindelementen kunnen alleen worden ingesteld in combinatie met het ouderelement",
    "disable_group_label": "Groepsopties",
    "disable_group_info_text": "Hier kun je groepen definiëren die elkaar uitsluiten, bijv. P#Groep1,Groep2 en D#Groep2,Groep1. Definitieparameter - P = geldig voor de pagina, D = geldig voor het document.",
    "events": {
      "TimelineMailPasswordReset": "De klant heeft de wijziging van het wachtwoord aangevraagd",
      "TimelineMailRegistration": "Klant heeft zich geregistreerd bij Creacheck.com",
      "TimelineMailOauthRegistration": "Klant heeft zich geregistreerd bij Creacheck.com met social media login",
      "TimelineMailInvitation": "De klant is uitgenodigd om Creacheck.com te gebruiken",
      "TimelineMailContactForm": "Klant heeft een bericht gestuurd via het contactformulier",
      "TimelineMailInvitationAccepted": "De klant heeft de uitnodiging geaccepteerd",
      "TimelineMailNewInvoice": "Je hebt de klant een factuur gestuurd",
      "TimelineMailNewQuote": "Je hebt een aanbieding naar de klant gestuurd",
      "TimelineMailNewMessage": "U hebt een bericht naar de klant gestuurd",
      "TimelineQuoteAccepted": "De klant heeft je aanbod geaccepteerd",
      "TimelineQuoteRejected": "Helaas heeft de klant uw aanbod afgewezen",
      "TimelineOrderCreated": "Nieuwe bestelling",
      "TimelineMailNewCustomerMessage": "E-mailbericht van de klant",
      "TimelineMonitoringPaymentReceipt": "Inkomende betalingen bewaken",
      "TimelineClosingManualDataCheck": "Handmatige gegevenscontrole gesloten",
      "TimelineUpdatedByForeignStatus": "Bestelstatus bijgewerkt",
      "TimelineMailedForeignOrderStatusOrderCanceled": "geannuleerd door leverancier",
      "TimelineMailedForeignOrderStatusInDelivery": "in levering",
      "TimelineMailedForeignOrderStatusInProduction": "in productie",
      "TimelineMailedForeignOrderStatusInReview": "in overleg",
      "TimelineMailedForeignOrderStatusInOrder": "op volgorde",
      "TimelineMailedOrderedWebAndDownloadPDF": "Web-en downloadbare PDF verzonden naar e-mail ontvangers.",
      "TimelineMailedOrderedDownloadPDF": "Download PDF verzonden naar e-mail ontvangers.",
      "TimelineMailedOrderedWebPDF": "Web-enabled verzonden naar e-mail ontvangers.",
      "TimelineDomainHostingHasBeenAuthorized": "Domeinhosting is geautoriseerd",
      "TimelineDomainNotAvailable": "Domein is niet langer beschikbaar",
      "TimelineDomainHostingDomainValidated": "Domein is gevalideerd",
      "TimelineExceptionCheckDomainAvailability": "Fout bij het controleren op domeinbeschikbaarheid van {0}. Fout: {2}",
      "TimelineDomainFailRegistering": "Registratie van domein {0} mislukt",
      "TimelineExceptionRegisterDomain": "Registratie van domein {0} mislukt met fout: {2}",
      "TimelineDomainSuccessfullyRegistered": "Domein {0} is succesvol geregistreerd.",
      "TimelineDomainSuccessfullyRedirected": "Domein succesvol omgeleid",
      "TimelineDomainCreatingCertificate": "Certificaat voor domein {0} is aangemaakt met fout: {1}",
      "TimelineDomainCreatingDistributionFailed": "Distributie van domein {0} mislukt met fout: {1}",
      "TimelineDomainCreatingSuccessfullyHosted": "Domein {0} is succesvol gehost",
      "TimelineDomainCreatingFailedHosting": "Domein {0} kon niet worden gehost met fout: {1}",
      "TimelineDomainHostingCompleted": "Voltooid",
      "TimelineMissingClientDataFirstAndLastName": "Voor- en achternaam van de klant ontbreken!",
      "TimelineMissingClientDataPhoneNumber": "Ontbrekend telefoonnummer van de klant!",
      "TimelineDomainCreatingSuccessfullyFinished": "Domein {0} is succesvol voltooid"
    },
    "attributeName": {
      "select": "Selectieveld",
      "html": "HTML-veld",
      "checkbox": "Selectievakje",
      "text": "Tekstveld",
      "colors": "Keuze aan kleuren",
      "number": "Nummer veld",
      "boolean": "Ja/Nee",
      "date": "datum"
    },
    "attributeType": {
      "select": "Je kunt verschillende opties definiëren om te selecteren. De gebruiker kan er dan een selecteren",
      "html": "Gebruiker kan een langere tekst schrijven met Rich Content Editor",
      "checkbox": "Je kunt verschillende opties definiëren om te selecteren. De gebruiker kan er dan verschillende selecteren",
      "text": "Gebruiker kan een eenvoudige tekst invoeren",
      "colors": "De gebruiker kan een van de hier gedefinieerde kleuren selecteren",
      "number": "De gebruiker kan een nummer invoeren",
      "boolean": "Gebruiker kan kiezen tussen ja en nee",
      "date": "De gebruiker kan een datum selecteren"
    },
    "attributeValueType": {
      "string": "Tekst",
      "number": "Aantal",
      "date": "datum"
    },
    "attributePrecision": {
      "year": "Jaar",
      "month": "maand",
      "day": "Dag",
      "time": "Tijd van de dag"
    },
    "categoryDisplay": {
      "select": "Ja/nee",
      "number_range": "Nummerreeks",
      "date_range": "Datumbereik",
      "list": "Lijst selectie"
    },
    "invoice_actions": {
      "tab_title": "Acties op de factuur",
      "refunded": {
        "title": "Handmatig terugbetaald",
        "label": "Markeer als terugbetaald",
        "info_text": "Handmatig markeren van de factuur als reeds terugbetaald zonder een annuleringsfactuur aan te maken"
      },
      "denied_to_pay": {
        "title": "Betaling geweigerd",
        "label": "Markeren als geweigerd",
        "info_text": "Markeer dat de factuur nooit zal worden betaald"
      },
      "amount_paid": {
        "title": "Betaald bedrag invoeren",
        "placeholder": "Betaald bedrag invoeren",
        "button": "Betaald bedrag opslaan"
      },
      "sent_reminders": {
        "title": "Verzonden herinneringen",
        "placeholder": "Aantal verzonden herinneringen",
        "button": "Nummer opslaan"
      }
    },
    "mail": {
      "registration": {
        "subject": "Bedankt voor uw registratie bij %bedrijf%",
        "subject_robethood": "Robethood Creatives Portal: Registratie succesvol.",
        "explanation": "Bedankt voor uw registratie. Bevestig je e-mailadres binnen 24 uur. Alleen met een bevestigd e-mailadres kun je een nieuw internetwachtwoord of informatie over wijzigingen ontvangen.",
        "explanation_first_name_form": "<p>Bedankt voor uw registratie. Gelieve uw e-mailadres binnen 24 uur te bevestigen.</p><p>Alleen met een bevestigd e-mailadres kunt u een nieuw wachtwoord of informatie over wijzigingen ontvangen.</p>",
        "possible_actions": "Zodra je e-mailadres is bevestigd, krijg je direct toegang tot alle functies die we voor je hebben voorbereid.",
        "possible_actions_first_name_form": "Zodra je e-mailadres is bevestigd, krijg je direct toegang tot alle functies die we voor je hebben voorbereid.",
        "call_to_action": "Mijn e-mailadres bevestigen"
      },
      "ordered_web_pdf": {
        "subject": "PDF op het web voor bestelling %orderId% bij %bedrijf%",
        "explanation": "Hartelijk dank voor uw bestelling. U kunt de web-enabled PDF openen en downloaden door op de onderstaande knop te klikken.",
        "explanation_first_name_form": "Hartelijk dank voor uw bestelling. U kunt de web-enabled PDF openen en downloaden door op de onderstaande knop te klikken.",
        "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
        "possible_actions_first_name_form": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
        "call_to_action": "Toon volgorde",
        "download": "Download web-enabled PDF"
      },
      "ordered_download_pdf": {
        "subject": "PDF voor order %orderId% van %bedrijf%",
        "explanation": "Hartelijk dank voor uw bestelling. Je kunt de PDF openen en downloaden door op onderstaande knop te klikken.",
        "explanation_first_name_form": "Hartelijk dank voor uw bestelling. Je kunt de PDF openen en downloaden door op onderstaande knop te klikken.",
        "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
        "possible_actions_first_name_form": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
        "call_to_action": "Toon volgorde",
        "download": "Downloaden PDF"
      },
      "order_vat_data_check_done": {
        "subject": "Uw bestelling voor de handmatige gegevenscontrole voor de bestelling %orderId% van %bedrijf%",
        "explanation": "Bedankt voor je bestelling en voor het bestellen van de handmatige gegevenscontrole.",
        "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
        "call_to_action": "Status weergeven"
      },
      "order_reduced_vat_authorized": {
        "subject": "Uw aanvraag voor een verlaagd BTW-tarief voor de bestelling %orderId% is goedgekeurd",
        "explanation": "Bedankt voor uw bestelling. We hebben de bestelling geautoriseerd voor het verlaagde btw-tarief.",
        "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
        "call_to_action": "Status weergeven"
      },
      "order_reduced_vat_rejected": {
        "subject": "Uw verzoek voor een verlaagd BTW-tarief voor de bestelling %orderId% is niet goedgekeurd",
        "explanation": "Hartelijk dank voor uw bestelling. We hebben uw bestelling gecontroleerd en vastgesteld dat deze niet in aanmerking komt voor het verlaagde btw-tarief. Het normale btw-tarief zal worden toegepast.",
        "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
        "call_to_action": "Status weergeven"
      },
      "chedri_registration": {
        "subject": "Uw registratie in het %bedrijf%",
        "subject_fiendly": "Uw registratie bij %bedrijf%",
        "explanation": "<p>Hallo %voornaam% %achternaam%,</p><p>Dank u voor uw registratie.</p><p>We hebben met succes een testaccount voor u en uw bedrijf gemaakt.</p>",
        "possible_actions": "Zodra je e-mailadres is bevestigd, kun je inloggen met <strong>%username%</strong> als gebruikersnaam en het wachtwoord dat je tijdens de registratie hebt gekozen.",
        "call_to_action": "Mijn e-mailadres bevestigen"
      },
      "invitation": {
        "subject": "Uitnodiging van %bedrijf%",
        "explanation": "Namens %company% nodigen wij u van harte uit op ons online ontwerpportaal.",
        "login_data": "Als u de uitnodiging accepteert, gebruikt u de volgende gegevens om in te loggen: <strong>%gebruikersnaam%</strong> als gebruikersnaam en <strong>%wachtwoord%</strong> als wachtwoord. Zorg ervoor dat u uw wachtwoord wijzigt nadat u bent ingelogd.",
        "call_to_action": "Uitnodiging accepteren",
        "explanation_first_name_form": "Namens %company% nodigen wij u van harte uit op ons online ontwerpportaal.",
        "login_data_first_name_form": "Als u de uitnodiging accepteert, gebruikt u de volgende gegevens om in te loggen: <strong>%gebruikersnaam%</strong> als gebruikersnaam en <strong>%wachtwoord%</strong> als wachtwoord. Zorg ervoor dat u uw wachtwoord wijzigt nadat u bent ingelogd."
      },
      "new_quote": {
        "subject": "Nieuw aanbod van %bedrijf%",
        "explanation": "%bedrijf% stuurt u een offerte voor een dienst die u hebt aangevraagd.",
        "possible_actions": "Je hebt nu de mogelijkheid om het aanbod te controleren en te accepteren. Je kunt het aanbod ook weigeren en een bericht achterlaten waarin je uitlegt waarom.",
        "call_to_action": "Aanbod controleren"
      },
      "new_invoice": {
        "subject": "Nieuwe factuur van %bedrijf%",
        "explanation": "%bedrijf% stuurt je een factuur voor een dienst die je hebt aangevraagd. Je kunt de factuur vinden in ons online portaal. We hebben de factuur ook als bijlage bij dit bericht gevoegd.",
        "explanation_first_name_form": "%bedrijf% stuurt je een factuur voor een dienst die je hebt aangevraagd. Je kunt de factuur vinden in ons online portaal. We hebben de factuur ook als bijlage bij dit bericht gevoegd.",
        "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je facturen bekijken.",
        "possible_actions_first_name_form": "Je kunt op elk moment inloggen op het online portaal op %domein% en je facturen bekijken.",
        "call_to_action": "Factuur tonen"
      },
      "new_sales_commission": {
        "subject": "Nieuwe opdracht van %bedrijf%.",
        "explanation": "%bedrijf% stuurt je een commissie voor een dienst die je hebt aangevraagd. Je kunt de commissie vinden in ons online portaal. We hebben de commissie ook als bijlage bij dit bericht gevoegd.",
        "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je commissie online bekijken.",
        "call_to_action": "Commissie tonen"
      },
      "new_questionnaire": {
        "subject": "%bedrijf% wil graag dat u een vragenlijst invult",
        "explanation": "<p>Hallo %voornaam% %achternaam%,</p><p>%bedrijf% stuurt u een nieuwe vragenlijst.</p>",
        "possible_actions": "Je kunt de vragenlijst online bekijken en invullen.",
        "call_to_action": "Vragenlijst bekijken"
      },
      "questionnaire_filled_out": {
        "subject": "De vragenlijst is zojuist ingevuld",
        "explanation": "<p>Hallo %voornaam% %achternaam%,</p><p>De vragenlijst aangevraagd door %doelvoornaam% is zojuist ingevuld.</p>",
        "possible_actions": "Je kunt de antwoorden bekijken in de leidende tijdlijn.",
        "call_to_action": "Open lood"
      },
      "contact_form_cms": {
        "subject": "Nieuw contact van %naam%",
        "info": "Melding van het indienen van een contactformulier. Verwerk dit onmiddellijk en zorg ervoor dat de klant de best mogelijke ondersteuning krijgt."
      },
      "contact_form_edition": {
        "subject": "Nieuw contact",
        "info": "<p>Het contactformulier is gebruikt. Zorg er nu voor dat je de best mogelijke klantenservice levert :) Veel succes</p>",
        "form_fields": "Ingevulde formuliervelden"
      },
      "password_renew": {
        "subject": "Wachtwoord vernieuwen",
        "explanation": "<p>Hallo %email%,<br><br>U hebt geprobeerd in te loggen op %platformNaam%. Uw wachtwoord is verlopen. U moet een nieuw wachtwoord instellen om in de toekomst in te kunnen loggen. Klik op de onderstaande link om een nieuw wachtwoord in te stellen.</p>",
        "possible_actions": "Je hebt de mogelijkheid om een nieuw wachtwoord in te stellen. Klik gewoon op de knop.",
        "call_to_action": "Nieuw wachtwoord instellen",
        "explanation_first_name_form": "<p>Hallo %email%,<br><br>u hebt geprobeerd in te loggen op %platformnaam%. Uw wachtwoord is verlopen. U moet een nieuw wachtwoord instellen om in de toekomst in te kunnen loggen. Klik op de onderstaande link om een nieuw wachtwoord in te voeren.</p>",
        "possible_actions_first_name_form": "Je hebt de mogelijkheid om een nieuw wachtwoord in te stellen. Klik gewoon op de knop."
      },
      "password_reset": {
        "subject": "Wachtwoord opnieuw instellen",
        "explanation": "<p>Goedemiddag %email%,</p><br /><p>er is zojuist een nieuw wachtwoord aangevraagd bij %platformNaam% via ons platform. Als u deze aanvraag heeft gedaan, klik dan op onderstaande link.</p",
        "explanation_first_name_form": "<p>Hallo %email%,</p><br /><p>er is zojuist een nieuw wachtwoord aangevraagd bij %platformNaam% via ons platform. Als u deze aanvraag heeft gedaan, klik dan op onderstaande link.</p",
        "possible_actions": "Je hebt de mogelijkheid om een nieuw wachtwoord in te stellen. Klik gewoon op de knop.",
        "possible_actions_first_name_form": "Je hebt de mogelijkheid om een nieuw wachtwoord in te stellen. Klik gewoon op de knop.",
        "call_to_action": "Nieuw wachtwoord instellen"
      },
      "message": {
        "explanation": "%bedrijf% heeft u een bericht gestuurd",
        "possible_actions": "Je kunt dit bericht beantwoorden door op deze knop te klikken:",
        "call_to_action": "Antwoorden"
      },
      "customerMessage": {
        "explanation": "Klant heeft u een bericht gestuurd"
      },
      "lead_comment_mention": {
        "subject": "Ze werden genoemd in de %title% lead",
        "explanation": "%creator% noemde je in een commentaar.",
        "call_to_action": "Open lood"
      },
      "lead_assignee_changed": {
        "subject_assigned": "Je bent nu toegewezen aan de lead '%title%'.",
        "subject_unassigned": "Ze zijn niet langer toegewezen aan de lead '%title%'.",
        "explanation_assigned": "Je bent nu toegewezen aan de leiding '%title%'. De vorige redacteur was %oldAssignee%.",
        "explanation_unassigned": "Ze zijn niet langer toegewezen aan de lead '%title%'. De nieuwe toegewezene is %newAssignee%.",
        "call_to_action": "Open lood"
      },
      "bank_data_reminder": {
        "subject": "We hebben je bankgegevens nodig voor je bestelling",
        "explanation": "Je hebt diensten geboekt waarvoor bankgegevens vereist zijn.",
        "possible_actions": "Ga naar de bestelling en voer de ontbrekende gegevens in.",
        "call_to_action": "Bankgegevens invoeren"
      },
      "order_approved": {
        "subject": "Uw bestelling bij %bedrijf% is zojuist goedgekeurd",
        "explanation": "We zijn blij u te kunnen informeren dat uw bestelling %orderId% is goedgekeurd.",
        "possible_actions": "Je kunt naar je bestelling gaan om de details te bekijken.",
        "call_to_action": "Toon volgorde"
      },
      "sales_contract_uploaded": {
        "subject": "Het koopcontract voor uw bestelling is nu beschikbaar"
      },
      "order_cancelled": {
        "subject": "Uw bestelling bij %bedrijf% is geannuleerd",
        "explanation": "Het spijt ons, maar we hebben uw bestelling %orderId% moeten annuleren.",
        "possible_actions": "Ga naar je bestelling om de details te bekijken.",
        "call_to_action": "Toon volgorde"
      },
      "payment_instructions": {
        "subject": "Betalingsgegevens voor je bestelling bij %bedrijf%",
        "explanation": "Hieronder vindt u de betalingsgegevens voor uw bestelling %orderId%:",
        "possible_actions": "Ga naar je bestelling om de details te bekijken.",
        "call_to_action": "Toon volgorde"
      },
      "pickup_invite": {
        "subject": "Je bestelling is klaar om te worden opgehaald",
        "explanation": "Uw bestelling met het %orderId% is klaar om te worden opgehaald.",
        "possible_actions": "Ga naar je bestelling om de details te bekijken.",
        "call_to_action": "Toon volgorde"
      },
      "order_picked_up": {
        "subject": "Bedankt voor uw bestelling"
      },
      "request_plugin_activation": {
        "subject": "Plugin activering gevraagd",
        "explanation": "<p>Hallo %voornaam% %achternaam%,</p><p>%creatorvoornaam% %creatorachternaam% wil graag de plugin <strong>%pluginnaam%</strong> activeren voor het project %platformnaam%.</p>",
        "possible_actions": "U kunt inloggen en het activeringsformulier gebruiken door op de volgende knop te drukken.",
        "call_to_action": "De plugin activeren"
      },
      "plugin_activated": {
        "subject": "Plugin geactiveerd",
        "explanation": "<p>Hallo %voornaam% %achternaam%,</p><p>Wij willen u graag informeren dat de %pluginNaam% voor het %platformNaam% project is geactiveerd.</p>",
        "call_to_action": "Bekijk het in actie"
      },
      "lead_data": {
        "subject": "Nieuwe lead: %title%",
        "call_to_action": "Toon voorsprong"
      },
      "order": {
        "item_sales": {
          "title": "Uw bestelling",
          "explanation": "De details van je bestelling vind je hieronder.",
          "subject": "Uw bestelling bij %bedrijf%",
          "possible_actions": "Je kunt ook alle informatie over je bestelling vinden in je klantenaccount.",
          "call_to_action": "Bekijken in klantenaccount"
        }
      },
      "rent_sync_vamoso_error": {
        "subject": "Bestelling #%orderId%: Fout in huur synchronisatie voor Vamoso Plugin: %errorType%",
        "explanation": "Helaas kon onze plugin geen taak uitvoeren in je Vamoso-systeem. Controleer de boekingsstatus handmatig. <br/>Fouttype: %errorType% <br/>Foutbericht: %errorText%"
      },
      "lead_deadline_reminder": {
        "subject": "Ze worden toegewezen aan leads die morgen klaar moeten zijn",
        "explanation": "Hier vind je een lijst met de leads die aan jou zijn toegewezen. Deze leads staan nog open en moeten morgen worden ingeleverd."
      },
      "chat_history": {
        "subject": "Je chatgeschiedenis",
        "explanation": "Bedankt voor uw contact. In de bijlage vindt u uw chatgeschiedenis met %firstName% %lastName%. Ik hoop dat we u hebben kunnen helpen met al uw vragen of problemen. Als u nog vragen heeft, kunt u altijd weer contact met ons opnemen.",
        "greatingchat_history": "Geschiedenis chat",
        "greating": "Bedankt voor het gebruik van de chat van %platformNaam%. Uw chatpartner was %firstName% %lastName% Hieronder ziet u de chatgeschiedenis vanaf %datum%.",
        "adaption": "Ik hoop dat we je hebben kunnen helpen met al je vragen of problemen. Als je nog vragen hebt, kun je altijd contact met ons opnemen.",
        "privacy_policy": "U kunt ons privacybeleid vinden op <a href=\"%link%\" target=\"_blank\">'%link%</a>.",
        "call_to_action": "Bezoek ons opnieuw"
      },
      "lead_pool_notification": {
        "subject": "Nieuw lood in de pool: %title%",
        "explanation": "Er is een nieuwe lead beschikbaar in het systeem. We hebben nog geen medewerker toegewezen. Klik op de knop hieronder om de details te bekijken.",
        "call_to_action": "Open lood"
      },
      "lead_escalation_level1": {
        "subject": "[NIVEAU1] %naam%",
        "explanation": "Je ontvangt deze e-mail omdat een of meer leads het eerste escalatieniveau hebben bereikt dat is gedefinieerd in de regel %naam%. Controleer de afleidingen om te zien wat er moet gebeuren."
      },
      "lead_escalation_level2": {
        "subject": "[NIVEAU2] %naam%",
        "explanation": "Je ontvangt deze e-mail omdat een of meer leads het tweede escalatieniveau hebben bereikt dat is gedefinieerd in de regel %naam%. Controleer de leads en probeer je teams te motiveren om harder te werken."
      },
      "campaign_budget_auth_request": {
        "subject": "Waarschuwing: Campagnebudget overschreden.",
        "explanation": "het campagnebudget werd overschreden.",
        "call_to_action": "Toon volgorde"
      },
      "campaign_budget_auth_request_unsuccessful": {
        "subject": "Waarschuwing: Probleem met de autorisatie van het campagnebudget.",
        "explanation": "Er is een probleem ontdekt met de autorisatie van het campagnebudget.",
        "call_to_action": "Toon volgorde"
      },
      "user_budget_auth_request": {
        "subject": "Waarschuwing: Gebruikersbudget overschreden.",
        "explanation": "het gebruikersbudget werd overschreden.",
        "call_to_action": "Toon volgorde"
      },
      "user_budget_auth_request_unsuccessful": {
        "subject": "Waarschuwing: Probleem met de autorisatie van het gebruikersbudget",
        "explanation": "Er is een probleem ontdekt met de autorisatie van het gebruikersbudget.",
        "call_to_action": "Toon volgorde"
      },
      "order_auth_changes_requested": {
        "subject": "Info: Wijzigingen gevraagd",
        "call_to_action": "Toon volgorde"
      },
      "order_auth_changes_implemented": {
        "subject": "Info: Wijzigingen aangebracht",
        "call_to_action": "Toon volgorde"
      },
      "manage_notification": "E-mailmeldingen beheren",
      "unsubscribe": "Afmelden",
      "confidentiality_notice": "Vertrouwelijkheid: Deze e-mail is uitsluitend bestemd voor de opgegeven ontvanger en kan vertrouwelijke en/of wettelijk beschermde informatie bevatten. Als u niet de juiste ontvanger bent of deze e-mail per vergissing hebt ontvangen, stel de afzender dan onmiddellijk op de hoogte en vernietig deze e-mail. Ongeoorloofd kopiëren en ongeoorloofd doorsturen van deze e-mail is niet toegestaan.",
      "no_legal_binding_notice": "Dit bericht is uitsluitend bedoeld ter informatie en is niet juridisch bindend. Vanwege de gemakkelijke manipuleerbaarheid van e-mails kunnen we geen aansprakelijkheid aanvaarden voor de inhoud.",
      "invoice_due_reminder": {
        "subject": "Betalingsherinnering",
        "explanation": "Helaas hebben we de ontvangst van betaling voor de volgende factuur nog niet kunnen vaststellen.",
        "explanation_first_name_form": "Helaas hebben we de ontvangst van betaling voor de volgende factuur nog niet kunnen vaststellen.",
        "request_payment": "Om verdere kosten te vermijden, vragen we om onmiddellijke betaling van het totale bedrag. Gebruik de volgende bankgegevens voor de betaling van deze factuur:",
        "request_payment_first_name_form": "Om verdere kosten te voorkomen, verzoeken wij u het totale bedrag onmiddellijk te betalen. Gebruik de volgende bankgegevens om deze factuur te betalen:",
        "contact": "Als je vragen hebt over deze herinnering, neem dan contact met ons op per e-mail op %email% of per telefoon op de %phone% hotline.",
        "contact_first_name_form": "Als je vragen hebt over deze herinnering, neem dan contact met ons op per e-mail op %email% of per telefoon op de %phone% hotline.",
        "bank_account": "Bankgegevens",
        "bank_name": "Naam bank",
        "account_holder": "Rekeninghouder",
        "iban": "IBAN",
        "swift_bic": "SWIFT/BIC",
        "bank_code_number": "Sorteercode bank",
        "account_number": "Rekeningnummer",
        "invoice_number": "Factuurnr:"
      },
      "last_seen_reminder": {
        "subject": "Laatste keer gezien Herinnering",
        "user_not_seen": "De gebruiker '%clientUsername%' is al %daysNotSeen% dagen niet gezien.",
        "contact_user": "Neem contact met hen op om er zeker van te zijn dat alles in orde is.",
        "contact_user_first_name_form": "Neem contact met hen op om er zeker van te zijn dat alles in orde is.",
        "customer_profil": "Profiel van de klant"
      },
      "order_foreign_status": {
        "do_contact_support": {
          "subject": "Bestellen - Neem contact op met de klantenservice",
          "explanation": "Bedankt voor uw bestelling. We hebben een vraag over uw bestelling. Neem contact op met onze klantenservice.",
          "explanation_first_name_form": "Bedankt voor uw bestelling. We hebben een vraag over uw bestelling. Neem contact op met onze klantenservice.",
          "possible_actions": "-",
          "contact": "Neem contact met ons op",
          "phone": "Telefoon:",
          "email": "e-mail:",
          "call_to_action": "-"
        },
        "in_delivery": {
          "subject": "De bestelling wordt momenteel verzonden",
          "explanation": "Je bestelling is geproduceerd en overhandigd aan de koerier voor levering.",
          "explanation_first_name_form": "Je bestelling is geproduceerd en overhandigd aan de koerier voor levering.",
          "possible_actions_tracking": "Je kunt de levering volgen op de website van de koeriersdienst.",
          "possible_actions_tracking_first_name_form": "Je kunt de levering volgen op de website van de koeriersdienst.",
          "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
          "possible_actions_first_name_form": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
          "call_to_action": "Bestelgegevens",
          "tracking_numbers": "Volgnummers"
        },
        "in_production": {
          "subject": "De bestelling wordt momenteel geproduceerd",
          "explanation": "Bedankt voor uw bestelling. We hebben uw bestelling geplaatst. Het wordt momenteel geproduceerd.",
          "explanation_first_name_form": "Bedankt voor uw bestelling. We hebben uw bestelling geplaatst. Het wordt momenteel geproduceerd.",
          "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
          "possible_actions_first_name_form": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
          "call_to_action": "Bestelgegevens"
        },
        "invalid_print_data": {
          "subject": "Bestelling - ongeldige afdrukgegevens",
          "explanation": "Er zijn ongeldige afdrukgegevens gevonden tijdens de bestelling.",
          "call_to_action": "Bestelgegevens"
        },
        "missing_print_data": {
          "subject": "Bestelling - ontbrekende afdrukgegevens",
          "explanation": "Bij het plaatsen van de bestelling zijn ontbrekende afdrukgegevens gevonden.",
          "call_to_action": "Bestelgegevens"
        },
        "order_canceled": {
          "subject": "De bestelling is geannuleerd",
          "explanation": "Je bestelling is succesvol geannuleerd. Het spijt ons dat je je bestelling hebt moeten annuleren en we hopen dat we je in de toekomst nog eens kunnen helpen.",
          "explanation_first_name_form": "Je bestelling is succesvol geannuleerd. Het spijt ons dat je je bestelling hebt moeten annuleren en we hopen dat we je in de toekomst nog eens kunnen helpen.",
          "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
          "possible_actions_first_name_form": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
          "call_to_action": "Bestelgegevens"
        },
        "payment_pending": {
          "subject": "Inkooporder - uitstaande betaling",
          "explanation": "We hebben de betaling voor je bestelling nog niet ontvangen.",
          "explanation_first_name_form": "We hebben de betaling voor je bestelling nog niet ontvangen.",
          "possible_actions": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
          "possible_actions_first_name_form": "Je kunt op elk moment inloggen op het online portaal op %domein% en je bestelling bekijken.",
          "call_to_action": "Bestelgegevens"
        }
      },
      "contact_form_cms_confirm": {
        "subject": "Bevestig uw e-mail",
        "explanation": "<p>Uw e-mailadres is ingevoerd in het contactformulier op onze website.</p><p>Als u ons contactformulier hebt ingevuld, klikt u op de knop Bevestigen.</p><p>Of negeer dit bericht.</p>",
        "call_to_action": "Bevestig"
      },
      "contact_form_edition_confirm": {
        "subject": "Bevestig uw e-mail",
        "explanation": "<p>Uw e-mailadres is ingevuld in het contactformulier op onze website.</p><p>Als u ons contactformulier hebt ingevuld, klikt u op de knop Bevestigen.</p><p>Of negeer dit bericht.</p>",
        "call_to_action": "Bevestig"
      },
      "order_content_check_status": {
        "subject": "de inhoudscontrole voor de bestelling %orderId% was %status%",
        "explanation": "Hartelijk dank voor uw bestelling. We hebben de inhoud van uw bestelling gecontroleerd en deze de %status% gegeven.",
        "possible_actions": "Je kunt je bestelling oproepen om de details te bekijken.",
        "call_to_action": "Bestelling bekijken",
        "reason": "Reden"
      },
      "lead_follow_up_reminder": {
        "subject": "Controleer de toegewezen taken",
        "explanation": "Hier vind je een lijst met taken die aan jou zijn toegewezen. Deze taken staan nog open."
      }
    },
    "contact_form_cms_confirm": {
      "success": {
        "heading": "Uw vraag is verzonden",
        "body": "<p>Dank u voor uw contact met ons! </p><p>We hebben uw vraag ontvangen en nemen zo snel mogelijk contact met u op.</p>"
      },
      "already_confirmed": {
        "heading": "Deze aanvraag is al bevestigd",
        "body": "<p>We hebben uw vraag ontvangen en nemen zo snel mogelijk contact met u op.</p>"
      },
      "not_found": {
        "heading": "Niet gevonden",
        "body": "<p>Zorg ervoor dat je op de knop in onze e-mail hebt geklikt om hier te komen.</p>"
      }
    },
    "contact_form_edition_confirm": {
      "success": {
        "heading": "Uw vraag is verzonden",
        "body": "<p>Dank u voor uw contact met ons! </p><p>We hebben uw vraag ontvangen en nemen zo snel mogelijk contact met u op.</p>"
      },
      "already_sent": {
        "heading": "Deze aanvraag is al bevestigd",
        "body": "<p>We hebben uw vraag ontvangen en nemen zo snel mogelijk contact met u op.</p>"
      },
      "not_found": {
        "heading": "Niet gevonden",
        "body": "<p>Zorg ervoor dat je op de knop in onze e-mail hebt geklikt om hier te komen.</p>"
      },
      "expired": {
        "heading": "Code verlopen",
        "body": "<p>De bevestigingslink was slechts 2 dagen geldig. Vul het contactformulier opnieuw in.</p>"
      }
    },
    "formGroup": {
      "basic_information": "Basisinformatie",
      "contact_person": "Contactpersoon",
      "address_information": "Adres",
      "address_settings": "Adresinstellingen",
      "bank_account": "Bankgegevens",
      "company_information": "Bedrijfsinformatie",
      "user_roles": "Gebruikersrollen",
      "subscription_roles": "Gebruikersabonnement",
      "opening_hours": "Openingstijden",
      "position_on_map": "Positie op de kaart",
      "white_label_parent": "Voorloper",
      "user_teams": "Gebruikersteams",
      "contact_data": "Contactgegevens",
      "permissions": "Autorisaties",
      "person_information": "Informatie voor de gebruiker"
    },
    "hints": {
      "small_hint_for_you": "Een kleine tip voor jou",
      "task_is_automated": "Deze taak is geautomatiseerd. Je moet wachten tot de automatiseringstaak wordt uitgevoerd. Daarna markeren we het als voltooid.",
      "please_complete_previous_tasks": "Je kunt deze taak nu niet bewerken. Zorg eerst voor de vorige taken.",
      "companyLogoNew": "<p>U hebt uw logo nog niet geüpload. Om er een te uploaden, sleep je gewoon een afbeelding (jpeg of png) naar deze pagina.</p>",
      "companyLogoChange": "<p>Dit is het logo dat je hebt geüpload. Om het te wijzigen, sleep je gewoon een afbeelding (jpeg of png) naar deze pagina.</p>)",
      "companyFinancial": "<div><p>Vul de gegevens in die nodig zijn voor facturering</p><p>De bankgegevens die u hier invult, worden weergegeven in de voettekst op uw offertes en facturen</p></div>",
      "companyPaymentInfo": "<p>Deze informatie wordt weergegeven op elke factuur die je maakt.</p"
    },
    "vendors": {
      "association": "Associatie met klantgegevens",
      "vendor_api": "Servicenaam",
      "pricing": "Prijzen",
      "operating_mode": "Bedrijfsmodus",
      "price_update_method": "Methode voor prijsupdates",
      "order_processing": "Orderverwerking",
      "order_method": "Bestelmethode",
      "order_email": "E-mailadres voor bestellingen",
      "phone_number": "Telefoonnummer",
      "web_shop_url": "URL webshop",
      "trial": "proef",
      "live": "live",
      "prices_by_vendor": "Prijzen worden beheerd door de aanbieder",
      "prices_by_employee": "Prijzen worden gecontroleerd en bijgehouden door de medewerker",
      "prices_by_api": "Prijzen worden bijgewerkt via een API",
      "notified_by_email": "Wordt per e-mail op de hoogte gesteld van binnenkomende bestellingen",
      "accepts_only_email": "Accepteert alleen bestellingen per e-mail",
      "use_webshop": "Bestellingen via webshop",
      "order_by_api": "Bestellingen via API",
      "order_by_api_and_webshop": "Bestellingen via API of webshop"
    },
    "client_account": "Klant account",
    "deleted_campaigns": "Verwijderde campagnes",
    "deleted_editions": "Verwijderde edities",
    "deleted_folders": "Verwijderde mappen",
    "deleted_media": "Verwijderde media",
    "root": "Hoofdmap",
    "trash_bin": "Prullenmand",
    "delete_trash_bin": "Lege prullenmand",
    "pls_wait": "Even geduld. De gewenste actie wordt uitgevoerd.",
    "go_back": "Terug",
    "restore": "Herstel",
    "archived_campaigns": "Gearchiveerde campagnes",
    "load_all_campaigns": "Alle campagnes laden",
    "load_creacheck_campaigns": "Creacheck campagnes laden",
    "load_flyeralarm_campaigns": "Laad Flyeralarm campagnes",
    "load_designgenie_campaigns": "Laad DesignGenie-campagnes",
    "archived_editions": "Gearchiveerde edities",
    "sorting_name": "Naam",
    "sorting_date_ASC": "Oudste eerst",
    "sorting_date_DESC": "Nieuwste eerst",
    "sorting_owner": "Bedrijf",
    "sorting_creator": "Gemaakt door",
    "campaign_title": "Campagne",
    "dataproof": "Gegevens controleren",
    "not_ordered": "niet besteld",
    "supplier_order": "Bestelling leverancier",
    "change_of_address": "Adreswijziging",
    "not_available": "niet beschikbaar",
    "selected_shipping_method": "Geselecteerde verzendmethode",
    "ordered_items": "Bestelde artikelen",
    "standard": "Standaard",
    "express": "Express",
    "aws_iframe_hosting": "AWS Iframe Hosting",
    "aws_hosting_html_file_for_iframe_downloaded": "AWS Hosting HTML-bestand voor iframe met titel: %title%",
    "lead_stage_change_confirm_title": "Afleidingsstatus wijzigen",
    "lead_stage_change_confirm_body": "Wil je echt de status van de lead wijzigen?",
    "iframe_implementation_file": "Iframe implementatiebestand",
    "iframe_implementation_file_download_failed": "Iframe implementatiebestand kon niet worden gedownload: %error%",
    "lead_stage_change_error_domain_hosting_in_progress": "De lijn kan niet worden gewijzigd als de huidige fase loopt of als de autorisatie niet is goedgekeurd.",
    "lead_stage_change_error_domain_hosting_command_to_start_pipeline_has_been_sent": "De lijn kan niet worden gewijzigd als de huidige fase loopt of als de autorisatie niet is goedgekeurd.",
    "allowed_domain_already_exists": "Het domein %domein% staat al in de lijst met toegestane domeinen.",
    "allowed_domain_domain_name_should_not_be_empty": "De domeinnaam mag niet leeg zijn.",
    "allowed_domains_title": "Toegestane domeinen",
    "allowed_domains_description": "Hier kun je domeinen toevoegen die gebruikt kunnen worden voor de implementatie van iframes.",
    "allowed_domains_add": "Domein toevoegen",
    "allowed_domains_domain_name": "Domeinnaam",
    "allowed_domains_default_src": "Standaard-Src",
    "allowed_domains_script_src": "Script-Src",
    "allowed_domains_connect_src": "Connect-Src",
    "allowed_domains_style_src": "Stijl-Src",
    "allowed_domains_img_src": "Img-Src",
    "allowed_domains_font_src": "Font-Src",
    "allowed_domains_frame_src": "Frame-Src",
    "created_at": "Gemaakt op",
    "created_by": "Gemaakt door",
    "overnight": "Overnachting",
    "create_printData": "Afdrukgegevens maken",
    "created_new_printData": "Er zijn nieuwe afdrukgegevens gemaakt",
    "uploaded_printData_successful": "Afdrukgegevens zijn succesvol geüpload",
    "no_printData": "Geen afdrukgegevens",
    "no_images": "Geen afbeeldingsbestanden beschikbaar",
    "not_the_original_printFile": "Opmerking: dit is niet het originele printbestand",
    "printDataFiles": "Gegevensbestand(en) afdrukken",
    "image_files": "Afbeeldingsbestanden",
    "customer_comment": "Commentaar van klant",
    "actions_": " Acties",
    "import_success": "Uw import is uitgevoerd",
    "import_rejected": "Uw import is geannuleerd",
    "import_exec": "Uw import wordt uitgevoerd",
    "import_successRE": "Je import is gereset",
    "import_rejectedRE": "Het proces werd geannuleerd",
    "import_execRE": "Je import wordt gereset",
    "choice_csv": "Selecteer een CSV-bestand",
    "csv_content": "CSV-inhoud",
    "warning_and_notes": "Waarschuwingen en opmerkingen",
    "start_remove": "Start verwijdering",
    "reverse_remove": "Verwijdering ongedaan maken",
    "user_import_success": "Succesvol importeren",
    "user_duplicate_import": "Duplicaat gevonden",
    "start_import": "Import starten",
    "reverse_import": "Importeren ongedaan maken",
    "upload_local_changes": "Lokale wijzigingen uploaden",
    "dataproof_master_copies_upload": "Hier kunt u de lokaal bewerkte sjabloon-/gegevensbestanden uploaden met [Uploaden].",
    "edit_edition": "Reclamemateriaal bewerken",
    "dataproof_master_copies_recreate": "Hier kan [Afdrukgegevens maken] worden gebruikt om een nieuwe creatie van het (de) sjabloon-/afdrukgegevensbestand(en) te starten op basis van het origineel of kan het (de) sjabloon-/afdrukgegevensbestand(en) lokaal worden bewerkt met [Bewerken].",
    "hint": "Opmerking",
    "dataproof_hint_master_copies_upload": "De lokaal gewijzigde sjabloon-/afdrukgegevensbestanden moeten worden geüpload met [Uploaden] om de wijziging van kracht te laten worden nadat de nabewerking is voltooid.",
    "edit_": "Bewerk",
    "send_invoice": "Factuur sturen",
    "sended_invoice": "De factuur is verstuurd naar",
    "send_email_tracking": "Verzending per e-mail volgen",
    "sended_email": "De e-mail is verzonden!",
    "tracking": "Zending volgen",
    "send_tracking_to_customer": "Verzendingen naar klanten traceren",
    "to_the_product": "Naar het product",
    "order_sended": "De bestelling is verzonden!",
    "failure_when_ordering": "Er is een fout opgetreden tijdens de bestelling!",
    "please_create_orderhistory_email": "De bestelling is per e-mail verzonden! Maak een bestelgeschiedenis aan!",
    "new_status_set": "De nieuwe status is ingesteld!",
    "change_of_status": "Statuswijziging",
    "statusQuery": "Statusaanvraag",
    "totalStatus": "Algemene status:",
    "printData": "Gegevens afdrukken",
    "current_printdata_sended": "De huidige afdrukgegevens zijn verzonden!",
    "orderHistory": "Bestelproces",
    "orderHistory_created": "De bestelgeschiedenis is aangemaakt!",
    "order_created": "De bestelling is succesvol aangemaakt!",
    "enter_trackingLink": "Volglink invoeren",
    "query_orderStatus": "Bestelstatus opvragen",
    "foreignId": "Extern bestelnr.",
    "orderStatus": "Bestelstatus",
    "orderDate": "Datum bestelling",
    "options_": "Opties",
    "type_": "Vriendelijk",
    "comment_": "Opmerking",
    "orderOptions": "Bestelopties",
    "ordering_by_webshop": "Bestellen via webshop",
    "ordering_by_email": "Bestellen per e-mail",
    "info_to_the_emailOrder": "Aanvullende informatie voor bestellingen per e-mail",
    "add_or_edit_foreignId": "Extern bestelnummer toevoegen of bewerken",
    "create_trackingLink": "Koppeling maken voor het volgen van zendingen",
    "campaign": {
      "copy_edition": "Dubbele",
      "created_by": "Gemaakt door:",
      "no_edition": "Je hebt nog geen reclamemateriaal bewerkt!",
      "configuration": "Algemene opties",
      "select_all": "Alle producten",
      "budget": "Campagnebudget",
      "access": "Toegangsrechten",
      "inactive": "Sluit campagne",
      "delete": "Campagne verwijderen",
      "transfer_title": "De campagne overdragen aan een andere gebruiker",
      "campaign_owner": "Campagne eigenaar",
      "campaign_creator": "Campagne maker",
      "change_owner": "Campagne-eigenaar wijzigen",
      "change_creator": "Wijzig campagne maker",
      "order_all": "Hele campagne bestellen",
      "share": "Deel campagne",
      "archive": "Archief campagne",
      "recent_editions": "Laatst bewerkt",
      "marked_editions": "Gemarkeerd reclamemateriaal",
      "marked_campaigns": "Gelabelde campagnes",
      "own_campaigns": "Eigen campagnes",
      "shared_editions": "Gedeeld advertentiemateriaal",
      "last_edited": "Bewerkt op:",
      "generated": "Gemaakt op:",
      "edition_edit": "Start editor",
      "edition_item": "Geselecteerd product",
      "submission_item": "Geselecteerde inzending",
      "edition_configuration": "2. instellingen",
      "edition_order": "Toevoegen aan winkelmandje",
      "edition_reorder": "Nabestellen",
      "ordered": "Besteld!",
      "edition_delete": "Verwijder",
      "edition_preview": "Voorbeeld",
      "edition_edit_alt": "Bewerk",
      "edition_error_empty": "Je hebt %s onbewerkte pagina's",
      "submissions": "Sjablonen",
      "new": "Nieuwe campagne maken",
      "back_to_list": "Alle campagnes",
      "new_edition": "Creëer nieuw reclamemateriaal",
      "settings": "Campagne beheren",
      "campaign_archived": "Uw campagne is gearchiveerd",
      "successfully_archived": "Succesvol archiveren",
      "save_settings": "Sla",
      "cancel_settings": "Annuleren",
      "title": "Naam campagne",
      "edition_title": "Aanwijzing",
      "publisher": "Vermelding van de uitgever",
      "creator": "Campagne gemaakt door:",
      "editions": "Reclamemateriaal",
      "edition": "Reclamemateriaal",
      "sidebar": "Campagnes",
      "mark_edition": "Reclamemateriaal markeren",
      "unmark_edition": "Markering verwijderen",
      "campaign_title_valid": "Voer een naam in voor je campagne.",
      "campaign_publisher_valid": "Voer uw auteursrechtgegevens in.",
      "choose_company_to_load_campaign": "Om de campagnes te laden, selecteer een bedrijf of klik op de knop 'Alle campagnes laden",
      "no_product_selected": "Geen product geselecteerd",
      "new_edition_title": "Nieuwe editie",
      "added_to_cart": "Je campagne is toegevoegd aan je winkelmandje!",
      "edition_add_to_cart_title": "Product in winkelmandje",
      "edition_add_to_cart_button_label": "Geweldig",
      "edition_add_to_cart_label": "Naar het winkelmandje",
      "edition_added_to_cart_message": " is toegevoegd aan je winkelmandje.",
      "edit_disabled": "Het bewerken van de editie was tijdelijk uitgeschakeld vanwege een sjabloonupdate.",
      "export_image": "Afbeelding exporteren",
      "unknown": "onbekend",
      "already_ordered": "Deze editie is al besteld en kan niet meer bewerkt worden. Je kunt de editie echter wel dupliceren en bewerken. Zie menu",
      "modal_tabs": {
        "default_edition": "Algemeen reclamemateriaal",
        "edition_from_submission": "Reclamemateriaal van sjabloon"
      }
    },
    "uom": {
      "piece": "Stuk",
      "gram": "gram",
      "kilogram": "Kilogram",
      "running_meter": "Lopende meter",
      "liter": "Liter",
      "package": "Pakket",
      "kilometer": "Kilometers"
    },
    "uom_symbole": {
      "gram": "g",
      "kilogram": "kg",
      "running_meter": "lineaire meters",
      "liter": "l",
      "package": "Pkt.",
      "kilometer": "km"
    },
    "pipelineViews": {
      "self": "Aan mij toegewezen",
      "unassigned": "Zonder toewijzing",
      "pastdeadline": "Deadline overschreden",
      "duetoday": "Verschuldigd vandaag"
    },
    "leadPriority": {
      "critical": "Kritisch",
      "high": "Hoog",
      "normal": "Normaal",
      "low": "Laag"
    },
    "orderTypes": {
      "item_sales": "Verkoop",
      "car_rent": "Auto huren",
      "car_sales": "Autoverkoop",
      "car_service": "Workshop afspraak"
    },
    "genderOptions": {
      "male": "Mannelijk",
      "female": "Vrouw"
    },
    "salutationOptions": {
      "mr": "De heer",
      "mrs": "Mevrouw"
    },
    "team_service": "Serviceteam",
    "team_dispo": "Dispositieteam",
    "team_rent": "Verhuurteam",
    "team_sales": "Verkoopteam",
    "car_documents": "Voertuigdocumenten",
    "nav_module_chip": "Navigatiemodule / chip",
    "safety_vest": "Veiligheidsvest",
    "parking_disk": "Parkeerschijf",
    "ice_scraper": "IJskrabber",
    "key_supplement_heating_taxis": "Sleuteluitbreiding (parkeerverwarming/taxibord)",
    "shelf_blind": "Hoedenplank / rolgordijn",
    "onboard_folder": "Boordmap",
    "first_aid_kit": "EHBO-kit",
    "warning_triangle": "Waarschuwingsdriehoek",
    "tirefit_spare_wheel": "Tirefit / reservewiel",
    "handover_damage": {
      "front": "Voor",
      "back": "Achter",
      "left": "Links",
      "right": "Rechts",
      "top": "Boven",
      "interior": "Interieur"
    },
    "dark": "Het is donker",
    "garage": "In een garage",
    "snow_ice": "Sneeuw / ijs",
    "rain_wet": "Het regent / de auto is nat",
    "countries": {
      "de": "Duitsland",
      "pl": "Polen",
      "fr": "Frankrijk"
    },
    "cookie_description": {
      "PHPSESSID": "Identificeert uw huidige browsersessie",
      "tempTarget": "We gebruiken de tijdelijke doelcookie om de producten waarnaar u op zoek bent en uw interesses te identificeren.",
      "chedriCookiesBoxClosed": "We gebruiken deze cookie om aan te geven dat u onze cookie-informatie hebt gezien en geaccepteerd.",
      "chedriCookiesPerformance": "Gebruikt om te bepalen of u onze prestatiecookies accepteert",
      "chedriCookiesTargeting": "Gebruikt om te bepalen of u onze targeting cookies accepteert",
      "ga": "Gebruikt om gebruikers te onderscheiden in de Google Analytics-service",
      "gid": "Gebruikt om gebruikers te onderscheiden in de Google Analytics-service",
      "act": "Dit is een Facebook-cookie dat wordt gebruikt voor hun analyse en onderzoek",
      "c_user": "Facebook-login voor gebruikersaanmeldingen, die wordt gebruikt om advertenties weer te geven die relevanter zijn voor gebruikers en hun interesses",
      "datr": "Gebruikt door Facebook om de browser van de gebruiker te identificeren",
      "fr": "Bevat een unieke browser- en gebruikers-ID die wordt gebruikt voor gerichte reclame",
      "sb": "Gebruikt door Facebook om de suggesties van vrienden te verbeteren",
      "xs": "Een Facebook-cookie dat wordt gebruikt om een sessie te beheren. Deze cookie werkt samen met de c_user-cookie om uw identiteit op Facebook te verifiëren."
    },
    "cookie_ttl": {
      "6_months": "6 maanden",
      "60_days": "60 dagen",
      "2_years": "2 jaar",
      "1_day": "1 dag"
    },
    "upload_and_manage_media_files": "Hier kun je je media uploaden en beheren.",
    "profile_information_avatar_contact_address": "Hier kun je je profielgegevens bewerken. Avatar, contactgegevens, adres voor facturering, financiële informatie.",
    "define_sales_points_address_position_map_opening_hours": "Definieer je verkooppunten: Adres, positie op de kaart, openingstijden. Belangrijk voor het beheren van producten en werknemers.",
    "check_incoming_and_outgoing_invoices_check_details_and_download_as_pdf": "Je kunt inkomende en uitgaande facturen controleren die door het systeem of andere gebruikers zijn gegenereerd. Bekijk details en download ze in PDF-formaat.",
    "found_api_interesting_personal_api_key_here": "Vind je onze API interessant? Wil je hem uitproberen? Je kunt je persoonlijke API-sleutel hier vinden.",
    "need_our_data_in_own_system_configure_data_push": "Heb je onze gegevens nodig in je eigen systeem? Hier kun je data push services configureren om je te abonneren op data events.",
    "projects_running_on_different_domains": "Projecten die op verschillende domeinen worden uitgevoerd",
    "administration_of_your_website_logo_menu_pages": "Beheer je website. Logo, hoofdmenu, systeempagina's",
    "create_users_manage_profile_financial_roles_teams": "Maak gebruikers aan die in uw organisatie werken, beheer hun profiel en financiële informatie, bekijk en definieer beveiligingsrollen en wijs teams toe.",
    "provision_service": "Geboekte services",
    "provision_service_description": "Hier kun je nieuwe typen services maken, bewerken of verwijderen",
    "provision_service_title": "Titel",
    "provision_service_type": "Naam",
    "provision_service_description_text": "Beschrijving van de",
    "provision_service_primary_rate": "Provisie in procenten voor prim. eigenaar",
    "provision_service_secondary_rate": "Provisie in procenten voor sec. eigenaar",
    "provision_service_type_status": "Status",
    "add_provision_type": "Commissietype toevoegen",
    "edit_provision_type": "Bewerk",
    "create_provision_type": "Commissietype aanmaken",
    "service_type_onboarding": "Onboarding (installatie)",
    "service_type_subscriptions": "Abonnementen (MRR, abonnementen && gebruikersvergoedingen)",
    "service_type_additional_services": "Extra diensten (sjablonen)",
    "service_type_product_sales": "Product verkoop",
    "sales": "Distributie",
    "primary_owner": "Primaire eigenaar",
    "secondary_owner": "Secundaire eigenaar",
    "provision_start": "Start",
    "provision_ending": "Einde",
    "provision_actions": "Acties",
    "add_provision_service": "Commissie maken",
    "edit_provision_service": "Bewerkingscommissie",
    "save_provision_type": "Sla",
    "prov_service_client_name": "Naam",
    "provision_service_documents": "Commissies",
    "commission_service_documents": "Commissies - Documenten",
    "assign_service_type": "Nieuw servicetype toewijzen",
    "provision_service_documents_description": "Je kunt alle commissies hier bekijken",
    "provision_service_documents_download": "Documenten downloaden",
    "provision_service_documents_download_start": "downloaden",
    "provision_service_documents_name": "Naam",
    "provision_service_documents_title": "Titel",
    "provision_service_documents_description_text": "Beschrijving van de",
    "provision_service_documents_serviceType": "Type",
    "provision_service_documents_provisionRate": "Commissietarief",
    "provision_service_documents_provisionValue": "Waarde Commissie",
    "provision_service_documents_provisionAssignee": "Ontvanger",
    "provision_service_documents_provisionPayedDate": "betaald op",
    "provision_service_documents_IsActive": "Status",
    "provision_service_documents_Client": "Klant",
    "provision_service_documents_choose_month": "Selecteer de gewenste maand",
    "commission_service_documents_description": "Je kunt alle commissiefacturen en details hier bekijken en downloaden",
    "commission_service_documents_download": "Documenten downloaden",
    "commission_service_documents_download_start": "downloaden",
    "commission_client": "Klant",
    "commission_invoice_no": "Factuurnummer",
    "commission_payed_data": "betaald op",
    "commission_service_type": "Type service",
    "commission_amount": "Bedrag",
    "commission_provision": "Commissie",
    "create_update_teams_assign_system_types": "Creëer en bewerk teams binnen je bedrijf. Systeemtypes aan je teams toewijzen.",
    "create_document_types_use_for_process_automation": "Maak je eigen documenttypes zodat je ze kunt gebruiken in integraties of voor procesautomatisering.",
    "write_email_templates_send_to_clients": "Maak e-mailsjablonen die je kunt gebruiken om berichten naar klanten te sturen of om bepaalde workflows te automatiseren.",
    "define_reusable_cms_elements": "Definieer herbruikbare elementen die op elke pagina kunnen worden gebruikt.",
    "manage_brands_edit_name_description": "Beheer productmerken, bewerk hun namen en beschrijvingen in meerdere talen.",
    "manage_car_models_and_aliases": "Automodellen beheren waaruit gebruikers kunnen kiezen. Aliassen definiëren voor het importeren.",
    "display_and_manage_category_tree": "De categorieboom weergeven en manipuleren. Je kunt namen en beschrijvingen wijzigen.",
    "display_and_manage_product_templates": "Maak en beheer sjablonen en laat gebruikers creatief aan de slag gaan.",
    "define_product_attributes_search_and_variants": "Definieer extra attributen die je kunt opgeven in je producten. Gebruik deze attributen om gebruikers te helpen zoeken naar producten. Maak productvarianten op basis van attributen.",
    "group_attributes_to_better_manage_and_inform": "Je kunt attributen groeperen om de gebruikerservaring in de backend beter te beheren en om itemattributen duidelijker aan je klanten te presenteren.",
    "entity_fields_missing_add_new": "Als je bepaalde velden mist in je formulieren, kun je ze hier toevoegen. Handig voor marketingcampagnes en integraties.",
    "define_manage_pipelines_and_stages": "Definieer en pas pijplijnen aan voor uw workflows. Stappen toevoegen, bewerken en verwijderen.",
    "create_questionnaires_for_workflows_and_campaigns": "Maak vragenlijsten die kunnen worden gebruikt in workflows of verschillende marketingcampagnes.",
    "catch_leads_from_other_sources_with_custom_email": "Vang leads uit meerdere bronnen op met onze Lead Catcher. Configureer afzonderlijke e-mailadressen voor elke gewenste bron.",
    "group_car_models_into_families_for_users_to_easily_find": "Groepeer automodellen in families om het voor gebruikers nog gemakkelijker te maken om naar auto's te zoeken.",
    "enrich_car_search_functionality_with_own_model_images": "Verrijk de zoekfunctie voor auto's met je eigen modelfoto's. Wijs foto's toe aan modellen of modelgroepen.",
    "tag_leads_with_custom_regular_expressions": "Breid de functionaliteit van ons tagsysteem uit met door de gebruiker gedefinieerde tags. Kennis van reguliere expressies is vereist.",
    "unknown_error": "Onbekende fout opgetreden",
    "username_required": "Voer uw gebruikersnaam in",
    "email_required": "Voer uw e-mailadres in",
    "email_invalid": "E-mail is ongeldig",
    "client_name_required": "Voer uw naam in",
    "password_required": "Voer uw wachtwoord in",
    "password_length": "Het wachtwoord moet uit minstens 8 tekens bestaan",
    "repeat_password_required": "Herhaal uw wachtwoord",
    "repeat_password_length": "Het herhaalde wachtwoord moet uit minstens 8 tekens bestaan",
    "message_required": "Voer een bericht in",
    "second_password_incorrect": "Tweede wachtwoord is niet hetzelfde als het eerste",
    "registration_not_confirmed": "Je inschrijving is nog niet bevestigd. Controleer uw mailbox en klik op de knop om uw e-mailadres te bevestigen.",
    "account_deleted": "Dit account is verwijderd!",
    "account_inactive": "Het abonnement op deze account is geannuleerd!",
    "company_required": "Voer de bedrijfsnaam in",
    "first_name_required": "Voer de voornaam in",
    "last_name_required": "Voer de achternaam in",
    "country_required": "Selecteer een land",
    "phone_required": "Voer het telefoonnummer in",
    "street_required": "Voer de straatnaam in",
    "house_number_required": "Voer het huisnummer in",
    "zip_code_required": "Voer de postcode in",
    "zip_code_invalid": "De postcode '{{ value }}' is geen geldige postcode",
    "city_required": "Voer de naam van de stad in",
    "vat_id_required": "Voer de verkoopbelasting-ID in",
    "timezone_required": "Selecteer een tijdzone",
    "select_job": "Selecteer een functie",
    "title_required": "Voer de titel in",
    "type_required": "Selecteer een type",
    "location_required": "Voer de locatie in",
    "questionnaire_required": "Selecteer een vragenlijst",
    "subject_required": "Voer het onderwerp in",
    "amount_required": "Voer het bedrag in",
    "time_count_required": "Voer de hoeveelheid tijd in",
    "name_required": "Voer de naam in",
    "price_required": "Voer de prijs in",
    "description_required": "Voer de beschrijving in",
    "question_required": "Voer de vraag in",
    "content_required": "Voer de inhoud in",
    "template_required": "Selecteer een sjabloon",
    "payment_schedule_required": "Selecteer een betalingsplan",
    "answer_required": "Voer het antwoord in",
    "website_required": "Voer het adres van de website in",
    "fax_required": "Voer het faxnummer in",
    "currency_required": "Selecteer een valuta",
    "vat_name_required": "Voer de naam van de btw in",
    "vat_id_name_required": "Voer de naam van het BTW-nummer in",
    "vat_rates_required": "Voer de btw-tarieven in",
    "coc_number_required": "Voer het COC-nummer in",
    "registered_at_required": "Voer de gegevens in waaronder uw bedrijf is geregistreerd",
    "bank_name_required": "Voer de naam van de bank in",
    "account_holder_required": "Voer de volledige naam van de rekeninghouder in",
    "account_number_required": "Voer het rekeningnummer in",
    "bank_location_required": "Voer de locatie van de bank in",
    "bank_code_number_required": "Voer de bank-sorteercode in",
    "iban_required": "Voer het IBAN in",
    "min_message": "Deze waarde is te kort. Het moeten {{ limit }} tekens of meer zijn",
    "max_message": "Deze waarde is te lang. Het moeten {{ limit }} tekens of minder zijn",
    "min_max_length": "Deze waarde moet tussen {{ min }} en {{ max }} liggen. tekens lang",
    "greater_than_or_equal": "Deze waarde moet groter of gelijk zijn aan {{ compared_value }}.",
    "price_type_invalid": "Het prijstype is ongeldig",
    "This value should not be blank": "Deze waarde mag niet leeg zijn",
    "required_message": "Deze waarde mag niet leeg zijn",
    "This value is not a valid URL": "Deze waarde is geen geldige URL",
    "must_upload_a_file": "U moet een bestand uploaden",
    "file_format_not_supported": "Het bestand dat u probeert te uploaden wordt niet ondersteund",
    "category_not_empty": "Categorie is niet leeg",
    "brand_has_items": "Het merk heeft producten toegewezen",
    "template_has_items": "Producten worden toegewezen aan de sjabloon",
    "item_has_variants": "Het product heeft varianten toegewezen",
    "no_valid_variant": "Geen geldige variant",
    "no_stage_set": "Er is geen niveau ingesteld",
    "no_pipeline_set": "Er is geen pijplijn ingesteld",
    "no_users_found": "Geen gebruikers gevonden",
    "no_team_set": "Geen team ingesteld",
    "budget_required": "Bepaal het budget",
    "client_required": "Wijs een klant toe",
    "order_required": "Wijs een bestelling toe",
    "item_required": "Wijs een product toe",
    "document_required": "Zorg ervoor dat de documenten beschikbaar en geldig zijn",
    "brand_required": "Selecteer een merk",
    "model_required": "Selecteer een model",
    "order_type_invalid": "Ordertype niet geldig",
    "item_already_reserved": "Sommige producten zijn niet beschikbaar op geselecteerde dagen",
    "wrong_credentials": "Uw gebruikersnaam of wachtwoord is onjuist ingevoerd. Probeer het opnieuw of klik op \"Wachtwoord vergeten\".",
    "no_car_found": "Geen voertuig gevonden voor deze lead",
    "data_push_event_invalid": "De gegevens push-gebeurtenis is ongeldig",
    "data_push_auth_invalid": "Het verificatietype van de gegevenspush is ongeldig",
    "cart_type_invalid": "Type winkelmandje is ongeldig",
    "system_type_invalid": "Het systeemtype is ongeldig",
    "menu_version_invalid": "Menuversie is ongeldig",
    "logo_version_invalid": "Versie logo is niet geldig",
    "payment_method_invalid": "We konden de door u opgegeven betaalmethode niet verwerken",
    "nationality_required": "Voer uw nationaliteit in",
    "place_of_birth_required": "Voer uw geboorteplaats in",
    "date_of_birth_required": "Voer uw geboortedatum in",
    "id_required": "Voer de ID in",
    "meta_identifier_required": "Selecteer een identificatiecode",
    "payment_status_invalid": "De betalingsstatus is ongeldig",
    "iban_invalid": "De ingevoerde waarde is geen geldig rekeningnummer",
    "swift_bic_required": "Voer een geldig SWIFT/BIC-nummer in",
    "reference_required": "Voer een referentie in",
    "service_status_invalid": "De servicestatus is ongeldig",
    "date_proposal_type_invalid": "Het voorgestelde datumtype is niet geldig",
    "mobile_phone_invalid": "Het mobiele telefoonnummer is ongeldig",
    "mobile_phone_required": "Voer uw mobiele nummer in",
    "phone_invalid": "Telefoonnummer is ongeldig",
    "fax_invalid": "Faxnummer is ongeldig",
    "salutation_required": "Selecteer een begroeting",
    "gender_required": "Selecteer een geslacht",
    "stripe_error": "Fout bij verbinding met Stripe",
    "stripe_connect_error_with_type": "We konden CreaCheck niet verbinden met je Stripe account. Foutcode: {type}.",
    "car_rental_pickup_time_invalid": "De gewenste ophaaltijd valt buiten de openingstijden van de locatie",
    "car_rental_drop_off_time_invalid": "De gevraagde inlevertijd voor een auto valt buiten de openingstijden van de locatie",
    "accept_terms_and_cancellation_before_continuing": "Bevestig dat u onze Algemene voorwaarden en de annuleringsvoorwaarden hebt gelezen.",
    "problem_processing_your_credit_card": "Er is een probleem met de verwerking van uw creditcard",
    "invoice_type_invalid": "Factuurtype is ongeldig",
    "doc_number_required": "Voer het documentnummer in",
    "doc_issuing_city_required": "Geef de stad van afgifte aan",
    "doc_issuing_authority_required": "Vermeld de autoriteit die het document heeft afgegeven",
    "doc_issue_date_required": "Voer de datum van afgifte in",
    "doc_expiration_date_required": "Voer de vervaldatum in",
    "test_domain_required": "Voer een testdomeinnaam in",
    "iban_has_wrong_format": "Het IBAN-formaat is niet correct",
    "car_cash_payment_data_missing_or_invalid": "Contante betalingsgegevens ontbreken of zijn ongeldig",
    "car_registration_service_only_available_in_germany": "De autorisatieservice is momenteel alleen beschikbaar in Duitsland",
    "car_transport_only_available_in_germany": "Onze transportservice is momenteel alleen beschikbaar in Duitsland",
    "target_type_invalid": "Doeltype niet geldig",
    "company_email_must_be_different_from_users_email": "Het e-mailadres van je bedrijf moet verschillen van je eigen e-mailadres.",
    "car_transport_distance_too_short": "Vervoer per auto is alleen mogelijk naar locaties op meer dan 100 km afstand",
    "first_offer_price_too_low": "Je eerste bod is te laag en werd niet aanvaard",
    "first_offer_price_too_high": "Je eerste bod is hoger dan de oorspronkelijke prijs en werd niet geaccepteerd",
    "current_offer_too_low": "Je huidige aanbod is te laag",
    "not_your_turn_to_negotiate_the_price": "Je kunt je aanbieding nu niet bijwerken. Wacht op een reactie van onze medewerker",
    "the_question_must_be_answered": "De vraag \"{{ question }}\" moet worden beantwoord",
    "validation_problems_with_item_attributes": "Controleer of de kenmerken correct zijn ingevuld. Problemen die zich hebben voorgedaan, zijn rood gemarkeerd",
    "the_field_name_contains_following_errors": "Het {fieldName} veld bevat de volgende fout: {helpText}",
    "plugin_required": "Selecteer een abonnement.",
    "invalidRegex": "De reguliere expressie die u hebt opgegeven is ongeldig",
    "assignment_strategy_type_invalid": "Het type toewijzingsstrategie is ongeldig",
    "cannot_remove_role_company": "Het is niet mogelijk om gebruikersrol ROLE_COMPANY te verwijderen",
    "coupon_type_invalid": "Selecteer een geldig type voucher",
    "subscription_starting_fee": "Inschrijfgeld voor de inschrijving",
    "comms_center_monthly_fee": "Communicatiecentrum Creacheck",
    "comms_center_chat": "Creacheck Communicatiecentrum - Klantenchat",
    "comms_center_call": "Creacheck Communicatiecentrum - Klantenoproep",
    "0": "<strong>Fehler beim Einloggen</strong>",
    "You are not authenticated": "Helaas weten we het e-mailadres en wachtwoord niet",
    "access_denied": "Om in te loggen moet je de toegang voor Creacheck.com accepteren",
    "oauth_email_missing": "We kunnen je niet aanmelden via sociale media. Log in met je e-mailadres.",
    "User already exists": "Het ingevoerde e-mailadres bestaat al in onze database. Ben je je wachtwoord vergeten?",
    "next": "Verder",
    "create_comment": "Een commentaar schrijven",
    "new_pipeline": "Nieuwe pijpleiding",
    "new_stage": "Nieuw niveau",
    "quantity": "Hoeveelheid",
    "login_error": "Fout bij inloggen",
    "not_found": "Niet gevonden",
    "deal_information": "Deal informatie",
    "login_domain": "Login domein",
    "login_domain_successfully_changed": "Het aanmeldingsdomein is gewijzigd.",
    "missing_lead_information": "Voer ontbrekende informatie over de afleiding in",
    "missing_informations": "Ontbrekende informatie",
    "missing_client_signature": "Ontbrekende handtekening van klant",
    "previous": "Eerder",
    "current": "Huidige",
    "customCssInvalid": "Aangepaste CSS is ongeldig ({{ error }})",
    "accountAndLogin": "Account en aanmelden",
    "invalidEIN": "Ongeldig eVB-nummer (elektronische verzekeringsbevestiging)",
    "radio": "Radio",
    "airConditioning": "Airconditioning",
    "parkingAssistanceRear": "Parkeerhulp achteraan",
    "airConditioningAutomatic": "Automatische klimaatregeling",
    "navigation": "Satellietnavigatie",
    "alloyRims": "Aluminium velgen",
    "parkingAssistanceFrontRear": "Parkeerhulp voor & achter",
    "metallicPaint": "Metallic lak",
    "cruiseControl": "Cruisecontrol",
    "seatHeating": "Stoelverwarming",
    "leatherSeats": "Lederen stoelen",
    "equipmentLevel": "Niveau uitrusting",
    "motorPower": "Prestaties",
    "currentLocation": "Huidige locatie",
    "distance": "Afstand",
    "selected": "geselecteerd",
    "noBrandSelected": "Geen merk geselecteerd",
    "showMore": "Meer tonen",
    "showLess": "Minder tonen",
    "optional": "Optioneel",
    "altogether": "In totaal",
    "monday": "Maandag",
    "tuesday": "Dinsdag",
    "wednesday": "Woensdag",
    "thursday": "Donderdag",
    "friday": "Vrijdag",
    "saturday": "Zaterdag",
    "sunday": "Zondag",
    "payment_data_missing_or_invalid": "Betalingsgegevens ontbreken of zijn ongeldig",
    "previousSearchRequests": "Vorige zoekopdrachten",
    "reset": "Reset",
    "number_days": "{dagen, meervoud, nul {# dagen} een {# dag} andere {# dagen}}",
    "number_months": "{maanden, meervoud, nul {# maanden} een {# maand} andere {# maanden}}",
    "outdated_browser": "Je browser is verouderd!",
    "update_browser_text": "Update je browser zodat deze website correct wordt weergegeven.",
    "update_browser_btn_text": "Werk de browser nu bij",
    "save_successfully": "Succesvol opgeslagen",
    "add_quickly": "Snel toevoegen",
    "load_data": "Gegevens laden",
    "car_data_import_vin_info": "Voer het geldige VIN in om het formulier met voertuiggegevens automatisch in te vullen.",
    "car_data_import_missing_attr_values": "Sommige attribuutwaarden ontbreken - ze zijn gemarkeerd in het formulier.",
    "vin_invalid": "FIN is ongeldig",
    "others": "Andere",
    "activate_plugin_to_enable_feature": "Deze functie is alleen beschikbaar met de volgende plugin. Activeer de plugin om deze functionaliteit te blijven gebruiken.",
    "car_data_import_failed": "Het was niet mogelijk om voertuiggegevens voor dit VIN te importeren. Controleer of het opgegeven VIN correct is.",
    "send_mail_via_outlook": "De e-mail wordt verzonden met je Microsoft Outlook-account ({email}) en is later beschikbaar in je map \"Verzonden\".",
    "permission_required": "Vereiste autorisaties",
    "phone_call_permissions_required": "Deze applicatie moet toegang hebben tot je telefoonaccounts om te kunnen bellen",
    "calling": "Bel me",
    "callingTo": "Oproep aan",
    "startingCall": "Een gesprek starten",
    "callEnded": "Gesprek beëindigd",
    "inCall": "In gesprek",
    "callCancelled": "Oproep geannuleerd",
    "callRejected": "Oproep afgewezen",
    "is_calling": "%naam% belt...",
    "contacts": "Contacten",
    "addresses": "Adresboek",
    "chat": "chat",
    "calls": "Oproepen",
    "endCustomerNewMessage": "Bericht van nieuwe klant",
    "returnToTheActiveCall": "Terug naar het actieve gesprek",
    "agentStatus": "Agent status",
    "switchOnAgentNote": "Activeren om chats en videogesprekken met klanten te accepteren",
    "switchOffAgentNote": "Deactiveren om chats en videogesprekken van klanten niet te accepteren",
    "activateAgent": "De acceptatie van chats van klanten activeren",
    "deactivateAgent": "De acceptatie van chats van klanten uitschakelen",
    "startAudioCallWith": "Begin telefoongesprek met {{to}}",
    "startVideoCallWith": "Start een videogesprek met {{to}}",
    "terms_and_conditions": "Algemene voorwaarden",
    "form_incorrect_data": "De door u ingevoerde gegevens zijn niet correct. Controleer uw gegevens en stuur ze opnieuw op.",
    "accept_terms_and_condition_start_chat": "Als je op \"Start chat\" klikt, accepteer je de algemene voorwaarden.",
    "start_chat": "Chat starten",
    "connecting_with": "Maak verbinding met",
    "connected_with": "Verbonden met",
    "do_calling": "belt",
    "chat_inactivity_warning": "De chat is te lang inactief geweest en wordt binnenkort gesloten.",
    "get_in_contact_with_us": "Neem contact met ons op",
    "no_agent_available": "Helaas is geen van de agenten online. Laat een bericht achter en we nemen zo snel mogelijk contact met je op.",
    "hello": "Hallo!",
    "we_are_here_for_you": "We zijn er voor je en kijken uit naar je vragen of feedback.",
    "start_conversation": "Gesprek beginnen",
    "welcome_customer": "Mijn naam is {voornaam} {achternaam}. Hoe kan ik u helpen?",
    "end_chat": "Ga naar",
    "send_request": "Aanvraag sturen",
    "checkout_payment_agreement": "Ik stem ermee in dat {bedrijf} begint met het leveren van de aanvullende diensten vóór het begin van de annuleringstermijn en ik begrijp dat ik mijn recht op annulering verlies zodra deze aanvullende diensten volledig zijn geleverd.",
    "checkout_buy_order_legal_text": "De {algemene_voorwaarden} en het {annulerings_beleid_en_annulerings_formulier} van {bedrijf} ({privacy_beleid}) zijn van toepassing op de aankoop.",
    "checkout_services_terms_cancelation_legal_text": "Voor de geboekte aanvullende diensten ({geboekte_diensten}) zijn de {algemene_voorwaarden} en het {annulerings_beleid_en_annulerings_formulier} van {bedrijf} ({privacy_beleid}) van toepassing.",
    "no_credit_cards": "Geen creditcards",
    "minimize_video": "Videoweergave minimaliseren",
    "maximize_video": "Videoweergave maximaliseren",
    "enable_fullscreen": "Modus Volledig scherm activeren",
    "disable_fullscreen": "Modus Volledig scherm uitschakelen",
    "enable_screensharing": "Bureaublad delen",
    "disable_screensharing": "Bureaublad delen beëindigen",
    "mute_microphone": "Microfoon dempen",
    "unmute_microphone": "Microfoon uitschakelen",
    "enable_camera": "De camera inschakelen",
    "disable_camera": "Schakel de camera uit",
    "end_call": "Het gesprek beëindigen",
    "end_videocall": "De videostream beëindigen",
    "muted": "Gedempt",
    "overall_feedback": "Hoe beoordeelt u uw contact met ons?",
    "connection_quality_feedback": "Hoe zou je de kwaliteit van de verbinding beoordelen?",
    "send_feedback": "Feedback sturen",
    "customer_chat_ended_info": "Uw chat is beëindigd",
    "checking_connection": "Verbinding wordt gecontroleerd",
    "call_again": "Opnieuw bellen",
    "end_call_local_disconnected": "Gesprek beëindigd wegens verbindingsproblemen",
    "end_call_remote_disconnected": "Gesprek beëindigd wegens verbindingsproblemen op de {remoteUserName} pagina",
    "network_disconnected_warning": "Er is momenteel geen verbinding met het internet.",
    "network_disconnected": "Verbinding onderbroken",
    "network_want_reconnect": "Uw verbinding is onderbroken. Wilt u opnieuw verbinding maken?",
    "leave_as_an_email_message": "Laat een e-mail achter",
    "continue_call": "Doorgaan met gesprek",
    "continue_text_chat": "Tekstchat voortzetten",
    "continue_conversation": "Gesprek voortzetten",
    "disconnected": "Aparte",
    "disconnect_reason": "Mogelijke reden",
    "disconnect_reason_transport_close": "Deze pagina verlaten",
    "disconnect_reason_ping_timeout": "Geen internetverbinding",
    "customer_may_return": "De klant kan snel terugkomen.",
    "mobile": "Mobiele telefoon",
    "headquarter": "Centrum",
    "extension-number": "Uitbreidingsnummer",
    "add_contact": "Contact toevoegen",
    "add_address": "Adres toevoegen",
    "choose_contact": "Selecteer contact",
    "number": "Aantal",
    "new_number": "Nieuw nummer",
    "address_mail": "Postadres",
    "address_delivery": "Afleveradres",
    "address_invoice": "Factuuradres",
    "new_address": "Nieuw adres",
    "find_address": "Adres vinden",
    "searching": "Zoeken...",
    "address_change": "Adres wijzigen",
    "address_ask_change": "Het wijzigen van dit adres heeft invloed op alle contacten met dit adres. Wil je het adres wijzigen?",
    "designation": "Aanwijzing",
    "net": "Netto",
    "gross": "Bruto",
    "exp_delivery_time": "verwachte levertijd",
    "workdays": "Werkdagen",
    "special_offer": "Actie",
    "transportation_cost": "Verzendkosten",
    "discount": "Korting",
    "total": "Totaal",
    "total_gross": "Totaal (incl. btw)",
    "product_details": "Productgegevens",
    "type_of_order": "Type bestelling",
    "number_of_copies": "Editie",
    "type_of_order_choices": "Reguliere printproductie of download de printgegevens als PDF-bestand",
    "type_of_order_website": "Beschikbaarstelling van deze website",
    "print_production": "Productie",
    "pdf_download": "PDF downloaden",
    "website_upload": "De website uploaden",
    "production_time": "Productietijd",
    "production_time_description": "Selecteer de gewenste optie",
    "production_standard": "Standaard productie",
    "production_express": "Express-productie",
    "production_overnight": "Overnachting productie",
    "choose_edition": "Geselecteerde editie",
    "choose_edition_description": "Edities met staffelprijzen. Kortingen en promoties worden gemarkeerd indien beschikbaar.",
    "more_options": "Verdere opties",
    "more_options_description": "Voeg extra opties toe aan je bestelling.",
    "manual_data_review": "Handmatige gegevenscontrole (incl. verzekering)",
    "manual_item_entry": "Handmatige invoer van de positie",
    "web_enabled_pdf": "Afdrukgegevens ook beschikbaar als web-enabled PDF.",
    "cart_production_type_change_warning_title": "De circulatie wordt aangepast",
    "cart_production_type_change_warning": "De geselecteerde oplage is niet beschikbaar voor het {{shippingType}}. Deze wordt gewijzigd in de maximaal beschikbare oplage. Weet u zeker dat u wilt doorgaan?",
    "cart_no_warranty_without_manual_data_check": "Opmerking: Handmatige gegevenscontrole niet geselecteerd.",
    "campaign_budget_exceed_warning": "Campagnebudget overschreden. Goedkeuring vereist!",
    "user_budget_exceed_warning": "Gebruikersbudget overschreden. Vrijgave vereist!",
    "order_quantity": "Orderaantal",
    "data_check": "Gegevens controleren",
    "split_shipping": "Gedeeltelijke levering",
    "cart_not_logged_in": "Geen ingelogde gebruiker",
    "cart_contact_or_address_not_found_invoice": "Selecteer een factuuradres",
    "cart_contact_or_address_not_found_shipping": "Selecteer een afleveradres",
    "cart_edtion_item_or_variant_not_found": "Uitgave, artikel of variant niet gevonden",
    "cart_edtion_item_not_available": "Het artikel %item_name% is niet langer beschikbaar",
    "cart_no_print_type_found": "Geen afdruktype gevonden",
    "cart_print_type_invalid": "Afdruktype ongeldig:",
    "cart_no_shipping_type_found": "Geen verzendmethode gevonden",
    "cart_checkout_info_missing": "Checkbox-informatie ontbreekt:",
    "cart_reduced_vat_rate_check_not_supported": "Verzoek om controle van het verlaagde btw-tarief voor artikelen die dit niet ondersteunen:",
    "cart_no_price_found": "De prijs kon niet worden gevonden",
    "cart_price_mismatch": "De prijs komt niet overeen met de prijs die we aan de gebruiker hebben gepresenteerd",
    "cart_web_pdf_price_mismatch": "De PDF-prijs op het web komt niet overeen met de prijs die we aan de gebruiker hebben gepresenteerd.",
    "cart_contact_or_address_not_found_split_shipping": "Selecteer een adres voor gedeelde verzending",
    "cart_quantity_not_found_split_shipping": "Hoeveelheidsparameters niet gevonden voor gesplitste verzending",
    "cart_split_shipping_quantity_mismatch": "De gesplitste verzendhoeveelheid komt niet overeen met de productiehoeveelheid",
    "cart_coupons_mismatch": "De gebruikte coupons komen niet overeen",
    "cart_coupon_cant_be_used": "De gevraagde coupon is verlopen of kan niet worden gebruikt",
    "cart_coupon_value_mismatch": "De couponwaarde is niet correct",
    "cart_camaign_budget_processing_error": "Onverwacht probleem opgetreden tijdens het verwerken van het campagnebudget",
    "cart_campaign_budget_has_changed": "Het campagnebudget is veranderd",
    "cart_user_budget_has_changed": "Het gebruikersbudget is gewijzigd",
    "cart_user_budget_processing_error": "Onverwacht probleem opgetreden bij het verwerken van het gebruikersbudget",
    "cart_domain_not_active": "Het domein is niet actief",
    "cart_missing_desired_domain": "Specificatie van het gewenste domein ontbreekt",
    "lead_monitor_order_payment_receipt": "Inkomende betalingen bewaken",
    "lead_format_message_description_route53_register_domain": "Registreer domein {0} en redirect naar {1}",
    "lead_title_route53_register_domain": "AWS-domein registreren",
    "domain_not_available": "Domein niet beschikbaar",
    "invoice_payment_option": {
      "enabled": "Geactiveerd",
      "disabled": "Gedeactiveerd",
      "inherited": "Geërfd",
      "title": "Betaling van de factuur",
      "loading": "Overgeërfde instelling laden",
      "effective_setting": "Effectieve instelling"
    },
    "invoice_payment_threshold": {
      "title": "Drempelwaarde",
      "placeholder": "in het voorbeeld 100",
      "no_effect": "Geen effect",
      "loading": "Overgeërfde instellingen laden",
      "info_text": "Minimum bestelwaarde voor betaling op factuur",
      "effective_threshold": "Effectieve drempel",
      "not_defined": "niet gedefinieerd"
    },
    "remind_period": {
      "days": "Dagen",
      "inherited_setting": "Geërfde houding",
      "title": "Vervaldatum van de factuur Herinneringsperiode"
    },
    "sellDownloadPdf": "PDF downloaden",
    "success_management": {
      "title": "Succesmanagement",
      "manager": "Succes manager",
      "switch_title": "Activeer onlangs bekeken herinneringen",
      "interval": "Laatst bekeken periode, dagen",
      "last_seen": "Laatst gezien",
      "error_no_self": "Kan zichzelf niet toewijzen als succesmanager",
      "error_no_same_parent": "Success Manager moet ondergeschikt zijn aan een ander bedrijf"
    },
    "copy": "Kopie",
    "is_client_registration_allowed": "Registratie activeren",
    "is_email_validation_enabled": "E-mail valideren",
    "email_validation_pattern": "Regex voor e-mailvalidatie",
    "confirmation_email_cc": "CC voor registratiebevestiging",
    "client_registration_title": "Registratie-instellingen",
    "registration_fields_show": "Velden weergeven",
    "registration_fields_require": "Verplichte velden",
    "accept_terms_of_use_before_continuing": "Voordat u verdergaat, moet u onze gebruiksvoorwaarden accepteren",
    "subscription_settings": "Abonnementen",
    "client_subscription_settings": "Klant- en productabonnementen",
    "subscription_settings_description": "Hier kunt u een basisabonnement maken en een type toewijzen",
    "client_subscription_settings_description": "Hier kun je het basisabonnement toewijzen aan een klant of een artikel, afhankelijk van het type, en de prijzen aanpassen",
    "subscriptions_": "Abonnementen",
    "subscription_name": "Naam",
    "subscription_title": "Titel",
    "subscription_description": "Beschrijving van de",
    "subscription_fee": "Vergoeding",
    "subscription_startingFee": "Inschrijfgeld (optioneel)",
    "subscription_start": "Start",
    "subscription_end": "Einde",
    "subscription_status": "Status",
    "subscription_action": "Acties",
    "subscription_items": "Producten",
    "subscription_clients": "Klanten",
    "subscription_companies": "Bedrijven",
    "subscription_companies_detailed_role": "Bedrijf Gebruiker/Rol",
    "subscription_basic": "Basisabonnement",
    "add_subscription": "Abonnement toevoegen",
    "create_subscription": "Abonnement aanmaken",
    "edit_subscription": "Abonnement opslaan",
    "subscription_language": "Taalkeuze",
    "period_status": "Deactiveren aan het einde van de periode",
    "subscription_edit": "Bewerk",
    "omit_subscription_fee": "Inschrijfgeld weglaten",
    "invoice_email": "E-mailadres voor facturen",
    "order_processing_contract": "Contract voor orderverwerking",
    "accept_order_processing_contract_before_continuing": "Voordat u verdergaat, moet u onze overeenkomst voor het verwerken van bestellingen accepteren",
    "no_image_title": "Geen afbeeldings titel gevonden. Klik hier om te bewerken.",
    "no_image_description": "Geen afbeeldingsbeschrijving gevonden. Klik hier om te bewerken",
    "no_image_copyright": "Geen copyrightinformatie gevonden. Klik hier om te bewerken",
    "no_text": "Geen tekst gevonden. Klik hier om te bewerken",
    "subscription_clients_and_companies": "Klanten en bedrijven",
    "save_subscription": "Sla",
    "go_to": "Ga naar",
    "no_invoices_to_display": "Geen facturen om weer te geven",
    "url_settings": "Url-instellingen",
    "update_notification": "Beheer bijwerken",
    "update_notification_description": "Beschrijving van de",
    "update_notification_settings_description": "Je kunt meldingen voor updates hier beheren",
    "update_name": "Naam",
    "update_notification_meta": {
      "title": "Beheer bijwerken",
      "description": "Beheer uw updatemeldingen hier",
      "name": "Beheer bijwerken"
    },
    "new_update_notification": "Nieuwe updatemelding maken",
    "update_from": "van",
    "update_to": "tot",
    "edit_update_notification": "Bijwerkmelding bewerken",
    "individual_prices": "Prijzen op maat",
    "template_pdf_profile_x3": "PDF maken in X-3 formaat",
    "countries_for_prices": {
      "de": "Duitsland",
      "ch": "Zwitserland",
      "at": "Oostenrijk",
      "es": "Spanje"
    },
    "choose_country": "Selecteer een land",
    "product_groups": "Productgroepen - Leveranciers",
    "product_groups_description": "Hier kun je de productgroepen van leveranciers beheren",
    "new_product_group": "Nieuwe productgroep toevoegen",
    "edit_product_group": "Bewerk",
    "create_product_group": "Maak",
    "product_group_number": "Productgroepnummer",
    "save_product_group": "Sla",
    "product_group_actions": "Acties",
    "product_group_status": "Status",
    "hide_preview": "Voorbeeld in de editor uitschakelen",
    "editor_settings": "Instellingen-editor",
    "share_edition": "Deel editie",
    "canceled_quote": "Aanbod afgewezen",
    "active_quote": "Aanbod in behandeling",
    "manual_quote_status": "Status van de aanbiedingen",
    "invalid_manual_quote_status": "Ongeldige status van de aanbieding",
    "internal_status": "Interne status",
    "edit_quote": "Bewerk aanbod",
    "copy_quote": "Kopieer aanbod",
    "open_lead": "Open Lood",
    "upload_pdf": "PDF uploaden en koppelen",
    "upload_pdf_title": "PDF uploaden",
    "upload_pdf_status_done": "Uw PDF-bestand is succesvol geüpload",
    "upload_pdf_status_error": "PDF uploaden mislukt.",
    "placeholder_upload_error": "Er is een probleem opgetreden tijdens het uploaden!",
    "linkedin_insight_tag": "LinkedIn Inzicht Tag",
    "linkedin_partner_id": "Partner-id",
    "tracking_pixel": "Volgpixel bijv. Meta Pixel",
    "tracking_pixel_data": "Uitvoeringscode",
    "jsWebsite": "Javascript",
    "upload_website_favicon": "Favicon",
    "upload_website_favicon_label": "Afbeelding uploaden en bekijken",
    "upload_website_favicon_info": "Let op! Het vereiste bestandsformaat is PNG. Afhankelijk van het gebruikte apparaat ligt de optimale resolutie tussen 32 x 32px en maximaal 64 x 64px. Je kunt ook andere formaten gebruiken zolang ze vierkant zijn en de maximale resolutie niet overschrijden.",
    "upload_favicon_error": "Afbeelding heeft niet de vereiste resolutie",
    "go_back_to_campaign": "Terug naar de campagne",
    "no_orders_to_display": "Keine Bestellungen zum Anzeigen",
    "no_editions_to_display": "Keine Editionen zum Anzeigen",
    "google_tag_manager": "Google Tag Manager",
    "google_tag_manager_id": "GTM ID",
    "website_analyse": "Website analyse",
    "legal_information": "Juridische informatie",
    "contact_info": "Contactgegevens",
    "import_users_via_csv": "Gebruikers aanmaken door een CSV-bestand te importeren",
    "remove_users_via_csv": "Gebruikers verwijderen door een CSV-bestand te importeren",
    "import_users": "Gebruikers importeren",
    "remove_users": "Gebruiker verwijderen",
    "fill_data_policy_before_autosave": "Automatisch opslaan mislukt. Vul de informatie over gegevensbescherming en wettelijke kennisgeving in. Deze kunt u vinden in de instellingen",
    "invoice_customer_info": "ID - Bedrijf / Naam",
    "invoice_search_debtor": "Debiteurennummer",
    "invoice_search_vat": "BTW",
    "invoice_search_amount": "Bedrag",
    "search_field_name": "Selecteer een zoekveld",
    "search_field_info_text": "Als u wilt zoeken naar een numerieke waarde in het zoekveld, moet u een veld selecteren",
    "your_export": "Uw export",
    "export_download_info": "Uw bestand is aangemaakt en kan nu worden gebruikt.",
    "export_download": "downloaden",
    "export_file": "Bestand",
    "export_share": "Deel",
    "image_permission_title": "Attentie!",
    "image_permission_warning": "Beeldrechten beschikbaar/toestemming verkregen van alle personen?",
    "other_settings": "Andere instellingen",
    "image_rights_query": "Vraag naar beeldrechten",
    "direct_ordering_label": "Directe bestelling",
    "direct_ordering_info_text": "In het geval van een directe bestelling kan de gemaakte editie niet meer worden bewerkt. Het wordt direct in het winkelmandje geplaatst.",
    "subscription_automation": {
      "title": "Factureringstarget voor nieuwe gebruikersabonnementen",
      "payer": "Betaler",
      "fee_for_role_user": "Vergoeding voor ROLE_USER",
      "fee_for_role_admin": "Vergoeding voor ROLE_ADMIN",
      "fee_for_role_wl_admin": "Vergoeding voor ROLE_WL_ADMIN",
      "hint_title": "Opmerking",
      "hint_body": "Maak en activeer een bedrijfsabonnement voor dit bedrijf voor alle gebruikers die later worden aangemaakt.",
      "debtor_ancestor": "Elk gebruikersabonnement wordt gefactureerd aan de %billingTargetName%.",
      "debtor_ancestor_missing": "Er is geen voorgaand bedrijf gemarkeerd als debiteur. Corrigeer dit alstublieft."
    },
    "need_content_authorization": "Toestemming voor inhoud vereist",
    "publisher_info_text": "De uitgever wordt automatisch opgenomen in elk noodzakelijk advertentiemedium in overeenstemming met de wettelijke richtlijnen.",
    "mark_campaign": "Campagne markeren",
    "template_exports": {
      "download_complete": "Voltooid downloaden",
      "export": "Exporteer",
      "download_files": "Bestanden downloaden",
      "synchronize": "Synchroniseer"
    },
    "info_import_and_export": {
      "note": "Algemene informatie over import en export",
      "import": "Importeren: Importeert alle informatie over de sjablooninstellingen (CSS, inhoudsblokken, andere instellingen) en bestanden. Maakt een initieel zip-bestand voor de export. Bestaande zip-bestanden worden overschreven.",
      "upload": "Uploaden: Voegt extra bestanden toe aan de S3 sjabloonmap. Deze bestanden worden toegevoegd aan het zipbestand voor de export.",
      "sync": "Synchroniseren: Synchroniseert de sjablooninstellingen (CSS, inhoudsblokken, andere instellingen) met de bestanden (index.html, page.css, pdf.css, printpdf.css, website.css, website.js) in de S3 sjabloonmap en het zip-bestand (exporteren).",
      "export": "Exporteren: Maakt de structuur van een index.html voor kopiëren en plakken",
      "download": "Bestanden downloaden: Downloadt het bestaande zip-bestand. (Voer een synchronisatie uit voordat u gaat downloaden om de meest recente gegevens te verkrijgen.",
      "copy": "Sjabloon kopiëren: De functie 'Sjabloon kopiëren' kopieert alleen de sjablooninstellingen, maar niet de mappen en bestanden."
    },
    "template_upload_title": "Bestanden uploaden naar de sjabloonmap",
    "pls_choose_text": "Selecteer een tekst",
    "discounts": {
      "discount_type_invalid": "Het type korting is niet geldig",
      "discounts_tab_title": "Kortingen",
      "intro_text": "Voer het FlyerAlarm productgroepnummer en het bijbehorende kortingstype en de waarde in. Deze zullen worden toegepast op alle gebruikers binnen de huidige organisatie tijdens het afrekenen.",
      "none_yet_add_some": "Er zijn nog geen kortingen gedefinieerd. Gelieve er enkele toe te voegen.",
      "number_must_be_unique": "Het nummer moet uniek zijn",
      "must_be_0_to_100": "Moet van 0 tot 100 zijn"
    },
    "link_to_uploaded_pdf": "Url naar het geüploade PDF-bestand",
    "upload_qrcode_logo": "Logo uploaden voor QR code",
    "choose_file": "Selecteer bestand",
    "upload_successful": "Uploaden geslaagd",
    "upload_failed": "Uploaden mislukt",
    "qr_logo": "Logo",
    "qr_example": "Voorbeeld",
    "qr_settings": "QR code instellingen",
    "qr_margin": "Afstand",
    "qr_upload": "QRCode-logo uploaden",
    "google_search_console": "Google Zoekresultaten",
    "gsc_html_tag": "HTML-TAG verificatie",
    "content_id": "Inhoud-id",
    "gsc_html_tag_example": "Gebruik de HTML-tagverificatie. Kopieer de id, bijv. Xu9c238409d8JDSF8... uit het inhoudskenmerk van de metatag die je van Google ontvangt voor de verificatie en plak de ID hier. Voorbeeld: <meta name='google-site-verificatie' content='Xu9c238409d8JDSF8...' />",
    "order_complete": {
      "good_luck": "Veel succes met je campagne!",
      "order_complete": "Uw bestelling is succesvol afgerond",
      "pls_check_inbox": "Controleer je inbox.",
      "order_info": "Alle bestelgegevens zijn verzonden naar het e-mailadres dat we van je hebben. U kunt uw bestelgeschiedenis op elk gewenst moment bekijken in uw gebruikersaccount op",
      "orders": "Bestellingen",
      "view": "bekijken.",
      "start_next_campaign": "Start nu een andere campagne"
    },
    "no_product_selected": "Geen product geselecteerd",
    "mail_messages": {
      "header": {
        "header_title": "Het slimme online ontwerpportaal",
        "support": "Help & FAQ",
        "login": "INLOGGEN"
      },
      "footer": {
        "email_info": "Je ontvangt deze e-mail omdat je je hebt geregistreerd bij %bedrijf%.",
        "email_info_first_name_form": "Je ontvangt deze e-mail omdat je je hebt geregistreerd bij %bedrijf%.",
        "dont_reply": "Deze e-mail is verzonden vanaf een adres dat geen inkomende e-mails kan ontvangen. </br>Beantwoord dit bericht niet. Als je vragen of opmerkingen hebt, kun je contact met ons opnemen via",
        "agb": "GTC",
        "privacy_policy": "Gegevensbescherming",
        "imprint": "Afdruk"
      }
    },
    "content_check": "Beoordeling inhoud",
    "terms_of_payment": "Betalingsvoorwaarden",
    "terms_of_payment_edit": "Betalingsvoorwaarden bewerken",
    "cancel_impersonation": "Een einde maken aan rolomkering",
    "impersonate": "Rol accepteren",
    "template_deleted_warning": "Let op! De sjabloon van de editie is niet langer beschikbaar.",
    "click_to_enable_editing": "Klik hier om bewerken op het huidige tabblad te activeren.",
    "click_to_send_a_request_to_enable_editing": "Klik hier om een verzoek in te dienen om de verwerking te activeren",
    "waiting_for_form_release": "Wachten op vrijgave",
    "already_edited_by_another_user": "Deze editie is mogelijk al bewerkt door een andere gebruiker.",
    "please_wait": "Even geduld.",
    "user_already_requested_release": "al goedkeuring heeft aangevraagd",
    "user_requested_that_you_give_up_the_editing": "vraagt om toestemming om te bewerken.",
    "emergency_release": "Vrijgave afdwingen. Gebruik op eigen risico. Editie kan overschreven worden door een andere gebruiker als die er op dat moment aan werkt.",
    "alt_debtor_number": "Alternatieve debiteur-ID",
    "use_bootstrap_label": "Bootstrap",
    "use_bootstrap_infotext": "Als je deze optie activeert, worden de bootstrap bibliotheken geïntegreerd",
    "last_queried_attributes": "Laatst aangevraagde prijzen",
    "transfer_campaign": "Overdracht",
    "duplicate_campaign_description": "De campagne dupliceren en toewijzen aan individuele gebruikers of alle gebruikers van een bedrijf",
    "duplicate_campaign": "Dubbele",
    "clone_to_single_user": "Duplicaat maken voor een of meer gebruikers",
    "clone_to_all_users_of_company": "Duplicaat aanmaken voor alle gebruikers van een bedrijf",
    "enum_option_invalid": "Ongeldige optie",
    "pdf_download_option": {
      "enabled": "Geactiveerd",
      "disabled": "Gedeactiveerd",
      "inherited": "Geërfd",
      "title": "PDF downloaden toegestaan",
      "loading": "Overgeërfde instelling laden",
      "effective_setting": "Effectieve instelling"
    },
    "choose_image_for_filter": "Selecteer een afbeelding",
    "invoice_email_bcc": {
      "send_new_invoice_email_bcc": "Nieuwe factuur per e-mail versturen BCC",
      "email_address_bcc": "E-mailadres BCC",
      "info_text": "Er wordt ook een verborgen kopie van de e-mail met de nieuwe factuur naar dit adres gestuurd."
    },
    "reduced_vat_rate": {
      "cart_item_hint": "Dit artikel komt in aanmerking voor een verlaagd btw-tarief na een handmatige controle door een Creacheck-medewerker"
    },
    "forgot_password_infotext": "Als u uw wachtwoord bent vergeten, kunt u het hier opnieuw instellen. Voer in het onderstaande formulier uw geregistreerde e-mailadres in en klik op \"Doorgaan\". We sturen je dan een e-mail met meer informatie.",
    "forgot_password_infotext_robethood": "Als u uw wachtwoord bent vergeten, kunt u het hier opnieuw instellen. Voer in het onderstaande formulier het geregistreerde e-mailadres in en klik op \"Doorgaan\". Wij sturen u dan een e-mail met meer informatie.",
    "forgot_password_info_email_not_used": "Als je het e-mailadres dat is gekoppeld aan je Creacheck-account niet langer gebruikt, neem dan contact met ons op via ons contactformulier:",
    "forgot_password_info_email_not_used_robethood": "Als je het e-mailadres dat aan je account is gekoppeld niet langer gebruikt, neem dan contact met ons op via ons contactformulier:",
    "invoice_greeting_male": "Geachte heer",
    "invoice_greeting_female": "Geachte mevrouw",
    "invoice_greeting_text": "Hartelijk dank voor uw bestelling via %bedrijf%. Wij brengen u de volgende diensten in rekening:",
    "bank_account": "Bankgegevens",
    "mandatory_field": "Declareer dit als een verplicht veld",
    "custom_checkbox": "Aangepast selectievakje",
    "set_label_checkbox": "Wijs een label toe aan het selectievakje",
    "set_checkbox_link": "Je kunt het label hier linken",
    "change_checkbox_link": "Link wijzigen",
    "checkbox_infotext": "Je kunt het label koppelen door het corresponderende gebied in $-tekens te plaatsen. bijv. Ik ga akkoord met het $Privacybeleid$.",
    "perforation": {
      "cart_radiobox_title": "Opties voor vijlperforatie",
      "attribute_is_custom_fa_setting": "Wordt op maat gemaakt FA voorgeselecteerde perforatieopties",
      "set_perforation_options": "Perforatieopties instellen",
      "preset": "Standaardinstelling",
      "how_to": "Zoek naar de identificatie van de productgroep, selecteer enkele varianten, probeer verschillende hoeveelheden en verzendmethoden. Selecteer vervolgens de perforatieoptie die met dit kenmerk moet worden voorgeselecteerd. Er kunnen meerdere opties worden toegevoegd.",
      "select_variant": "Selecteer een variant om de aantallen en verzendmethoden weer te geven.",
      "select_shipping_type": "Klik op een verzendmethode om de beschikbare opties te bekijken.",
      "select_option_value": "Klik op een optie om deze toe te voegen aan de selectie.",
      "please_try_other": "Probeer een andere variant, hoeveelheid of verzendmethode",
      "options": "Opties",
      "other_options": "Andere opties",
      "selected_options": "Geselecteerde opties",
      "perforation_options": "Opties voor perforatie",
      "product_group": "Productgroep",
      "variants": "Varianten",
      "quantities_in_variant": "Hoeveelheden in variant"
    },
    "textile_select_exactly_n_items": "Selecteer exact {totaal}",
    "textile_exact_number_selected": "Alles goed: precies {totaal} geselecteerde artikelen",
    "textile_order_quantity_mismatch": "Het aantal stofmaten moet overeenkomen met de geselecteerde editie.",
    "tracking_pixel_help_text": "Houd er rekening mee dat de implementatie per aanbieder kan verschillen. In ons voorbeeld gebruiken we de trackingpixel van Meta. De integratie vereist voornamelijk domeinverificatie. Hiervoor moet je een metatag integreren, die in ons voorbeeld is geleverd door Meta. Zie de voorbeeldcode hieronder. Houd er rekening mee dat verificatie tot 72 uur kan duren, afhankelijk van de provider. In de tweede stap voeg je de basiscode voor de trackingpixelintegratie toe. Lees voor meer informatie de documentatie van de betreffende aanbieder.",
    "reg_greeting_male": "Geachte heer",
    "reg_greeting_female": "Geachte mevrouw",
    "reg_gender_male": "De heer",
    "reg_gender_female": "Mevrouw",
    "reg_name": "Aanhef en naam",
    "reg_phone_number": "Telefoonnummer",
    "reg_domain": "Domein",
    "reg_ancestors": "Structuur-ID's",
    "reg_explanation": "<p>Bedankt voor uw registratie. Gelieve uw e-mailadres binnen 24 uur te bevestigen.</p><p>Alleen met een bevestigd e-mailadres kunt u een nieuw wachtwoord of informatie over wijzigingen ontvangen.</p>",
    "widget_order_stats": {
      "title": "Totaal aantal bestellingen",
      "orders_count": "Orders tellen",
      "orders_gross_total": "Totaal bruto orders",
      "no_orders_in_date_range": "Geen bestellingen binnen de geselecteerde periode",
      "loading": "Laden",
      "this_month": "Deze maand",
      "previous_month": "Vorige maand",
      "last_3_months": "Laatste 3 maanden",
      "last_6_months": "Laatste 6 maanden",
      "last_12_months": "Laatste 12 maanden",
      "for_companies": "voor onderneming(en)",
      "for_clients": "voor de klant(en)",
      "title1": "Totaal aantal bestellingen",
      "title2": "De 10 meest bestelde producten",
      "items_count": "Producten tellen",
      "item_types_count": "Producten (soorten)"
    },
    "cart_domain": "Het domein dat u hebt opgegeven",
    "cart_domain_available": "is beschikbaar!",
    "cart_domain_not_available": "is niet langer beschikbaar!",
    "cart_domain_not_available_title": "Domein niet beschikbaar",
    "pages_to_delete": "De volgende pagina's worden verwijderd:",
    "page_to_delete": "Pagina",
    "sales_doc_reverse_charge": "De factuur wordt zonder btw uitgereikt, omdat in dit geval de verleggingsregeling van toepassing is. De btw moet worden aangegeven en betaald door de ontvanger van de dienst.",
    "invoice_for_service": "Factuur voor service",
    "phone_invalid_country": "Landcode van het telefoonnummer is ongeldig",
    "mobile_phone_invalid_country": "Landcode van het mobiele telefoonnummer is ongeldig",
    "fax_invalid_country": "De landcode van het faxnummer is ongeldig",
    "search_campaign": "Campagnes zoeken",
    "coupon_usage": "Voucher gebruik",
    "coupon_balance_title": "Couponbalans",
    "get_coupon_balance": "Resterend bedrag opvragen",
    "total_coupon_budget": "Totaal budget:",
    "remaining_coupon_budget": "Overblijvend budget:",
    "spent_coupon_budget": "Besteed budget:",
    "coupon_balance_success": "Vraag succesvol",
    "upload_print_data_previews": "Afdrukvoorbeeldafbeeldingen uploaden",
    "uploaded_print_data_previews": "Geüploade printgegevens Afbeeldingen bekijken",
    "confirm_upload": "Bevestig",
    "image_minimum_size": "Afbeelding komt niet overeen met de minimumgrootte!",
    "data_policy_info": "Gegevensbescherming en opdruk",
    "fill_data_policy_before_save": "Vul de informatie over gegevensbescherming en de juridische kennisgeving in voordat u de gegevens opslaat. U vindt deze in de instellingen",
    "subscription_liu_subscribe": "Aanmelden",
    "subscription_liu_cancel": "Annulering",
    "subscription_liu_extend": "Verleng",
    "subscription_status_changed_to_active": "Status gewijzigd in actief!",
    "subscription_status_changed_to_inactive": "Status gewijzigd in inactief!",
    "sub_start_end": "De begindatum kan niet groter zijn dan de einddatum!",
    "subscription_liu_send": "Verzenden",
    "subscription_to_client": {
      "subscription_not_available": "Kies een abonnement!",
      "type_parameter_not_present": "De parameter %type% ontbreekt",
      "date_parameter_not_present": "De parameter %type% ontbreekt",
      "end_date_greater_then_start_date": "De einddatum kan niet groter zijn dan de begindatum!",
      "end_date_less_then_first_day_of_next_month": "De einddatum kan niet eerder zijn dan de eerste datum van de volgende maand!"
    },
    "subscription_billing_period": "Boekhoudkundige periode",
    "mail_greeting_female": "Hallo mevrouw",
    "mail_greeting_male": "Hallo mijnheer",
    "mail_greeting_first_name_form": "Hallo",
    "mail_preview": "Voorbeeld e-mail",
    "mail_preview_update": "Voorbeeld starten/bijwerken",
    "mail_preview_close": "Sluit",
    "mail_preview_hint": "Houd er rekening mee dat de preview alleen dient om de lay-out en berichten te controleren. Alle sjablonen bevatten dummygegevens.",
    "tracking_details": "Klik op het trackingnummer voor meer informatie",
    "ups_tracking_title": "Verzending traceren via API (UPS)",
    "ups_tracking_button": "Status opvragen",
    "tracking_informations": "Consignatie informatie",
    "ups_tracking_status": "Status van uw zending (UPS)",
    "shipment_package": "Pakket",
    "shipment": "Programma",
    "shipment_package_current_status": "Huidige status",
    "shipment_package_current_status_description": "Beschrijving van de",
    "shipment_package_delivery_date": "Geleverd op",
    "shipment_package_delivery_location": "Ontvangen waar",
    "shipment_package_delivery_receivedBy": "Ontvangen van",
    "tracking_error": "Er is een fout opgetreden tijdens de query!",
    "1": "<strong>Fehler beim Einloggen</strong>",
    "website_title": "Titel voor website (zoekmachines)",
    "basic_billing_settings": "Basisinstellingen voor facturering",
    "currency_and_vat": "Valuta en btw",
    "basic_client_settings": "Basisinstellingen",
    "client_settings_info_text": {
      "info_text_phone": "Om je vaste nummer correct in te voeren, voer je het volledige nummer in, inclusief de landcode. Zorg ervoor dat je geen spaties of speciale tekens gebruikt. Voorbeeld voor Duitsland: +49XXXXXXXXX.",
      "info_text_mobile": "Om je mobiele nummer correct in te voeren, voer je het volledige nummer in, inclusief de landcode. Zorg ervoor dat je geen spaties of speciale tekens gebruikt. Voorbeeld voor Duitsland: +49XXXXXXXXX.",
      "info_text_demo": "Activeer de demomodus, bijvoorbeeld om testopdrachten uit te voeren.",
      "info_text_image_rights_query": "Als u deze optie activeert, wordt er telkens wanneer u een afbeelding uploadt een extra vraag weergegeven. U wordt dan gevraagd om te bevestigen dat u de gebruiksrechten voor de geüploade afbeelding bezit. Dit helpt ons om ervoor te zorgen dat alle geüploade afbeeldingen rechtsgeldig zijn en geen auteursrechten schenden.",
      "info_text_pdf_download_option": "Hiermee activeert u de downloadoptie voor PDF-bestanden in de editor.",
      "info_text_needs_content_authorization": "Als je de inhoudscontrole activeert, moeten alle bestellingen van je bedrijfsleden handmatig worden gecontroleerd en bevestigd.",
      "info_text_main_language": "Selecteer de taal waarin je de applicatie voornamelijk wilt gebruiken. Door een taal te selecteren, passen we de gebruikersinterface aan je behoeften aan.",
      "info_text_password_renew": "Als u deze optie activeert, sturen we de gebruiker de volgende keer dat hij/zij inlogt een e-mail om het wachtwoord te vernieuwen.",
      "info_text_editor_auto_save": "Als je deze optie activeert, slaat de editor je wijzigingen elke 5 minuten automatisch op."
    },
    "content_check_status_rejected": "Afgewezen",
    "content_check_status_approved": "geautoriseerd",
    "cart_remaining_user_budget": "Resterend gebruikersbudget",
    "cart_remaining_user_budget_after_order": "Overblijvend gebruikersbudget na de bestelling",
    "cart_budget_user": "Gebruikersbudget",
    "archived_product_templates": "Gearchiveerde sjablonen",
    "archived_product_templates_description": "Hier vind je alle gearchiveerde sjablonen",
    "archive_template": "Archief",
    "restore_template": "Herstel",
    "budget_value_used": "Gebruikt",
    "budget_value_reserved": "Gereserveerd",
    "budget_value_residual": "Restwaarde",
    "double_opt_in": "Toestemmingsprocedure met dubbele opt-in",
    "export_video": "Video exporteren",
    "mail_text_rhenus": {
      "support": "FAQ en hulp",
      "login": "Inloggen",
      "thank_you": "Bedankt voor uw bestelling!",
      "order_foreign_status": {
        "in_delivery": {
          "explanation_first_name_form": "Je bestelling is geproduceerd en overhandigd aan de koerier voor levering.",
          "possible_actions_tracking_first_name_form": "Volg je levering op",
          "possible_actions": "Onder <a href='%domein%'><span class='r_url bold'>%domein%</span></a> kunt u uw bestelling op elk gewenst moment bekijken."
        }
      },
      "order_item_sales": {
        "explanation": "De gegevens van je bestelling staan hieronder:",
        "possible_actions": "Onder <a href='%domein%'><span class='r_url bold'>%domein%</span></a> kunt u uw bestelling op elk gewenst moment bekijken.",
        "call_to_action": "Bestelgegevens"
      },
      "order_content_check_status": {
        "explanation": "Bedankt voor uw bestelling. De bestelling is verzonden na de inhoudscontrole %status%.",
        "possible_actions": "Onder <a href='%domein%'><span class='r_url bold'>%domein%</span></a> kunt u uw bestelling op elk gewenst moment bekijken.",
        "call_to_action": "Bestelgegevens"
      }
    },
    "authorized": "Geautoriseerd",
    "open_in_new_tab": "Open",
    "subscription_set_availability_period": "De beschikbaarheidsperiode van het abonnement instellen",
    "failed_video_generation": "Video kon niet worden gegenereerd! Controleer of de geüploade bestanden geldig zijn!",
    "video_uploads_missing": "Geen video-uploads gevonden in editieblokken!",
    "subscription_video_missing": "Het video-abonnement is niet gevonden!"
  }
}