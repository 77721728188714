import React, { useState, useEffect } from 'react';
import _, { findIndex as _findIndex, cloneDeep as _cloneDeep } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { S3_LINKS, S3_LINKS_DIV } from 'js/constants';
import {
  getMediaLibrary,
  getMediaLibraryFolder,
  updateStoreFR,
  getMediaLibraryFolderSorted,
} from '../../../actions/MediaLibraryActions';
import Loader from '../Loader';
import FormAutocomplete from '../../forms/FormAutocomplete';

const UploadFiles = ({
  handleMedia,
  neededHeight,
  neededWidth,
  getMediaLibrary,
  mediaFiles,
  mediaFolders,
  updater,
  getMediaLibraryFolder,
  pending,
  updateStoreFR,
  storeFR,
  client,
  getMediaLibraryFolderSorted,
  user,
  intl: { messages },
  neededType
}) => {
  // const [message, setMessage] = useState('');
  // const [fileInfos, setFileInfos] = useState([]);
  // const [folderInfos, setFolderInfos] = useState([]);
  const [openedFolder, setOpenedFolder] = useState('main');
  const [activdir, setActivdir] = useState({});
  // const [storeF, setStoreF] = useState(["main"]);
  const [storeParent, setStoreParent] = useState();
  const [sorting, setSorting] = useState();

  // Inputfields set Mediainfos
  const s3urlThumb = S3_LINKS.MEDIA_IMGS_THUMB;
  const s3urlOriginal = S3_LINKS.MEDIA_IMGS_ORIGINAL;
  const pic = S3_LINKS_DIV.MEDIA_IMAGE_THUMB_DEFAULT;

  // set
  // const [mediaData, setMediaData] = useState({ index: 0, title: '', description: '', copyright: '', parent: 'main' });

  // get
  const [getMedia, setGetMedia] = useState({
    title: 'Rucksack',
    description: 'Survival',
    copyright: 'c by cc',
    tag: 'rucksack',
    sour: pic,
    back: 0,
    disabled: '',
  });

  useEffect(() => {
    if (mediaFolders.length + mediaFiles.length === 0) {
      getMediaLibrary(client, sorting, user.granted_holding_access_whitelist, user.username);
    }
  }, [client]);

  useEffect(() => {
    if (sorting) {
      getMediaLibraryFolderSorted(client, openedFolder, sorting, user.granted_holding_access_whitelist, user.username);
    }
  }, [sorting]);

  useEffect(() => {
    if (updater !== true) {
      choiceFolder(openedFolder);
    }
  }, [mediaFiles, mediaFolders, updater]);

  const reloadMediaLibrary = () => {
    getMediaLibrary(client, '', user.granted_holding_access_whitelist, user.username);
    setStoreParent('');
    setGetMedia({ back: 0 });
    setActivdir({});
    setOpenedFolder('main');
    updateStoreFR(['main']);
  };
  const ListFiles = props => {
    let pointerEvents;
    let opacity;
    let showMinimumSizeHint = false;

    if (props.neededHeight > props.imageHeight || props.neededWidth > props.imageWidth) {
      // background = "red";
      pointerEvents = 'none';
      opacity = 0.3;
      showMinimumSizeHint = true;
      // document.getElementById("cardFiles").style.pointerEvents = "none";???
    } else {
      pointerEvents = 'all';
      opacity = 1;
      // background = "yellow";
    }



    if (neededType !== 'video') {
      return (
        !props?.isInTrash && (
          <div
            md={2}
            className="cc-media-image"
            style={{ pointerEvents }}
            key={props.index}
            onClick={() => {
              props.handleMedia(
                props.fileKey,
                S3_LINKS.MEDIA_IMGS_ORIGINAL,
                props.filename,
                props.thumb,
                props.copyright
              );
            }}
          >
            <div id="cardImages" className="card setCursorPointer" style={{ opacity, pointerEvents }}>
              {showMinimumSizeHint && (
                <span style={{ position: 'absolute', backgroundColor: '#e03a3a', color: '#FFFFFF' }}>
                {messages.image_minimum_size}
              </span>
              )}
              {props.thumb === 'video/mp4' ? (
                <div className="card-img-top rounded cc-card-img">
                  <video controls style={{ width: '100%', height: '100%' }}>
                    <source src={s3urlOriginal + props.fileKey} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <img className="card-img-top rounded cc-card-img" src={props.s3urlThumb + props.thumb} width="100%" />
              )}
              <div className="card-body">
                <div className="cc-card-title">
                  <h5 className="">{props.title || props.filename}</h5>
                </div>
                <p className="card-text" />
                <div className="cc-card-footer" />
              </div>
            </div>
          </div>
        )
      );
    }
    else if (neededType === 'video' && props.thumb === 'video/mp4') {
      console.log("ausgabe neededType and thumb", neededType, props.thumb);
      return (
        !props?.isInTrash && (
          <div
            md={2}
            className="cc-media-image"
            style={{pointerEvents}}
            key={props.index}
            onClick={() => {
              props.handleMedia(
                props.fileKey,
                S3_LINKS.MEDIA_IMGS_ORIGINAL,
                props.filename,
                props.thumb,
                props.copyright
              );
            }}
          >
            <div id="cardImages" className="card setCursorPointer" style={{opacity, pointerEvents}}>
              {showMinimumSizeHint && (
                <span style={{position: 'absolute', backgroundColor: '#e03a3a', color: '#FFFFFF'}}>
                  {messages.image_minimum_size}
                </span>
              )}
                <div className="card-img-top rounded cc-card-img">
                  <video controls style={{width: '100%', height: '100%', pointerEvents: 'none'}}>
                    <source src={s3urlOriginal + props.fileKey} type="video/mp4"/>
                  </video>
                </div>
              <div className="card-body">
                <div className="cc-card-title">
                  <h5 className="">{props.title || props.filename}</h5>
                </div>
                <p className="card-text"/>
                <div className="cc-card-footer"/>
              </div>
            </div>
          </div>
        )
      );
    }
    return null;
  };

  /*

  function choiceFolder(parent, parentparent) {
    // parent ist folderid
    const currentFiles = mediaFiles
      ? mediaFiles.filter(d => d.parent === parent || (parent === 'main' && d.parent === null))
      : [];

    const currentFolders = mediaFolders ? mediaFolders.filter(d => d.parent === parent) : [];
    const parentDir = parent !== 'main' ? mediaFolders.filter(d => d.id === parent)[0] : {};
    const index = _findIndex(mediaFolders, ['id', parentparent]);

    //todo check where this index -1 error come from

    let name = index !== -1 ? mediaFolders[index].name : 'Stammverzeichnis';
    setActivdir({ folderInfo: parentDir, files: currentFiles, folders: currentFolders, parentName: name });
    setOpenedFolder(parent);

    if (parent === 'main') {
      setGetMedia({ back: 0 });
    } else {
      setGetMedia({ back: 1 });
    }
  }
*/

  function choiceFolder(parent, parentparent) {
    const storeIndex = _.findIndex(storeFR, store => store === parent);
    if (storeIndex === -1) {
      const addFoldersToStore = _cloneDeep(storeFR);
      addFoldersToStore.push(parent);
      // setStoreF([...addFoldersToStore])
      updateStoreFR([...addFoldersToStore]);
      setOpenedFolder(parent);
      getMediaLibraryFolder(client, parent, sorting, user.granted_holding_access_whitelist, user.username);
      setStoreParent(parentparent);
    } else {
      if (storeParent) {
        parentparent = storeParent;
      }
      // parent is folderid

      const currentFiles = mediaFiles
        ? mediaFiles.filter(d => d.parent === parent || (parent === 'main' && d.parent === null))
        : [];

      const currentFolders = mediaFolders ? mediaFolders.filter(d => d.parent === parent) : [];
      const parentDir = parent !== 'main' && mediaFolders ? mediaFolders.filter(d => d.id === parent)[0] : {};
      const index = _findIndex(mediaFolders, ['id', parentparent]);
      const name = index !== -1 ? mediaFolders[index].name : messages.medialibrary.back_to_root;

      setOpenedFolder(parent);
      setActivdir({ folderInfo: parentDir, files: currentFiles, folders: currentFolders, parentName: name });

      if (parent === 'main') {
        setGetMedia({ back: 0 });
      } else {
        setGetMedia({ back: 1 });
      }

      setStoreParent('');
    }
  } // End folderChoice

  function sortMedia(name, value) {
    setSorting(value);
  }

  const options = [
    [messages.sorting_name, 'name'],
    [messages.sorting_date_DESC, 'cdateDESC'],
    [messages.sorting_date_ASC, 'cdateASC'],
    [messages.sorting_owner, 'owner'],
    [messages.sorting_creator, 'creator'],
  ];

  return (
    <>
      <Row>
        <Col class="mediaLibraryViews">
          <Row>
            <Col style={{ margin: '20px' }}>
              <h3 style={{ display: 'inline' }}>
                <i className="fas fa-folders" />
                <span> {messages.medialibrary.directories} </span>
              </h3>
              <button
                type="button"
                className="btn btn-success"
                style={{ marginTop: '-10px', marginRight: '20px' }}
                onClick={() => {
                  reloadMediaLibrary();
                }}
              >
                <i style={{ cursor: 'pointer', fontSize: '214x', color: '', margin: '2px' }} className="fas fa-sync" />
              </button>

              <div className="sortingInEditorML" style={{ width: '100%', maxWidth: '300px', display: 'inline-block' }}>
                <FormAutocomplete
                  onChange={(name, value) => {
                    sortMedia(name, value);
                  }}
                  value={sorting}
                  label={messages.medialibrary.sorting}
                  name="sort"
                  infoText={messages.sorting_info_text}
                  options={options.map(option => {
                    return { label: option[0], value: option[1] };
                  })}
                />
                {sorting === 'name' ? (
                  <p style={{ color: '#CF1719' }}>{messages.medialibrary.sorting_description}</p>
                ) : (
                  <></>
                )}
              </div>
              {getMedia && getMedia.back ? (
                <div style={{ margin: '10px' }}>
                  {activdir.folderInfo.parent && (
                    <span style={{ fontSize: '14px', color: '', margin: '10px' }}>
                      <i
                        style={{ cursor: 'pointer' }}
                        className="far fa-arrow-alt-circle-left"
                        onClick={() => {
                          choiceFolder(activdir.folderInfo.parent);
                        }}
                      />
                    </span>
                  )}
                  <span style={{ fontSize: '15px' }}>
                    - <i className="fad fa-folder-open" /> {activdir.folderInfo.name}
                  </span>
                  <div>
                    {getMedia && getMedia.back ? (
                      <div
                        md={2}
                        style={{ cursor: 'pointer', padding: '10px' }}
                        className="cc-media-folder cc-media-folder-back"
                        onClick={() => choiceFolder(activdir.folderInfo.parent)}
                      >
                        <div className="card backTo" style={{ backgroundColor: '#626262' }}>
                          <span style={{ color: '' }}>
                            <i className="far fa-arrow-alt-circle-left sizeFolderIcon" />
                          </span>
                          <span> {activdir.parentName}</span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div class="text-left">
                {activdir.folders && activdir.folders.length > 0 && pending === false ? (
                  activdir.folders.map(
                    (folder, index) =>
                      !folder?.is_in_trash && (
                        <div md={2} className="cc-media-folder">
                          <div className="card" style={{ backgroundColor: 'transparent' }}>
                            <div className="card-body">
                              <div
                                className="selectZone"
                                style={{ cursor: 'pointer' }}
                                onClick={() => choiceFolder(folder.id)}
                              >
                                <div className="cc-card-title">
                                  <img
                                    alt="cutted-svg"
                                    src="https://creacheck-icons.s3.eu-central-1.amazonaws.com/folder-solid-cutted-svg-weiss.svg"
                                  />
                                  <h5 className="">{folder.name}</h5>
                                </div>
                                <p className="card-text" />
                              </div>
                              <div
                                className="cc-card-footer"
                                style={{ cursor: 'pointer' }}
                                onClick={() => choiceFolder(folder.id)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                  )
                ) : pending ? (
                  <Loader />
                ) : (
                  <div>{messages.medialibrary.no_folder_created}</div>
                )}
              </div>
              <Row>
                <Col className="extraStyle" />
              </Row>
              <Row>
                <Col style={{ margin: '20px' }}>
                  <h3>
                    <i className="far fa-images" /> {messages.medialibrary.media}
                  </h3>
                </Col>
              </Row>
              <div class="text-left">
                {activdir.files && activdir.files.length > 0 && pending === false ? (
                  activdir.files.map((file, index) => (
                    <ListFiles
                      index={index}
                      fileKey={file.key}
                      thumb={file.thumb}
                      title={file.title}
                      filename={file.filename}
                      copyright={file.copyright}
                      imageHeight={file.image_height}
                      imageWidth={file.image_width}
                      handleMedia={handleMedia}
                      s3urlThumb={s3urlThumb}
                      neededHeight={neededHeight}
                      neededWidth={neededWidth}
                      isInTrash={file?.is_in_trash}
                    />
                  ))
                ) : pending ? (
                  <Loader />
                ) : (
                  <div>{messages.medialibrary.no_media_found}</div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  mediaFolders: state.mediaLibrary.mediaFolders,
  mediaFiles: state.mediaLibrary.mediaFiles,
  saving: state.mediaLibrary.saving,
  pending: state.mediaLibrary.pending,
  updater: state.mediaLibrary.updater,
  already_exist: state.mediaLibrary.already_exist,
  storeFR: state.mediaLibrary.storeFR,
  appIntl: state.intl,
  client: state.login.user?.parent?.username,
  user: state.login.user,
});

const mapDispatchToProps = dispatch => {
  return {
    getMediaLibrary: (client, sort, access, user) => {
      dispatch(getMediaLibrary(client, sort, access, user));
    },
    getMediaLibraryFolder: (client, folderId, sorting, access, user) => {
      dispatch(getMediaLibraryFolder(client, folderId, sorting, access, user));
    },
    getMediaLibraryFolderSorted: (client, folderId, sorting, access, user) => {
      dispatch(getMediaLibraryFolderSorted(client, folderId, sorting, access, user));
    },
    updateStoreFR: data => {
      dispatch(updateStoreFR(data));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(UploadFiles)
  )
);
