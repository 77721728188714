import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from 'react-bootstrap';
import { getDeviceType } from '../cc-editor/components/actions/deviceType';
import JSZip from "jszip";

const initialState = {
  forwardingR: '',
  previewingR: false,
  exeThen: '',
  changedR: false,
  campaignSavingR: false,
  linkDataR: '',
  shareMediaR: false,
  pendingR: false,
  pendingCopyR: false,
  statusCopyR: '',
  typeOfCopyR: '',
};

function handleButtonClick(downloadLink, option) {
  if (option === 'download'){
    fetch(downloadLink)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const filename = getFilenameFromUrl(downloadLink);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', filename);
        link.click();
        URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
  } else {
    // create a hidden link element
    const link = document.createElement('a');
    link.href = downloadLink;
    link.target = '_blank';
    link.click();
  }
}

function getFilenameFromUrl(url) {
  const path = url.split('/'); // Die URL anhand des Schrägstrichs (/) aufteilen
  return path.pop(); // Den letzten Teil der aufgeteilten URL zurückgeben, was normalerweise der Dateiname ist
}

function downloadZip(fileLinks) {
  const zip = new JSZip();
  const promises = fileLinks.map(fileLink => {
    return fetch(fileLink)
      .then(response => response.blob())
      .then(blob => {
        zip.file(getFilenameFromUrl(fileLink), blob);
      })
      .catch(error => {
        console.error('Error adding files to ZIP:', error);
      });
  });

  // Wait for all files to download before generating the ZIP archive
  Promise.all(promises)
    .then(() => {
      return zip.generateAsync({ type: 'blob' });
    })
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', 'Media.zip');
      link.click();
      URL.revokeObjectURL(blobUrl);
    })
    .catch(error => {
      console.error('Error creating ZIP:', error);
    });
}

export default function campaigns(state = initialState, action) {
  switch (action.type) {
    case 'NEW_EDITION_FULFILLED': {
      return { ...state, forwardingR: action.payload.id };
    }
    case 'SET_VARIABLES': {
      if (action.payload === 'forwardingfalse') {
        return { ...state, forwardingR: '' };
      }
      if (action.payload === 'image' || action.payload === 'video') {
        return { ...state, shareMediaR: true };
      }
      return { ...state, previewingR: action.payload };
    }
    case 'SET_PREVIEW_IMAGE_PENDING': {
      return { ...state, pendingR: true };
    }
    case 'SET_PREVIEW_IMAGE_FULFILLED': {
      return { ...state, pendingR: false };
    }
    case 'DUPLICATE_CAMPAIGNS_PENDING': {
      return { ...state, pendingCopyR: true, statusCopyR: '', typeOfCopyR: '' };
    }
    case 'DUPLICATE_CAMPAIGNS_FULFILLED': {
      return { ...state, pendingCopyR: false, statusCopyR: 'success', typeOfCopyR: action.payload?.params?.type };
    }
    case 'DUPLICATE_CAMPAIGNS_REJECTED': {
      return { ...state, pendingCopyR: false, statusCopyR: 'error', typeOfCopyR: action.payload?.params?.type };
    }
    case 'GET_PDF_CAMP_FULFILLED': {
      const shareFB = url => {
        if (navigator.share) {
          navigator
            .share({
              title: 'CreaCheck',
              url,
            })
            .then(() => {
              console.log('Thanks for sharing!');
            })
            .catch(console.error);
        } else {
          window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + url,
            'pop',
            'width=600, height=400, scrollbars=no'
          );
        }
      };
      const options = {
        customUI: ({ onClose }) => {
          const { shareMediaR } = state;
          const shareMedia = shareMediaR;
          return (
            <div className="react-confirm-alert-body">
              <h1>{action.payload?.messages.your_export}</h1>
              <p>{action.payload?.messages.export_download_info}</p>
              <div className="edition-download">
                {typeof action.payload?.data === 'string' && (
                  <>
                    <div className="file-options">
                      <Button
                        bsStyle="success"
                        onClick={() => {
                          handleButtonClick(action.payload?.data, 'download');
                          onClose();
                        }}
                      >
                        {action.payload?.messages.export_download}
                      </Button>
                      <Button
                        onClick={() => {
                          handleButtonClick(action.payload?.data)
                        }}
                      >
                        {action.payload?.messages.open_in_new_tab}
                      </Button>
                      {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && shareMedia === true && (
                        <Button onClick={() => shareFB(action.payload?.data)}>
                          {action.payload?.messages.export_share}
                        </Button>
                      )}
                    </div>
                  </>
                )}
                {typeof action.payload?.data === 'object' && action.payload.data.length > 0 && (
                  <>
                    <Button
                      bsStyle="success"
                      onClick={() => {
                        downloadZip(action.payload.data);
                        onClose();
                      }}
                    >
                      {action.payload?.messages.export_download + ' - ZIP'}
                    </Button>
                    {action.payload.data.map((file, i) => {
                      let count = i + 1;
                      return (
                        <>
                          <div className="file-options">
                            <Button
                              onClick={() => {
                                handleButtonClick(file, 'download');
                              }}
                            >
                              {action.payload?.messages.export_download +
                                ' - ' + action.payload?.messages.export_file +
                                ' ' + count}
                            </Button>
                            <Button
                              onClick={() => {
                                handleButtonClick(file);
                              }}
                            >
                              {action.payload?.messages.open_in_new_tab +
                                ' - ' + action.payload?.messages.export_file +
                                ' ' + count}
                            </Button>
                            {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && shareMedia === true && (
                              <Button onClick={() => shareFB(file)}>{action.payload?.messages.export_share}</Button>
                            )}
                          </div>
                        </>
                      );
                    })}
                    <Button
                      bsStyle="warning"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      {action.payload?.messages.editor.abort}
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        },
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
      };

      confirmAlert(options);
      return { ...state, previewingR: false, shareMediaR: false };
    }
    case 'GET_PRINT_DATA_CAMP': {
      const shareFB = url => {
        if (navigator.share) {
          navigator
            .share({
              title: 'CreaCheck',
              url,
            })
            .then(() => {
              console.log('Thanks for sharing!');
            })
            .catch(console.error);
        } else {
          window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + url,
            'pop',
            'width=600, height=400, scrollbars=no'
          );
        }
      };
      const options = {
        customUI: ({ onClose }) => {
          const { shareMediaR } = state;
          const shareMedia = shareMediaR;
          return (
            <div className="edition-download">
              <h1>{action.payload?.messages.your_export}</h1>
              <p>{action.payload?.messages.export_download_info}</p>
              <div className="react-confirm-alert-button-group">
                {typeof action.payload?.data === 'string' && (
                  <>
                    <div className="file-options">
                      <Button
                        bsStyle="success"
                        onClick={() => {
                          handleButtonClick(action.payload?.data, 'download');
                          onClose();
                        }}
                      >
                        {action.payload?.messages.export_download}
                      </Button>
                      <Button
                        onClick={() => {
                          handleButtonClick(action.payload?.data);
                        }}
                      >
                        {action.payload?.messages.open_in_new_tab}
                      </Button>
                      {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && shareMedia === true && (
                        <Button onClick={() => shareFB(action.payload?.data)}>
                          {action.payload?.messages.export_share}
                        </Button>
                      )}
                    </div>
                  </>
                )}
                {typeof action.payload?.data === 'object' && action.payload.data.length > 0 && (
                  <>
                    <Button
                      bsStyle="success"
                      onClick={() => {
                        downloadZip(action.payload.data);
                        onClose();
                      }}
                    >
                      {action.payload?.messages.export_download + ' - ZIP'}
                    </Button>
                    {action.payload.data.map((file, i) => {
                      let count = i + 1;
                      return (
                        <>
                          <div className="file-options">
                            <Button
                              onClick={() => {
                                handleButtonClick(file, 'download');
                              }}
                            >
                              {action.payload?.messages.export_download +
                                ' - ' + action.payload?.messages.export_file +
                                ' ' + count}
                            </Button>
                            <Button
                              onClick={() => {
                                handleButtonClick(file);
                              }}
                            >
                              {action.payload?.messages.open_in_new_tab +
                                ' - ' + action.payload?.messages.export_file +
                                ' ' + count}
                            </Button>
                            {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && shareMedia === true && (
                              <Button onClick={() => shareFB(file)}>{action.payload?.messages.export_share}</Button>
                            )}
                          </div>
                        </>
                      );
                    })}
                    <Button
                      bsStyle="warning"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      {action.payload?.messages.editor.abort}
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        },
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
      };

      confirmAlert(options);
      return { ...state, previewingR: false, shareMediaR: false };
    }
  }
  return state;
}
